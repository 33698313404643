import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "./ScrollTop";
import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import axios from "axios";
import { MdFilterList } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";
import { FaCheckCircle, FaHome, FaTimes } from "react-icons/fa";
import PopUpModalForDeleteCustomer from "./CustomerDeleteModel";
import { FaTimesCircle } from "react-icons/fa";
import {
  addCustomer,
  deleteAllCustomers,
  deleteCustomer
} from "../../store/customerSlice";
import { Tooltip } from "react-tippy";
import Search from "../Search/Search";
import config from "../../config/config";
import FullScreenLoader from "../pages/FullScreenLoader";

import { useMemo } from "react";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import ScrollingMessage from "./ScrollingMessage";
import { AiOutlineSearch } from "react-icons/ai";
import ToastNotificationBox from "./ToastBox/ToastNotificationBox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DescriptionIcon from "@mui/icons-material/Description"; // For document symbol
import { useLocation } from "react-router-dom";
import ViewCustomer from "../forms/ViewCustomer";
import CustomerView from "./CustomerView";
import TabMenu from "./TabMenu";
import CustomNavigationButtons from "../reusable/CustomNavigationWithEditDelete";
import CustomCommonTab from "../reusable/CustomCommonTab";

const columnHelper = createColumnHelper();

function capitalizeFirstLetter(email) {
  return email.charAt(0).toUpperCase() + email.slice(1).toLowerCase();
}

function CustomerScreen() {
  const location = useLocation();

  const [expandedRow, setExpandedRow] = useState(null);
  const [isSubtotalExpanded, setSubtotalExpanded] = useState(false);
  const [isTaxesExpanded, setTaxesExpanded] = useState(false);

  const toggleSubtotal = (e) => {
    e.preventDefault();
    setSubtotalExpanded(!isSubtotalExpanded); // Toggle Subtotal
    setTaxesExpanded(false); // Ensure Taxes is closed
  };

  const toggleTaxes = (e) => {
    e.preventDefault();
    setTaxesExpanded(!isTaxesExpanded); // Toggle Taxes
    setSubtotalExpanded(false); // Ensure Subtotal is closed
  };
  const [clicked, setClicked] = useState(null);
  const [filteredStatus, setFilteredStatus] = useState("all");
  const userRole = localStorage.getItem("user_role");
  const { businessName } = location.state || {};

  const customerId = localStorage.getItem('customerId')

  const [activeItem, setActiveItem] = useState("View");

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };
  const toggleRow = (id) => {
    setExpandedRow((prevRow) => (prevRow === id ? id : id));
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const isEditActive = selectedRows.length === 1;
  const isDeleteActive = selectedRows.length > 0;
  const [activeCustomerCount, setActiveCustomerCount] = useState(0);
  const [inactiveCustomerCount, setInactiveCustomerCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = React.useState([]);
  const [filtering, setFiltering] = useState("");
  const [columnFilters, setColumnFilters] = useState("");

  const dispatch = useDispatch();
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const customersAll = useSelector((state) => state.customer.allCustomers);
  console.log("all customers of business", customersAll);
  const resetRowSelection = useRef(null);

  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };
  const navigate = useNavigate();
  const handleViewCustomer = (customerId) => {
    navigate(`/setup/viewcustomer/${customerId}`);
  };

  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModalId, setOpenModalId] = useState(null);
  // fetching data from api
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [deleteBusinessName, setdeleteBusinessName] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
      .then((response) => {
        const sortedCustomerData = response.data.customers_data.sort(
          (a, b) => b.customer_id - a.customer_id
        );
        // console.log("sor" , sortedCustomerData);
        dispatch(deleteAllCustomers());
        dispatch(addCustomer(sortedCustomerData));
        // setCustomerData(sortedCustomerData);
        // setOriginalItemData(sortedCustomerData);

        const activeCount = sortedCustomerData.filter(
          (item) => item.customer_status === "1"
        ).length;
        const inactiveCount = sortedCustomerData.filter(
          (item) => item.customer_status === "0"
        ).length;
        setActiveCustomerCount(activeCount);
        setInactiveCustomerCount(inactiveCount);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        setLoading(false); // Set loading to false in case of error
      });
  }, [customerId]);

  const handleDeleteModalItem = (customerId, businessName, row) => {
    setOpenDeleteModalId(customerId);
    setdeleteBusinessName(businessName);
  };

  const businessId = localStorage.getItem("business_id");

  const [originalItemData, setOriginalItemData] = useState([]);

  const encodedId = btoa(`${businessId}`);

  const encodedURL = `${baseURL}Y3VzdG9tZXIvdmlldw==/${encodedId}`; // VIEW CUSTOMER
  const expandedRowRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      expandedRowRef.current &&
      !expandedRowRef.current.contains(event.target)
    ) {
      setExpandedRow(null); // Close the expanded row
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
      .then((response) => {
        const sortedCustomerData = response.data.customers_data.sort(
          (a, b) => b.customer_id - a.customer_id
        );
        dispatch(addCustomer(sortedCustomerData));
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const capitalizeFirstLetterWordWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  const lowercaseAllWords = (str) => {
    return str.toLowerCase();
  };

  const data = useMemo(() => {
    return customersAll.map((customer, index) => ({
      ...customer,
      business_name: capitalizeFirstLetterWordWords(customer.business_name),
      contact_name: capitalizeFirstLetterWordWords(customer.contact_name),
      contact_email: lowercaseAllWords(customer.contact_email)
    }));
  }, [customersAll]);

  // console.log(data);

  const isRowCheckboxDisabled = (row) => {
    if (rowSelection[row.id]) {
      return false;
    } else {
      return Object.values(rowSelection).some((selected) => selected);
    }
  };
  useEffect(() => {
    const selected = table.getSelectedRowModel().flatRows[0]?.original;
    if (selected) {
      // console.log("seelcted row", selected);
      setSelectedRows(selected);
    }
  }, [rowSelection, selectedRows, customerId]);
  // console.log("selected row details" , selectedRows);
  // console.log("edit active" , isEditActive);
  // console.log("delete active" , isDeleteActive);

  const columns = [
    // {
    //   id: "checkbox",
    //   accessor: "",
    //   cell: ({ row }) => (
    //     <IndeterminateCheckbox
    //       {...{
    //         checked: row.getIsSelected(),
    //         disabled: isRowCheckboxDisabled(row),
    //         indeterminate: row.getIsSomeSelected(),
    //         onChange: row.getToggleSelectedHandler(),
    //         className: isRowCheckboxDisabled(row) ? "disabled-checkbox" : "",
    //       }}
    //     />
    //   ),
    //   enableColumnFilter: false, // Disable filter for checkbox column
    //   enableSorting: false, // Disable sorting for checkbox column
    // },
    {
      accessorFn: (row, i) => (row ? i + 1 : ""),
      header: "#"
    },
    // columnHelper.accessor("added_on", {
    //   header: <div className="text-center">Added on</div>,
    //   // cell: (props) => <div className="text-left">{props.row.original.added_on}</div>,
    //   cell: (props) => {
    //     const date = new Date(props.row.original.added_on);
    //     const options = { month: 'short', day: '2-digit', year: 'numeric' };
    //     const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
    //     return (
    //       <div>{formattedDate}</div>
    //     );
    //   }
    // }),
    columnHelper.accessor("business_name", {
      header: <div className="text-center">Customer Name</div>,
      cell: (props) => (
        <div className="text-left">{props.row.original.business_name}</div>
      )
    }),
    columnHelper.accessor(
      (data) => {
        const {
          business_address,
          business_city,
          business_zip_code,
          business_state
        } = data;
        return `${capitalizeFirstLetterWordWords(
          business_address
        )}, ${capitalizeFirstLetterWordWords(
          business_city
        )}, ${capitalizeFirstLetterWordWords(
          business_state
        )}, ${business_zip_code}`;
      },
      {
        id: "business_address_full",
        header: <div className="text-center">Customer Address</div>,
        cell: (props) => (
          <div className="text-left">
            {props.row.original.business_address},{" "}
            {props.row.original.business_city},{" "}
            {props.row.original.business_state},{" "}
            {props.row.original.business_zip_code}
          </div>
        )
      }
    ),
    columnHelper.accessor("contact_name", {
      header: <div className="text-center">Contact Name</div>,
      cell: (props) => (
        <div className="text-left">{props.row.original.contact_name}</div>
      )
    }),
    columnHelper.accessor("contact_no", {
      header: <div className="text-center">Contact No</div>,
      cell: (props) => (
        <div className="text-left">{props.row.original.contact_no}</div>
      )
    })
    // columnHelper.accessor("contact_email", {
    //   header: <div className="text-center">Contact Email</div>,
    //   cell: (props) => <div className="text-left">{capitalizeFirstLetter(props.row.original.contact_email)}</div>,
    // }),
  ];

  const handleSortingChange = (columnId) => {
    setSorting((old) => {
      if (old.length && old[0].id === columnId) {
        // Toggle sorting direction
        return [
          {
            id: columnId,
            desc: !old[0].desc
          }
        ];
      }
      // Default sorting to descending initially
      return [{ id: columnId, desc: true }];
    });
  };

  const table = useReactTable({
    data: customersAll || [],
    columns: columns,
    state: {
      rowSelection: rowSelection,
      globalFilter: filtering,
      sorting: sorting
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    enableColumnFilter: true
  });
  const currentPage = table.options.state.pagination.pageIndex;
  const [activeTab, setActiveTab] = useState("View");

  const tabs = [
    {
      key: "View",
      label: "View",
      component: <CustomerView customerId={customerId} handledelete = {()=>handleDeleteModalItem(customerId, businessName)} />
    }, // No separate component—InvoiceView itself is shown
    // {
    //   key: "Edit",
    //   label: "Edit",
    //   component: <ViewCustomer customerId={customerId} />
    // },
    // {
    //   key: "Delete",
    //   label: "Delete",
    //   onClick: () => handleDeleteModalItem(customerId, businessName)
    // }, 

    // { key: "Cancel", label: "Cancel", navigationPath: "/customers" }
  ];
  const menuItems = [
    { name: "View Customer" }
  ];
  return (
    <div className="relative">
      {loading && <FullScreenLoader />}
      {/* <TabMenu tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} /> */}

      <div className="flex-1 flex justify-center">
          <CustomCommonTab items={menuItems} />
        </div>
      {activeTab === "View" && (
        <div>{tabs.find((tab) => tab.key === "View")?.component}</div>
      )}

      {activeTab === "Edit" && (
        <div>{tabs.find((tab) => tab.key === "Edit")?.component}</div>
      )}

      <div className=" w-full rounded-tl-sm rounded-tr-sm mt-4 pl-8 pr-10">
        
      {/* <div className="absolute -top-5 right-3 flex items-center space-x-2 z-10">
          <CustomNavigationButtons
            type="edit"
            onClick={() => setActiveTab("Edit")}
          />
          <CustomNavigationButtons
            type="delete"
            onClick={() => handleDeleteModalItem(customerId, businessName)}
          />
          <div
            className="rounded-full bg-[#25caed] w-10 h-10 flex items-center justify-center hover:scale-110 shadow-md cursor-pointer"
            onClick={() => navigate("/")}
            style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
          >
            <FaHome className="text-white" size={20} />
          </div>
          <div
            className="rounded-full bg-red-500 w-10 h-10 flex items-center justify-center hover:scale-110 shadow-md cursor-pointer"
            onClick={() => navigate("/UserDashBoard")}
            style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
          >
            <FaTimes className="text-white" size={20} />
          </div>
        </div> */}
        {openDeleteModalId && (
          <PopUpModalForDeleteCustomer
            onClose={() => setOpenDeleteModalId(null)}
            customerId={openDeleteModalId}
            // customerDelete={handleDeleteItems}
            businessName={deleteBusinessName}
            resetRowSelection={table.resetRowSelection}
          />
        )}
      </div>
    </div>
  );
}

export default CustomerScreen;
