import React, { useEffect, useMemo, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { MdFilterList } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";

import Table from "./Table";
import Search from "../Search/Search";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config/config";
import { Tooltip } from "react-tippy";
import PopUpModalForDeleteVendor from "./VendorDeleteModel";
import { addVendor } from "../../store/vendorSlice";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import ScrollingMessage from "./ScrollingMessage";
import { showToastForAddVendor } from "../toasts/toastForVendor";
import ToastNotificationBox from "./ToastBox/ToastNotificationBox";
import FullScreenLoader from './FullScreenLoader'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DescriptionIcon from '@mui/icons-material/Description';  // For document symbol

function capitalizeFirstLetter(email) {
  return email.charAt(0).toUpperCase() + email.slice(1).toLowerCase();
}

const Vendors = () => {
  const dispatch = useDispatch();
  const allVendors = useSelector(state => state.vendor.allVendors);
  console.log(allVendors);
  const [loading, setLoading] = useState(false)
  const [expandedRow, setExpandedRow] = useState(null);
  const [isSubtotalExpanded, setSubtotalExpanded] = useState(false);
  const [isTaxesExpanded, setTaxesExpanded] = useState(false);

  const [TruckNumber, setTruckNumber] = useState('');


  const toggleSubtotal = (e) => {
    e.preventDefault();
    setSubtotalExpanded(!isSubtotalExpanded); // Toggle Subtotal
    setTaxesExpanded(false); // Ensure Taxes is closed
  };

  const toggleTaxes = (e) => {
    e.preventDefault();
    setTaxesExpanded(!isTaxesExpanded); // Toggle Taxes
    setSubtotalExpanded(false); // Ensure Subtotal is closed
  };
  const [clicked, setClicked] = useState(null);
  const [filteredStatus, setFilteredStatus] = useState("all");
  const userRole = localStorage.getItem("user_role")

  const [activeItem, setActiveItem] = useState("View");

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };
  const toggleRow = (id) => {
    setExpandedRow((prevRow) => (prevRow === id ? id : id));
  };
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };
  const [vehiclesNames, setVehiclesNames] = useState([]);
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

  const [rowSelection, setRowSelection] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const isEditActive = selectedRows.length === 1;
  const isDeleteActive = selectedRows.length > 0;
  const [searchValue, setSearchValue] = useState("");
  const resetRowSelection = useRef(null);
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [sorting, setSorting] = React.useState([])
  const [filtering, setFiltering] = useState("")
  const [columnFilters, setColumnFilters] = useState("")

  const navigate = useNavigate();
  const columnHelper = createColumnHelper();

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${month}/${day}/${year}`;
  };
  const capitalizeFirstLetterWordWords = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  const lowercaseAllWords = (str) => {
    return str
      .toLowerCase()
  };

  const data = useMemo(() => {
    return allVendors.map((vehicle, index) => ({
      ...vehicle,
      sl_no: index + 1,
      added_on: formatDate(vehicle.added_on),
      truck_company_name: capitalizeFirstLetterWordWords(vehicle.truck_company_name),
      contact_name: capitalizeFirstLetterWordWords(vehicle.contact_name),
      contact_email: capitalizeFirstLetter(vehicle.contact_email)
    }));
  }, [allVendors]);

  // console.log(data);



  const isRowCheckboxDisabled = (row) => {
    if (rowSelection[row.id]) {
      return false;
    } else {
      return Object.values(rowSelection).some((selected) => selected);
    }
  };
  useEffect(() => {
    const selected = table.getSelectedRowModel().flatRows[0]?.original;
    if (selected) {
      // console.log("seelcted row", selected);
      setSelectedRows(selected);
    }
  }, [rowSelection, selectedRows]);

  const columns = [
    // {
    //   id: "checkbox",
    //   accessor: "",
    //   cell: ({ row }) => (
    //     <IndeterminateCheckbox
    //       {...{
    //         checked: row.getIsSelected(),
    //         disabled: isRowCheckboxDisabled(row),
    //         indeterminate: row.getIsSomeSelected(),
    //         onChange: row.getToggleSelectedHandler(),
    //         className: isRowCheckboxDisabled(row) ? "disabled-checkbox" : "",
    //       }}
    //     />
    //   ),
    //   enableColumnFilter: false, // Disable filter for checkbox column
    //   enableSorting: false, // Disable sorting for checkbox column
    // },

    columnHelper.accessor("sl_no", {
      header: <div className="text-center">#</div>,
    }),
    columnHelper.accessor("truck_company_name", {
      header: <div className="text-center">Carrier Name</div>,
    }),
    // columnHelper.accessor("ein_number", {
    //   header: <div className="text-center">EIN Number</div>,
    // }),
    columnHelper.accessor("contact_name", {
      header: <div className="text-center">Contact Name</div>,
    }),
    columnHelper.accessor("contact_no", {
      header: <div className="text-center">Contact No</div>,
    }),
    // columnHelper.accessor("contact_email", {
    //   header: <div className="text-center">Contact Email</div>,
    // }),
  ]

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleDeleteModalItem = (vendorId, truckCompanyName, row) => {
    setTruckNumber(row.original.truck_company_name)
    if (row?.original) {
      setOpenDeleteModalId(vendorId);
    }
  };


  useEffect(() => {
    const fetchVehiclesNames = async () => {
      setLoading(true)

      try {
        const response = await axios.get(
          `${baseURL}${btoa("invoice/allvehicles")}`
        );
        dispatch(addVendor(response.data.vehicles_data))
        // setVehiclesNames(response.data.vehicles_data);
      } catch (error) {
        console.log("Error fetching vehicles names", error);
      } finally {
        setLoading(false)
      }
    };
    fetchVehiclesNames();
  }, [baseURL]);

  const handleSortingChange = (columnId) => {
    setSorting((old) => {
      if (old.length && old[0].id === columnId) {
        // Toggle sorting direction
        return [{
          id: columnId,
          desc: !old[0].desc,
        }];
      }
      // Default sorting to descending initially
      return [{ id: columnId, desc: true }];
    });
  };

  const table = useReactTable({
    data: data || [],
    columns: columns,
    state: {
      rowSelection: rowSelection,
      globalFilter: filtering,
      sorting: sorting,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    enableColumnFilter: true,
  });
  const currentPage = table.options.state.pagination.pageIndex;
  const expandedRowRef = useRef(null);
  const handleClickOutside = (event) => {
    if (expandedRowRef.current && !expandedRowRef.current.contains(event.target)) {
      setExpandedRow(null); // Close the expanded row
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      {loading && <FullScreenLoader />}

      <div className="w-full  mt-4">
        <ScrollingMessage />
      </div>

      {/* <div className="flex justify-between items-center mt-10 pr-7">
        <h1 className="mx-8 font-bold mb-3 text-gray-400 text-lg">
          Vendors Dashboard - {allVendors.length} Active Vendors
        </h1>
     
      </div> */}
       <nav aria-label="breadcrumb">
        <ol className="flex space-x-2 text-md font-bold text-gray-700 ml-[10px] mt-[20px] ] font-[15px]">
          <li className="font-[15px]">
            {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
            Setup
            {/* </a> */}
          </li>
          <li>
            <span className="text-gray-400 font-[15px]">{">>"}</span>
          </li>
          <li className="font-[15px]">
            {/* <a href="/purchases" className="text-blue-600 hover:text-blue-800"> */}
            Carriers Dashboard
            {/* </a> */}
          </li>
        </ol>
      </nav>

      {/* <div>
      <div className="flex full pl-8 pr-10 justify-between space-x-4">
          <div className="relative w-full mt-10">
              <AiOutlineSearch
                size={18}
                className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-700"
              />
              <input
                type="text"
                placeholder="Search..."
                className="pl-10 px-6 py-1 border-2 rounded-lg border-gray-300 outline-none block w-full focus:ring-3"
                value={filtering}
                onChange={e => setFiltering(e.target.value)}
              />
          </div>

            <div className="flex items-center mt-[38px] space-x-3">
              {table.getSelectedRowModel().flatRows[0]?.original && (
                <Tooltip title="Edit" position="top" trigger="mouseenter">
                  <button
                    className="items-center text-sm font-medium text-center focus:outline-none text-gray-500 hover:text-blue-700 rounded-lg"
                    type="button"
                    onClick={() => {
                      const vendorId = table.getSelectedRowModel().flatRows[0]?.original.id;
                      // alert(vendorId)
                      navigate(`/viewvendor/${vendorId}`);
                    }}
                  >
                    <div className="hover:rounded-full rounded-full p-2 hover:bg-blue-200">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                        <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                      </svg>
                    </div>
                  </button>
                </Tooltip>
              )}

              {table.getSelectedRowModel().flatRows[0]?.original && (
                <Tooltip title="Delete" position="top" trigger="mouseenter">
                  <button
                    className="items-center text-sm font-medium text-center focus:outline-none text-gray-500 hover:text-red-700 rounded-lg"
                    type="button"
                    onClick={() => {
                      const vendorId = table.getSelectedRowModel().flatRows[0]?.original.id;
                      const truckCompanyName = table.getSelectedRowModel().flatRows[0]?.original.truck_company_name
                      handleDeleteModalItem(vendorId ,truckCompanyName )
                      // Add your delete logic here
                    }}
                  >
                    <div className="rounded-full p-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </button>
                </Tooltip>
              )}
            </div>
      </div>

      <div id="toast-message">
      </div>
        
      </div> */}
      <div className="w-full mt-1">

        {/* <div className="flex mb-6 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto py-2 bg-white mt-3 ">
         <div
           className={`flex items-center cursor-pointer px-4 py-1 ${
             activeItem === "View" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
           } border-b border-gray-300  `}
           onClick={() => handleMenuClick("View")}
         >
           <span>Vendors</span>
         </div>
   
       </div> */}
        <div>
          <div className="flex justify-start space-x-4 p-1 mb-2 mt-4">
            {/* Card 1 */}
            <div
              className={`flex flex-col items-center justify-between w-1/6 bg-white rounded-lg p-4 relative hover:scale-105 hover:border-l-[6px] hover:border-gray-500 transition-all duration-300 ${clicked === 'draft' ? 'border-l-[6px] border-gray-500' : ''}`}
              style={{
                boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
                height: '90px', // Adjust as per your desired height
              }}
              onClick={() => { setFilteredStatus('draft'); setClicked('draft') }}
            >
              <div className="flex justify-between w-full">
                <span className="text-sm text-gray-500 font-bold">Active Carriers</span>
              </div>
              <div>
                <h2
                  className="absolute bottom-2 left-4  text-2xl font-bold text-grey-500 mt-2"

                >
                  {allVendors.length}
                </h2>
              </div>
              <div className="absolute bottom-0 right-0 w-[100px] h-[55px] bg-gradient-to-tl from-[#ffffff] to-transparent rounded-tl-full flex items-center justify-center">
                <DescriptionIcon sx={{ fontSize: 30 }} className="text-grey-500 mt-2" />
              </div>
            </div>

            {/* Card 2 */}
            {/* <div
       className={`flex flex-col items-center justify-between w-1/6 bg-white rounded-lg p-4 relative hover:scale-105 hover:border-l-[6px] hover:border-blue-500 transition-all duration-300 ${clicked === 'approved' ? 'border-l-[6px] border-blue-500' : ''}`}
       style={{
         boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
         height: '90px',
       }}
       onClick={() => {setFilteredStatus('approved'); setClicked('approved')}}
     >
       <div className="flex justify-between w-full">
         <span className="text-sm text-blue-500 font-bold"> Approved Contracts</span>
   
       </div>
       <div>
         <h2
           className="absolute bottom-2 left-4  text-2xl font-bold text-blue-500 mt-2"
         >
           0
         </h2>
       </div>
       <div className="absolute bottom-0 right-0 w-[100px] h-[50px] bg-gradient-to-tl from-[#ffffff] to-transparent rounded-tl-full flex items-center justify-center">
         <CheckCircleIcon sx={{ color: 'blue', fontSize: 30 }} className="mt-2" />
         
       </div>
     </div>   */}
            {/* <div
       className={`flex flex-col items-center justify-between w-1/6 bg-white rounded-lg p-4 relative hover:scale-105 hover:border-l-[6px] hover:border-green-500 transition-all duration-300 ${clicked === 'approved' ? 'border-l-[6px] border-blue-500' : ''}`}
       style={{
         boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
         height: '90px',
       }}
       onClick={() => {setFilteredStatus('approved'); setClicked('approved')}}
     >
       <div className="flex justify-between w-full">
         <span className="text-sm text-green-500 font-bold"> Licenses</span>
   
       </div>
       <div>
         <h2
           className="absolute bottom-2 left-4  text-2xl font-bold text-green-500 mt-2"
         >
           0
         </h2>
       </div>
       <div className="absolute bottom-0 right-0 w-[100px] h-[50px] bg-gradient-to-tl from-[#ffffff] to-transparent rounded-tl-full flex items-center justify-center">
         <CheckCircleIcon sx={{ color: 'green', fontSize: 30 }} className="mt-2" />
         
       </div>
     </div>  */}

            {userRole === '2' &&

              <div className='flex flex-row' style={{ display: 'none' }}>
                <div className="w-1/2">
                  <h3 className="text-lg font-semibold text-[#25caed] mb-4  underline absolute top-[120px] right-[5%]  ">
                    2025 JAN Vendors Summary

                    {/* {status.monthName && status.year ? `${status.year} ${status.monthName ? status.monthName.substring(0, 3).toUpperCase() : ''} Documents Summary` : ''} */}
                  </h3>
                </div>
                <div className="lg:w-[18%] rounded-md p-4 mt-[20px]  ml-auto absolute top-[145px] right-[50px] custombgColor border border-gray-300">
                  {/* Subtotal Section */}
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center gap-2">
                      <button onClick={toggleSubtotal} className="text-blue-500">
                        {isSubtotalExpanded ? '▼' : <span className="inline-block text-xl leading-none">►</span>}
                      </button>

                      <span className="text-gray-800 font-medium">Total Vendors:</span>
                    </div>
                    <span className="text-gray-800 font-medium">
                      0{/* {formatNumberWithCommas( Number(status.total_draftInvoices+ status.total_approvedInvoices + status.total_sentInvoices) || 0 )}  */}
                    </span>
                  </div>

                  {isSubtotalExpanded && (
                    <>
                      <div className="ml-6 mb-4">
                        <div className="flex justify-between" >
                          <span className="capitalize">Other Vendors :</span>
                          <span className="font-normal">
                            {/* {formatNumberWithCommas(status.total_draftInvoices)} */}0
                          </span>
                        </div>

                        <div className="flex justify-between font-normal pt-2">
                          <span className="">Others :</span>
                          <span className="border-t border-black  text-right">
                            0{/* {formatNumberWithCommas( Number(status.total_draftInvoices+ status.total_approvedInvoices + status.total_sentInvoices) || 0 )}  */}

                          </span>
                        </div>
                      </div>

                    </>
                  )}

                  {/* Taxes Section */}
                  <div className=" flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <button onClick={toggleTaxes} className="text-blue-500">
                        {isTaxesExpanded ? '▼' : <span className="inline-block text-xl leading-none">►</span>
                        }
                      </button>
                      <span className="text-gray-800 font-medium"> Others:</span>
                    </div>
                    <span className="text-gray-800 font-medium">
                      {/* ${(
     (status?.invoice_sum_data?.[0]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[0]?.total_final_invoice_amount) : 0) +
     (status?.invoice_sum_data?.[1]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[1]?.total_final_invoice_amount) : 0) +
     (status?.invoice_sum_data?.[2]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[2]?.total_final_invoice_amount) : 0)
   ).toFixed(2) || 0} */}
                      0

                    </span>
                  </div>

                  {isTaxesExpanded && (
                    <div className="ml-6 mt-2">
                      <div className="flex justify-between">
                        <span className="capitalize">Others:</span>
                        <span className="font-normal">
                          {/* $ {Number(status.invoice_sum_data[0].total_final_invoice_amount).toLocaleString(undefined, {
                     minimumFractionDigits: 2,
                     maximumFractionDigits: 2,
                   })} */}0
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="capitalize">Others:</span>
                        <span className="font-normal">
                          {/* $ {Number(status.invoice_sum_data[1].total_final_invoice_amount).toLocaleString(undefined, {
                     minimumFractionDigits: 2,
                     maximumFractionDigits: 2,
                   })} */}0
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="capitalize">Others:</span>
                        <span className="font-normal">
                          {/* $ {Number(status.invoice_sum_data[2].total_final_invoice_amount).toLocaleString(undefined, {
                     minimumFractionDigits: 2,
                     maximumFractionDigits: 2,
                   })} */}0
                        </span>
                      </div>
                      <li className="flex justify-between font-normal pt-2">
                        <span>Others:</span>
                        <span className="border-t border-black  text-right">
                          0{/* ${(
     (status?.invoice_sum_data?.[0]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[0]?.total_final_invoice_amount) : 0) +
     (status?.invoice_sum_data?.[1]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[1]?.total_final_invoice_amount) : 0) +
     (status?.invoice_sum_data?.[2]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[2]?.total_final_invoice_amount) : 0)
   ).toFixed(2) || 0} */}

                        </span>
                      </li>
                    </div>
                  )}
                </div>
              </div>
            }
          </div>

        </div>
      </div>


      <div>
        <div className="flex w-full justify-end items-center space-x-2  mt-[163px] py-[8px] rounded-t-xl">
          {/* <div className="relative w-[160px] focus-within:w-full transition-all duration-300 max-w-full ml-auto">
    <AiOutlineSearch
            size={18}
            className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-700"
          />
          <input
            type="text"
            placeholder="Search..."
            className="pl-10 px-6 py-1 border-2 rounded-lg border-gray-300 outline-none block w-full focus:ring-3"
            value={filtering}
            onChange={e => setFiltering(e.target.value)}
          />
        </div> */}
          {/* <div className="flex jsutify-end items-end space-x-2 "> */}
            <div className="flex items-center justify-center bg-white p-2 rounded-lg h-[30px] shadow-lg cursor-pointer"
              style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)", }}
            //  onClick={() => setIsfilterOpen(!isfilterOpen)}
            >
              {/* MdFilterList Icon */}
              <MdFilterList className=" w-5 h-[16px]  mr-2" />
              {/* Filters Text */}
              {<span className="text-sm ">Filters</span>}
            </div>
            <div>
              <button
                className="flex items-center justify-center font-bold mr-3 w-55 h-[35px] border-transparent border-2 text-white bg-[#25caed] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-base px-4 py-1.5 focus:outline-none "
                type="button"
                onClick={() => navigate(`/createvendor`)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  className="w-5 h-5 mr-3"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                </svg>
                New Carrier
              </button>
            </div>
          {/* </div> */}
        </div>
        <ToastNotificationBox />
        <div id="toast-wrapper"></div>
      </div>
      <div className="">
        <div className="w-full rounded-b-xl pt-3 px-3 pb-4 bg-white w-full  rounded-b-xl px-3 bg-white rounded-tl-[12px] rounded-tr-[12px] shadow-[4px_4px_8px_rgba(0,0,0,0.1),-4px_4px_8px_rgba(0,0,0,0.1),4px_-4px_8px_rgba(0,0,0,0.1),-4px_-4px_8px_rgba(0,0,0,0.1)]
">
          <table className="w-full table-auto bg-transparent rounded-xl">
            <thead className="bg-blue-900 text-white">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="text-center text-xs">
                  {headerGroup.headers.map((header, index) => {
                    const isFirstHeader = index === 0;
                    const isLastHeader = index === headerGroup.headers.length - 1;
                    const headerClasses = [
                      'p-3 font-bold uppercase',
                      isFirstHeader ? 'rounded-l-xl' : '',
                      isLastHeader ? 'rounded-r-xl' : '',
                    ];

                    const isSorted = header.column.getIsSorted();
                    const showSortingIcons = header.column.columnDef.enableSorting !== false;
                    const ArrowUpIcon = (
                      <FaArrowUpLong
                        className={`${isSorted === 'asc'  ? 'text-[#06b6d4]' : 'text-white'}`}
                        size={12}
                      />
                    );
                    const ArrowDownIcon = (
                      <FaArrowDownLong
                        className={`${isSorted === 'desc'  ? 'text-[#06b6d4]' : 'text-white'}`}
                        size={12}
                      />
                    );

                    return (
                      <th
                        key={header.id}
                        className={headerClasses.join(' ')}
                        colSpan={header.colSpan}
                        onClick={() => showSortingIcons && handleSortingChange(header.column.id)}
                      >
                        <div className="flex items-center justify-center">
                          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                          {showSortingIcons && (
                            <div className="flex items-center ml-2">
                              {ArrowDownIcon}
                              {ArrowUpIcon}
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white">
              {table.getRowModel().rows.length > 0 ? (

                table.getRowModel().rows.map((row) => (
                  <>
                    <tr key={row.id} className="cursor-pointer text-center hover:shadow-[0_4px_8px_rgba(0,0,0,0.1)] transition-shadow "
                      style={{ borderBottom: "0.3px solid #e0e0e0" }}
                      // onClick={() => toggleRow(row.id)}
                      onClick={() => {
                        localStorage.setItem('vendorId',row.original.id)
                        localStorage.setItem('truckcompanyname',row.original.truck_company_name)

                        // handleDeleteModalItem();
                        navigate('/VendorScreen', {
                          state: {
                            vendorId: row.original.id,
                            truckcompanyname: row.original.truck_company_name,
                          }
                        });
                      }}

                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="p-4 font-semibold text-[#081159] text-[14px]">
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                    {expandedRow === row.id && (

                      <tr ref={expandedRowRef}>
                        <td
                          colSpan={table.getHeaderGroups()[0].headers.length}
                          className="bg-gray-100 p-4 border-t-[2px] border-[#25caed]"
                        >
                          <div className="text-sm text-gray-800 font-normal text-center" style={{ width: '100%' }}>
                            {(
                              <div className="flex flex-col gap-2">
                                {/* Row 1: EIN number */}
                                <p> <b>EIN Number:</b>  {row.original.ein_number}</p>
                                <p><b>Contact Email:</b> {row.original.contact_email}</p>


                              </div>
                            )}
                          </div>
                          <div
                            className="flex flex-col items-center gap-3 mb-0"
                            style={{
                              position: 'fixed',
                              right: '20px',
                              bottom: '12%',
                            }}
                          >
                            {row.original && (
                              <>
                                <Tooltip title="View" position="top" trigger="mouseenter">
                                  <button
                                    className=" items-center text-sm font-medium text-center focus:outline-none border-black text-black rounded-full bg-white hover:bg-gray-200 border p-2"
                                    type="button"
                                  // onClick={() =>handleView( table.getSelectedRowModel().rows[0].original.img_file)}
                                  // onClick={()=>  console.log(table.getSelectedRowModel().rows[0].original.brand_invoice_number)}
                                  >
                                    <div className="rounded-full p-1">
                                      <AiFillEye size={20} />
                                    </div>
                                  </button>
                                </Tooltip>
                                <Tooltip title="Edit" position="top" trigger="mouseenter">
                                  <button
                                    className="items-center text-sm font-medium text-center focus:outline-none border border-purple-800 text-purple-800 rounded-full bg-white hover:bg-purple-300 p-2"
                                    type="button"
                                    onClick={() => {
                                      const vendorId = row.original?.id;
                                      if (vendorId) navigate(`/viewvendor/${vendorId}`);
                                      else alert('Vendor ID is missing!');
                                    }}
                                  >
                                    <div className="rounded-full p-1" style={{
                                    }}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-5 h-5"
                                      >
                                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                        <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                      </svg>
                                    </div>
                                  </button>
                                </Tooltip>

                                <Tooltip title="Delete" position="top" trigger="mouseenter">
                                  <button
                                    className="ml-2 items-center text-sm font-medium text-center focus:outline-none border border-red-600 text-red-600 rounded-full bg-white hover:bg-red-300 p-2"
                                    type="button"
                                    onClick={() => {
                                      const vendorId = row.original?.id;
                                      const truckCompanyName =
                                        row.original?.truck_company_name;
                                      if (vendorId && truckCompanyName) {
                                        handleDeleteModalItem(vendorId, truckCompanyName, row);
                                      } else {
                                        alert('Vendor dat0207ja is missing!');
                                      }
                                    }}
                                  >
                                    <div className="rounded-full p-1"

                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-5 h-5"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </div>
                                  </button>
                                </Tooltip>

                              </>
                            )}
                          </div>
                        </td>

                      </tr>


                    )}
                  </>
                ))
              ) : (
                // Show empty state if no rows
                <tr>
                  <td colSpan={columns.length} className="text-center py-12">
                    <div className="text-gray-500 text-lg">
                      No data available
                    </div>
                  </td>
                </tr>
              )}

            </tbody>
          </table>
        
        </div>
        <div className="flex items-center mr-7 mt-6 gap-2 justify-end">
            <button
              className={`px-4 py-2 rounded-l-lg focus:outline-none flex items-center ${table.getCanPreviousPage()
                ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                : " cursor-not-allowed"
                }`}
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <MdNavigateBefore className="w-6 h-6" />
              Prev
            </button>
            {[...Array(table.getPageCount()).keys()].map((page) => (
              <button
                key={page}
                onClick={() => table.setPageIndex(Number(page))}
                className={`px-4 py-2  focus:outline-none ${currentPage === page
                  ? " rounded-full bg-[#3479E0] text-white "
                  : " rounded-full bg-white dark:text-black hover:bg-[#3479E0]/50 hover:text-white"
                  }`}
              >
                {page + 1}
              </button>
            ))}
            <button
              className={`px-4 py-2 rounded-r-lg  focus:outline-none flex items-center justify-center${table.getCanNextPage()
                ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                : "bg-gray-300 cursor-not-allowed"
                }`}
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
              <MdNavigateNext className="w-6 h-6" />
            </button>
          </div>
        {openDeleteModalId && (
          <PopUpModalForDeleteVendor
            onClose={() => setOpenDeleteModalId(null)}
            vendorId={openDeleteModalId}
            truckCompanyName={TruckNumber}
          // resetRowSelection={table.resetRowSelection}
          />
        )}

      </div>
    </div>
  );
};

export default Vendors;
