
import React, { useState, useEffect } from 'react';
import { DatePicker, Space } from "antd";
import dayjs from 'dayjs';
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import ToastNotificationBox from "../../components/pages/ToastBox/ToastNotificationBox";

import bankImg from './../../../src/assets/images/bank.png';
import cardImg from './../../../src/assets/images/card.png';
import checkImg from './../../../src/assets/images/check.png';
import cashImg from './../../../src/assets/images/cash.png';
import othersimg from './../../../src/assets/images/others.png';
import { useSearchParams, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import config from "../../config/config"
import axios from "axios";
import { useDispatch } from "react-redux";
import { addToast } from './ToastBox/ToastBoxMessageSlice';
import InvoiceView from '../forms/invoiceview';
import { FaEye, FaCheck, FaPaperPlane, FaTrash, FaArrowLeft, FaDownload, FaEdit, FaHome } from "react-icons/fa";
import CustomCommonTab from '../reusable/CustomCommonTab';
import CustomNavigation from '../reusable/CustomNavigationIcon';

function PayInvoice() {
  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invoicenumber } = useParams();

  const [invoiceData, setInvoiceData] = useState({
    businessName: '',
    FinalInvoiceAmount: 0,
    invoicenumber: '',
    dueDate: '',
    triggerFetch: false
  });
  
  // Then in useEffect, consolidate data sources with a clear priority
  useEffect(() => {
    // First priority: Use data from location state if available
    if (state && state.businessName && state.FinalInvoiceAmount) {
      setInvoiceData({
        businessName: state.businessName,
        FinalInvoiceAmount: state.FinalInvoiceAmount,
        invoicenumber: state.invoicenumber || invoicenumber,
        dueDate: state.dueDate || '',
        triggerFetch: state.triggerFetch || false
      });
      
      // Also save to localStorage for persistence
      localStorage.setItem("invoiceData", JSON.stringify({
        businessName: state.businessName,
        FinalInvoiceAmount: state.FinalInvoiceAmount,
        invoicenumber: state.invoicenumber || invoicenumber,
        dueDate: state.dueDate || '',
        triggerFetch: state.triggerFetch || false
      }));
    } 
    // Second priority: Try localStorage
    else {
      const storedData = localStorage.getItem("invoiceData");
      if (storedData) {
        try {
          const parsedData = JSON.parse(storedData);
          setInvoiceData(parsedData);
        } catch (e) {
          console.error('Error parsing stored invoice data:', e);
        }
      }
      // If nothing works, keep URL parameter as fallback
      else if (invoicenumber) {
        setInvoiceData(prev => ({...prev, invoicenumber}));
      }
    }
  }, [state, invoicenumber]);
  // const customerName = state?.businessName;
  // const finalInvoiceAmount = Number(state?.FinalInvoiceAmount);
  // const invoicenumberforpayinvoice = state?.invoicenumber;
  // const dueDateinvoice = state?.dueDate;

  const imageMap = {
    bank: bankImg,
    card: cardImg,
    check: checkImg,
    cash: cashImg,
    others: othersimg,
  };

  // useEffect(() => {
  //   const storedData = localStorage.getItem('invoiceData');
  //   if (storedData) {
  //     try {
  //       const parsedData = JSON.parse(storedData);
  //       setInvoiceData(parsedData);
  //     } catch (e) {
  //       console.error('Error parsing stored invoice data:', e);
  //     }
  //   }
  // }, []);
  // const customerName = state?.businessName;
  // const finalInvoiceAmount = Number(state?.FinalInvoiceAmount);
  // const invoicenumberforpayinvoice = state?.invoicenumber;
  // const dueDateinvoice = state?.dueDate;
  const customerName = invoiceData?.businessName;
  const finalInvoiceAmount = Number(invoiceData?.FinalInvoiceAmount);
  const invoicenumberforpayinvoice = invoiceData?.invoicenumber;
  const dueDateinvoice = invoiceData?.dueDate;
 


  // const [invoiceData, setInvoiceData] = useState({
  //   businessName: '',
  //   FinalInvoiceAmount: 0,
  //   dueDate: '',
  //   customer_id: '',
  //   triggerFetch: false,
  //   invoicenumber
  // });

  // const customerName = invoiceData.businessName;
  // const finalInvoiceAmount = Number(invoiceData.FinalInvoiceAmount);
  // const dueDateinvoice = invoiceData.dueDate;
  // const invoicenumberforpayinvoice = invoiceData.invoicenumber


  const businessId = localStorage.getItem("business_id");
  const customerId = localStorage.getItem('customerId');
  const userRole = localStorage.getItem("user_role");

  const [invoiceDate1, setInvoiceDate1] = useState(dayjs());
  const [paymentType, setPaymentType] = useState('fullPaid');
  const [amount, setAmount] = useState(finalInvoiceAmount);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [comment, setComment] = useState('');
  const [remainingAmount, setRemainingAmount] = useState(null);
  const [CustomerInfo, setCustomerInfo] = useState([]);
  const [status, setStatus] = useState('');
  const [activeItem, setActiveItem] = useState("View");
  const [isClicked, setIsClicked] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(dayjs());

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  
  const handleAmount = (e) => {
    let value = e.target.value;
    
    // Make sure we're working with a string
    value = String(value);
    
    // Remove any existing formatting
    value = value.replace(/[$,]/g, '');
    
    // Only allow digits and one decimal point
    value = value.replace(/[^\d.]/g, '');
    
    // Ensure only one decimal point
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }
    
    // Limit decimal places to 3 digits
    if (parts.length === 2 && parts[1].length > 3) {
      value = parts[0] + '.' + parts[1].substring(0, 3);
    }
    
    // Apply formatting only if there's a value
    if (value) {
      // Special handling for decimal input
      if (value.includes('.')) {
        const [wholePart, decimalPart] = value.split('.');
        const wholeNum = parseFloat(wholePart || '0');
        
        // Format whole part with commas
        const formattedWhole = !isNaN(wholeNum) ? wholeNum.toLocaleString() : '0';
        
        // Preserve the decimal part exactly as typed, including trailing zeros
        // but limit to 3 digits
        const limitedDecimalPart = decimalPart.substring(0, 3);
        value = `$ ${formattedWhole}.${limitedDecimalPart}`;
      } 
      // For whole numbers
      else {
        const num = parseFloat(value);
        if (!isNaN(num)) {
          value = '$ ' + num.toLocaleString();
        } else {
          value = '$ ' + value;
        }
      }
    } else {
      value = '';
    }
    
    // Update state
    setAmount(value);
  };

  const handlePaymentTypeChange = (e) => {
    const selectedPaymentType = e.target.value;
    setPaymentType(selectedPaymentType);
    
    if (selectedPaymentType === 'fullPaid') {
      setAmount(`$${finalInvoiceAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`);
      setRemainingAmount(0);
    } else if (selectedPaymentType === 'partialPaid') {
      setAmount('');
      setRemainingAmount(finalInvoiceAmount);
    }
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handleInvoiceDateChange = (date) => {
    setInvoiceDate(date);
  };

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isClicked) return;
    const url = `${baseURL}/${btoa("invoice/customerpayment")}`;
    setIsClicked(true);
  
    try {
      // Safe extraction of numeric value
      const numericAmount = paymentType === 'fullPaid' 
        ? finalInvoiceAmount 
        : extractNumericValue(amount);
        
      const formattedReceivedAmount = parseFloat(numericAmount).toFixed(2);
      const formattedDueAmount = paymentType === 'fullPaid' 
        ? 0 
        : (finalInvoiceAmount - numericAmount).toFixed(2);
  
      const response = await axios.post(url, {
        custName: customerName,
        businessId: localStorage.getItem('business_id'),
        managerId: localStorage.getItem('user_id'),
        invoiceNumber: invoicenumberforpayinvoice,
        finalInvoiceAmount: parseFloat(finalInvoiceAmount.toFixed(2)),
        payType: paymentType,
        receivedAmount: Number(formattedReceivedAmount),
        dueAmount: Number(formattedDueAmount),
        comment: paymentType === 'fullPaid' ? comment : comment || null,
        userRole: userRole,
        paymentDate: dayjs(invoiceDate).format('YYYY-MM-DD')
      });
  
      if (response.data.dataSavingStatus === true) {
        dispatch(addToast({
          type: 'success',
          message: paymentType === "fullPaid"
            ? `${customerName} Paid Full Amount successfully`
            : `${customerName} Paid Partial Amount successfully`
        }));
        navigate('/payIns');
      }
    } catch (error) {
      console.error("Payment error:", error);
    } finally {
      setIsClicked(false);
    }
  };
  const extractNumericValue = (value) => {
    if (value === null || value === undefined) return 0;
    
    if (typeof value === 'number') return value;
    
    // Ensure we're working with a string
    const stringValue = String(value);
    
    // Remove all non-numeric characters except decimal point
    const cleanValue = stringValue.replace(/[^0-9.]/g, '');
    
    // Parse to float, or default to 0 if parsing fails
    const numericValue = parseFloat(cleanValue);
    return isNaN(numericValue) ? 0 : numericValue;
  };
  
  // Fixed validation for amount exceeding finalInvoiceAmount
  const isAmountExceedingLimit = () => {
    if (!amount) return false;
    const numericAmount = extractNumericValue(amount);
    return numericAmount > finalInvoiceAmount;
  };
  
  // Update the getPaymentMemo function to use the helper
  const getPaymentMemo = () => {
    if (paymentType === "fullPaid") {
      return `${formatDate1(invoiceDate)} ${invoicenumberforpayinvoice} for ${customerName} paid full invoice amt through ${paymentMethod}. Invoice is closed`;
    } else if (paymentType === "partialPaid" && amount) {
      const numericAmount = extractNumericValue(amount);
      if (numericAmount > 0) {
        return `${formatDate1(invoiceDate)} ${invoicenumberforpayinvoice} for ${customerName} paid partial amt through ${paymentMethod}. Pending balance is $ ${(finalInvoiceAmount - numericAmount).toFixed(2)}`;
      }
    }
    return comment;
  };
  
  // Updated disabled check
  const isDisabled = !paymentType || !invoiceDate || !invoicenumberforpayinvoice || !paymentMethod ||
    (paymentType === "partialPaid" && (!amount || isAmountExceedingLimit()));
    useEffect(() => {
      if (invoiceData.invoicenumber) {
        const fetchDataByInvoice = async () => {
          try {
            const apiUrl = `${baseURL}/${btoa("invoice/fetchinvoicebyinvooiocenumber")}/${btoa(invoiceData.invoicenumber)}/${btoa(businessId)}/${btoa(customerId)}`;
            const response = await axios.get(apiUrl);
            setStatus(response.data.customerData[0].invoice_status);
            setCustomerInfo(response.data.customerInfo[0]);
          } catch (error) {
            console.error("Error fetching invoice by invoice number", error);
          }
        };
        fetchDataByInvoice();
      }
    }, [invoiceData.invoicenumber, businessId, customerId]);
  const formatDate1 = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };



// The issue is in this useEffect - you need to update this code block
useEffect(() => {
  if (paymentType && invoiceDate && invoicenumberforpayinvoice && customerName && paymentMethod && finalInvoiceAmount) {
    // Use the extractNumericValue helper function instead of trying to call replace directly
    const numericAmount = extractNumericValue(amount);
    
    if (numericAmount > finalInvoiceAmount) {
      setComment('');
    } else {
      setComment(getPaymentMemo());
    }
  }
}, [paymentType, invoiceDate, invoicenumberforpayinvoice, customerName, paymentMethod, finalInvoiceAmount, amount]);

// Make sure this extractNumericValue function is defined in your component


  function maskNumber(number) {
    const numberStr = number?.toString() || '';
    const maskedPart = "X".repeat(Math.max(0, numberStr.length - 3));
    return `${maskedPart}${numberStr.slice(-3)}`;
  }

  const disablefutureDates = (current) => {
    return current && current > dayjs().startOf('day');
  };

  const menuItems = [{ name: "Payment" }];
  return (

<>
<CustomCommonTab items={menuItems} />
<div className="flex flex-col justify-center items-center mt-4">
      <div className="relative w-[62vw] mx-auto p-6 border border-gray-200 rounded-md shadow-md bg-white"
        style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: 'bold', boxShadow: '0 0 20px 0 rgba(0, 0, 0,.3)' }}>
         
         {/* <CustomNavigation /> */}
         <div className="flex items-center justify-end">
      {/* Home button - bordered/unfilled by default, filled blue on hover */}
      <div
        className="absolute -top-5 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-1 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
        onClick={() => {
          navigate("/");
        }}
        style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
      >
        <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16} />
      </div>

      {/* Close Icon - bordered/unfilled by default, filled red on hover */}
      <div
        className="absolute -top-5 -right-4 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
        onClick={() => navigate(`/invoiceview/${invoicenumberforpayinvoice}`)}
      >
        <span className="text-red-500 group-hover:text-white text-xl font-bold  transition-colors duration-200">&times;</span>
      </div>
    </div>
        <div className='mx-auto max-w-3xl'>
          {/* Navigation Tabs */}
          {/* <div className="flex mb-8 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto py-2 bg-white mt-3">
            <div
              className={`flex items-center cursor-pointer px-4 py-1 ${activeItem === "View" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"} border-b border-gray-300`}
              onClick={() => handleMenuClick("View")}
            >
              <FaEye className="mr-2" />
              <span>Payment</span>
            </div>

            <div
              className={`flex items-center cursor-pointer px-4 py-1 ${activeItem === "Back" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"} border-b border-gray-300`}
              onClick={() => {
                handleMenuClick("Back");
                navigate('/');
              }}
            >
              <FaArrowLeft className="mr-2" />
              <span>Back</span>
            </div>
            
            <div
              className={`flex items-center cursor-pointer px-4 py-[7px] ${activeItem === "Home" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"} border-b border-gray-300`}
              onClick={() => { handleMenuClick("Home"); navigate('/') }}
            >
              <FaHome className="text-lg" />
            </div>
          </div> */}

          <form className="space-y-6">
            {/* Date Field */}
            <div className="grid grid-cols-3 items-center">
              <label className=" text-gray-700 font-bold">Date</label>
              <div className="col-span-2">
                <DatePicker
                  id="invoiceDate"
                  disabledDate={disablefutureDates}
                  format="MM-DD-YYYY"
                  className="w-full h-[35px] rounded-md border border-gray-300"
                  value={invoiceDate}
                  onChange={handleInvoiceDateChange}
                  placeholder="MM-DD-YYYY"
                />
              </div>
            </div>

            <div className="grid grid-cols-3 items-center">
              <label className="text-gray-700 font-bold">Payment Type</label>
              <div className="col-span-2 flex justify-center items-center space-x-24">
                <div className="flex items-center justify-center">
                  <input
                    id="fullPaid"
                    type="radio"
                    name="paymentType"
                    value="fullPaid"
                    checked={paymentType === 'fullPaid'}
                    onChange={handlePaymentTypeChange}
                    className="mr-2 h-5 w-5 accent-pink-500  my-auto"
                  />
                  <label htmlFor="fullPaid" className="text-sm text-gray-700  my-auto">
                    FULL PAY
                  </label>
                </div>

                <div className="flex items-center justify-center">
                  <input
                    id="partialPaid"
                    type="radio"
                    name="paymentType"
                    value="partialPaid"
                    checked={paymentType === 'partialPaid'}
                    onChange={handlePaymentTypeChange}
                    className="mr-2 h-5 w-5 accent-pink-500  my-auto"
                  />
                  <label htmlFor="partialPaid" className="text-sm text-gray-700  my-auto">
                    PARTIAL PAY
                  </label>
                </div>
              </div>
            </div>

            {/* Amount Field */}
            <div className="grid grid-cols-3 items-center">
  <label className="text-gray-700 font-bold">Amount</label>
  <div className="col-span-2">
    {paymentType === "partialPaid" ? (
      <div className="relative w-full">
        <div className="relative">
          <div className="absolute left-0 top-0 h-full flex items-center bg-gray-200 px-2 rounded-l-md z-10">
            <span className="text-gray-600 font-medium">$ {finalInvoiceAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
          </div>
          <input
            type="text"
            id="amount"
            name="amount"
            placeholder="Enter amount"
            value={amount}
            onChange={handleAmount}
            className={`font-medium w-full h-[35px] pl-[9rem] rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
              ${isAmountExceedingLimit() ? "border-red-500 focus:border-red-500 focus:ring-red-500" : "border-gray-300"}`}
          />
        </div>
        {isAmountExceedingLimit() && (
          <p className="text-red-500 text-sm mt-1">Amount cannot exceed final invoice amount.</p>
        )}
      </div>
    ) : (
      <input
        type="text"
        id="amount"
        name="amount"
        value={`$ ${finalInvoiceAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        readOnly
        className="bg-gray-200 font-medium block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 h-[35px]"
      />
    )}
  </div>
</div>

            {/* Payment Method Selection */}
            <div className="grid grid-cols-3 items-start">
              <label className=" text-gray-700 pt-2 font-bold">Method</label>
              <div className="col-span-2 flex flex-wrap gap-4">
                {['bank', 'card', 'check', 'cash', 'others'].map((method) => (
                  <div key={method} className="flex flex-col items-center">
                    <img
                      src={imageMap[method]}
                      alt={method}
                      className={`w-12 h-12 object-contain cursor-pointer ${paymentMethod === method ? 'border-4 border-indigo-500 rounded-lg' : ''}`}
                      onClick={() => handlePaymentMethodChange(method)}
                    />
                    <span className="mt-1 text-sm text-gray-700 capitalize">{method}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Bank Details */}
            {paymentMethod === 'bank' && (
              <>
                <div className="grid grid-cols-3 items-center">
                  <label className="font-bold text-gray-700">Bank Routing #</label>
                  <div className="col-span-2">
                    <input
                      type="text"
                      id="bankRouting"
                      name="bankRouting"
                      value={(CustomerInfo?.bank_routing ? maskNumber(CustomerInfo.bank_routing) : '')}
                      readOnly
                      className="bg-gray-200 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 h-[35px]"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 items-center">
                  <label className="font-bold text-gray-700">Bank A/c #</label>
                  <div className="col-span-2">
                    <input
                      type="text"
                      id="bankAccount"
                      name="bankAccount"
                      value={CustomerInfo?.bank_account ? maskNumber(CustomerInfo.bank_account) : ''}
                      readOnly
                      className="bg-gray-200 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 h-[35px]"
                    />
                  </div>
                </div>
              </>
            )}

            {/* Card Details */}
            {paymentMethod === 'card' && (
              <div className="grid grid-cols-3 items-center">
                <label className="font-bold  text-gray-700">Card #</label>
                <div className="col-span-2">
                  <input
                    type="text"
                    id="cardNumber"
                    name="cardNumber"
                    value={CustomerInfo?.card_number ? maskNumber(CustomerInfo.card_number) : ''}
                    readOnly
                    className="bg-gray-200 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 h-[35px]"
                  />
                </div>
              </div>
            )}

            {/* Memo/Notes */}
            <div className="grid grid-cols-3 items-start">
              <label className="font-bold text-gray-700 pt-2">Memo/Notes</label>
              <div className="col-span-2">
                <textarea
                  id="memo"
                  name="memo"
                  rows="3"
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm font-normal"
                ></textarea>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex justify-end gap-4 mt-10">
              <button
                type="submit"
                className={`w-40 px-4 flex items-center justify-center py-2 border-2 uppercase rounded-md duration-200 active:scale-90 h-[32px] my-auto
                  ${isDisabled 
                    ? 'bg-gray-100 border-gray-600 text-gray-600 cursor-not-allowed' 
                    : 'border-blue-500 text-blue-500 hover:font-bold hover:bg-blue-700 hover:shadow-blue-700/50 hover:text-white hover:shadow-lg hover:border-transparent'}`}
                onClick={handleSubmit}
                disabled={isDisabled}
              >
                PAY
              </button>
              <button
                type="button"
                onClick={() => navigate(`/invoiceview/${invoicenumberforpayinvoice}`)}
                className="w-40 flex items-center  justify-center  h-[32px] my-auto px-4 py-2 border-2 border-red-500 text-red-500 hover:font-bold rounded-md hover:bg-[#FF0001] hover:shadow-red-700/50 uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90"
              >
                CANCEL
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Toast and Invoice View */}
      <ToastNotificationBox />
      {/* <InvoiceView 
        // invoicenumberpay={invoicenumberforpayinvoice} 
        // customerId={customerId}
        // statuspay={status} 
        // business_name={customerName} 
        // finalInvoiceAmount={finalInvoiceAmount} 
        // dueDatepay={dueDateinvoice} 
        invoiceData={localStorage.getItem('invoiceData')}
      /> */}
{/* In PayInvoice.js */}
{/* Only render InvoiceView when you have the required data */}
{invoiceData.invoicenumber && status ? (
  <InvoiceView 
    invoicenumberpay={invoiceData.invoicenumber} 
    statuspay={status} 
    business_name={invoiceData.businessName} 
    finalInvoiceAmount={Number(invoiceData.FinalInvoiceAmount)} 
    dueDatepay={invoiceData.dueDate} 
  />
) : (
  <div className="flex justify-center items-center h-40">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
  </div>
)}
    </div>
</>


    
  );
}

export default PayInvoice;