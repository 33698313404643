import React from 'react';
import CustomCommonTab from '../reusable/CustomCommonTab';
import CustomNavigation from '../reusable/CustomNavigationIcon';
import { useNavigate } from 'react-router-dom';
import { FaHome } from "react-icons/fa";

const NewDocument = () => {
const navigate = useNavigate();
  const menuItems = [{ name: "New Documents" }];

  return (
    <>
     <CustomCommonTab items={menuItems} />
    <div
      style={{ 
        paddingTop: '36px',
        position: 'relative',
        width: '880px',
        margin: '40px auto',
        textAlign: 'center',
        padding: '23px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor:'white'
      }}
    >    
    {/* <CustomNavigation onCloseClick={() =>  navigate("/MyDocs")}  /> */}
    <div className="flex items-center justify-end">
          {/* Home button - bordered/unfilled by default, filled blue on hover */}
          <div
            className="absolute -top-5 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-1 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
            onClick={() => {
              navigate("/");
            }}
            style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
          >
            <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16} />
          </div>
    
          {/* Close Icon - bordered/unfilled by default, filled red on hover */}
          <div
            className="absolute -top-5 -right-4 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
            onClick={() => navigate('/MyDocs')}
          >
            <span className="text-red-500 group-hover:text-white text-xl font-bold  transition-colors duration-200">&times;</span>
          </div>
        </div>
      {/* Dropdowns */}
      <div style={{ marginBottom: '20px' }} className='w-50'>
        {/* <label htmlFor="customer" style={{ display: 'block', marginBottom: '8px' }}>
          Select Customer
        </label> */}
        <select
          id="customer"
          style={{
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        >
          <option value="" disabled selected>
            -- Select Customer --
          </option>
          <option value="customer1">Customer 1</option>
          <option value="customer2">Customer 2</option>
          <option value="customer3">Customer 3</option>
        </select>
      </div>

      <div style={{ marginBottom: '20px' }} className='w-50'>
        {/* <label htmlFor="document" style={{ display: 'block', marginBottom: '8px' }}>
          Select Document
        </label> */}
        <select
          id="document"
          style={{
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        >
          <option value="" disabled selected>
            -- Select Document --
          </option>
          <option value="contracts">Customer Contracts / Agreements</option>
          <option value="licenses">Licenses</option>
          <option value="miscellaneous">Miscellaneous</option>
        </select>
      </div>

      <div style={{ marginBottom: '20px' }} className='w-50'>
        {/* <label htmlFor="documentType" style={{ display: 'block', marginBottom: '8px' }}>
          Select Document Type
        </label> */}
        <select
          id="documentType"
          style={{
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        >
          <option value="" disabled selected>
            -- Select Document Type --
          </option>
          <option value="pdf">PDF</option>
          <option value="word">Word</option>
          <option value="images">PNG, JPG</option>
        </select>
      </div>

      {/* Submit Button */}
      <button
        className="w-40 px-2 py-1 border-2 border-blue-600
        hover:font-bold rounded-md hover:bg-blue-600 hover:shadow-blue-600/50
        uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90"
      >
        Submit
      </button>
    </div>
    </>
   
  );
};

export default NewDocument;
