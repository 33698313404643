

import React, { useCallback } from "react";
import moment from "moment";
import fullpay from "../../assets/images/fullpay.jpg";

import { useState, useEffect, useRef } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import config from "../../config/config";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { addToast } from "../../components//pages/ToastBox/ToastBoxMessageSlice";
import ToastNotificationBox from "../../components/pages/ToastBox/ToastNotificationBox";
import NotesIcon from "@mui/icons-material/Notes";

import FullScreenLoader from "../pages/FullScreenLoader";
import {
  FaEye,
  FaCheck,
  FaPaperPlane,
  FaTrash,
  FaArrowLeft,
  FaDownload,
  FaEdit,
  FaPen,
  FaSave
} from "react-icons/fa";
import PopUpModalForSendInvoice from "../../components/pages/invoiceSendModel";
import InvoicePayModal from "../pages/InvoicePayModal";
import PopUpModal from "../../components/pages/InvoiceDeleteModal";
import TabMenu from "../pages/TabMenu";
import PurchaseReport from "../pages/PurchaseReport";
import Sales from "../pages/Sales/Sales";
import InvoiceReport from "../pages/Invoice/InvoiceReport";
import { number } from "yup";
import FaxIcon from "../../../src/assets/images/fax image.jpg";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import CustomCommonTab from "../reusable/CustomCommonTab";
import CustomNavigationButtons from "../reusable/CustomNavigationWithEditDelete";
import CustomNavigation from "../reusable/CustomNavigation";
import { FaHome } from "react-icons/fa";
const EditInvoice = ({
  invoicenumberpay,
  statuspay,
  business_name,
  finalInvoiceAmount,
  dueDatepay
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { invoicenumber: paramInvoiceInvoice } = useParams();
  const invoicenumber = paramInvoiceInvoice || invoicenumberpay;
  const [expandedFuels, setExpandedFuels] = useState({});
  const [expandedTaxRow, setExpandedTaxRow] = useState(null);

  // New state variables for editing functionality
  const [editingRowId, setEditingRowId] = useState(null);
    const [isTermsEditable, setIsTermsEditable] = useState(false);
  const [editedTerms, setEditedTerms] = useState("");
  const [editedItems, setEditedItems] = useState([]);
  const [showSaveButton, setShowSaveButton] = useState(false);

  // Toggle quantity editing
  const toggleQuantityEditable = (rowId) => {
    // If clicking the same row, cancel editing
    if (editingRowId === rowId) {
      setEditingRowId(null);
    } else {
      // Otherwise, edit this row
      setEditingRowId(rowId);
      setShowSaveButton(true);
    }
  };
  useEffect(() => {
    // Only add listener if we're editing
    if (editingRowId) {
      const handleDocumentClick = (e) => {
        // Check if the click was on an edit button or input field
        const isEditButtonClick = e.target.closest('.edit-btn');
        const isInputClick = e.target.closest('.quantity-input');
        
        // If it's not a click on either the button or input, cancel editing
        if (!isEditButtonClick && !isInputClick) {
          setEditingRowId(null);
        }
      };
      
      document.addEventListener('mousedown', handleDocumentClick);
      return () => document.removeEventListener('mousedown', handleDocumentClick);
    }
  }, [editingRowId]);
  const termsTextareaRef = useRef(null);

  // Toggle terms editing
  const toggleTermsEditable = () => {
    setIsTermsEditable(!isTermsEditable);
    setShowSaveButton(true);

    // Use setTimeout to ensure the DOM has updated before attempting to focus
    setTimeout(() => {
      if (termsTextareaRef.current) {
        termsTextareaRef.current.focus();

        // Move cursor to end of text
        const textLength = termsTextareaRef.current.value.length;
        termsTextareaRef.current.setSelectionRange(textLength, textLength);
      }
    }, 50);
  };

  // Handle quantity changes
  const handleQuantityChange = (index, newValue) => {
    const updatedItems = [...editedItems];

    // Handle empty string or just a decimal point
    if (newValue === "" || newValue === ".") {
      updatedItems[index].item_quantity = newValue;
      updatedItems[index].unit_total = 0;
      setEditedItems(updatedItems);
      return;
    }

    // Allow valid number inputs including partial number entry
    if (/^(\d*\.?\d*)$/.test(newValue)) {
      updatedItems[index].item_quantity = newValue;

      // Only recalculate total if we have a complete number
      const numValue = parseFloat(newValue);
      if (!isNaN(numValue)) {
        const unitPrice = parseFloat(updatedItems[index].item_price);
        if (!isNaN(unitPrice)) {
          updatedItems[index].unit_total = numValue * unitPrice;
        }
      } else {
        // If it's not a complete number yet, set total to 0
        updatedItems[index].unit_total = 0;
      }

      setEditedItems(updatedItems);
    }
  };

  // Function to save changes
  const saveChanges = async () => {
    try {
      // Prepare data for API call
      const data = {
        businessId: localStorage.getItem("business_id"),
        invoiceNumber: invoicenumber,
        updatedItems: editedItems.filter((item) => !item.isCustomRow), // Only send non-custom items
        termsAndConditions: editedTerms
      };

      // Make API call to save changes
      const response = await axios.post(
        `${baseURL}/${btoa("invoice/updateInvoiceItems")}`,
        data
      );

      if (response.data.success) {
        // Update local state with saved changes
        setItems(editedItems);
        if (BillShip) {
          setBillShip({
            ...BillShip,
            terms_conditions: editedTerms
          });
        }

        // Reset edit modes
        // setIsQuantityEditable(false);
        setIsTermsEditable(false);
        setShowSaveButton(false);

        // Show success message
        dispatch(
          addToast({
            type: "success",
            message: "Invoice updated successfully!"
          })
        );

        // Refresh the data if needed
        fetchDataByInvoice();
      } else {
        throw new Error(response.data.message || "Failed to update invoice");
      }
    } catch (error) {
      console.error("Error saving invoice changes:", error);
      dispatch(
        addToast({
          type: "danger",
          message: "Failed to update invoice. Please try again."
        })
      );
    }
  };

  // 2. Replace the toggleTaxRow function with this one:
  const toggleTaxRow = (productId) => {
    // If the same row is clicked again, close it
    if (expandedTaxRow === productId) {
      setExpandedTaxRow(null);
    } else {
      // Otherwise, set the new expanded row (closes any previously opened row)
      setExpandedTaxRow(productId);
    }
  };

  const { state } = location;
  // useEffect(()=>{
  //   const status = state?.status; // Use optional chaining to avoid undefined errors

  // },[])
  // const businessName = state?.business_name ? state?.business_name : ''
  const final_invoice_amount = state?.final_invoice_amount
    ? state?.final_invoice_amount
    : 0 || finalInvoiceAmount;
  console.log(final_invoice_amount, "final_invoice_amount");
  const dueDateinvoice = state?.dueDate || dueDatepay;
  const customer_id = state?.customer_id;

  console.log(statuspay, "statusstatus");

  console.log(invoicenumber, localStorage.getItem("customerId"));

  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const [showDescription, setShowDescription] = useState(false);
  const [payModalInvoice, setpayModalInvoice] = useState(false);
  const handlePayModalInvoice = () => {
    setpayModalInvoice(true);
  };

  const [customerEmail, setCustomerEmail] = useState("");

  const [FinalInvoiceAmount, setFinalInvoiceAmount] = useState("");

  const [businessName, setBusinessName] = useState("");

  const [triggerFetch, setTriggerFetch] = useState(false);

  const [openSendInvoiceModalId, setOpenSendInvoiceModalId] = useState(null);
  const [activeFuelSection, setActiveFuelSection] = useState(null);

  const [quantityErrors, setQuantityErrors] = React.useState({});
  const [discount, setDiscount] = useState(0);
  const [waterBill, setwaterBill] = useState(0);
  const [Rebates, setRebates] = useState(0);
  const [freightsurcharge, setfreightsurcharge] = useState(0);
  const [freightCharges, setfreightCharges] = useState(0);
  const [brandInvoiceNumber, setBrandInvoiceNumber] = useState("");
  const [invoiceFuelData, setInvoiceFuelData] = useState([]);
  console.log(invoiceFuelData, "invoicefueldata");
  const [InvoiceCustomerData, setInvoiceCustomerData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(""); // Selected product
  const [selectedDiscountType, setSelectedDiscountType] = useState(""); // Type of discount (amount, %, gallons)
  const [isSubtotalExpanded, setSubtotalExpanded] = useState(false);
  const [isTaxesExpanded, setTaxesExpanded] = useState(false);

  const [productSubTotal, setProductSubTotal] = useState(0);
  const [CustomerData, setCustomerData] = useState([]);

  const [BillShip, setBillShip] = useState([]);

  const [CustomerInfo, setCustomerInfo] = useState([]);

  const toggleSubtotal = (e) => {
    e.preventDefault();
    setSubtotalExpanded(!isSubtotalExpanded);
  };
  const toggleTaxes = (e) => {
    e.preventDefault();
    setTaxesExpanded(!isTaxesExpanded);
  };
  // const handleDeleteInvoices = async () => {
  //   if (selectedRows.length > 0) {

  //   }
  // };
  const [isOpen, setIsOpen] = useState(false);

  const toggleInput = () => {
    setIsOpen((prev) => !prev);
  };
  const [status, setStatus] = useState("");

  const fetchDataByInvoice = async () => {
    const customerId = localStorage.getItem("customerId");

    try {
      const apiUrl = `${baseURL}/${btoa(
        "invoice/fetchinvoicebyinvooiocenumber"
      )}/${btoa(invoicenumber)}/${btoa(businessId)}/${btoa(customerId)}`;
      const response = await axios.get(apiUrl);
      console.log("invoice on on invoice number: ", response);
      setCustomerEmail(response.data.customerData[0].contact_email);

      setBusinessName(response.data.customerData[0].business_name);
      setFinalInvoiceAmount(response.data.customerData[0].final_invoice_amount);
      setStatus(response.data.customerData[0].invoice_status);
      appendCustomRows(response);
      setCustomerData(response.data.businessData[0]);
      setBillShip(response.data.customerData[0]);
      setTaxesView(response.data.customersTaxesAndValuesAndResult);
      setCustomerInfo(response.data.customerInfo[0]);
      setTermsAndConditions(response.data.customerInfo[0].terms_conditions);

      // Initialize the editedTerms state
      if (response.data.customerInfo[0].terms_conditions) {
        setEditedTerms(
          response.data.customerInfo[0].terms_conditions === "null"
            ? ""
            : response.data.customerInfo[0].terms_conditions
        );
      }
    } catch (error) {
      console.error("Error fetching invoice by invoice number", error);
    }
  };

  const appendCustomRows = (response) => {
    const invoiceItems = response.data.invoiceItemsData;

    // Start index after the existing items
    let currentIndex = invoiceItems.length + 1;

    const customRows = [
      {
        product_name: "Freight Charge",
        description: response.data.customerData[0]?.freight_charge_descr,
        item_price: response.data.customerData[0]?.freight_charge,
        unit_total: response.data.customerData[0]?.freight_charge,
        isCustomRow: true,
        isAdded: true
      },
      {
        product_name: "Freight Surcharge",
        description: response.data.customerData[0]?.freight_surcharge_descr,
        item_price: response.data.customerData[0]?.freight_surcharge,
        unit_total: response.data.customerData[0]?.freight_surcharge,
        isCustomRow: true,
        isAdded: true
      },
      {
        product_name: "Discount",
        description: response.data.customerData[0]?.discounts_type,
        item_quantity: response.data.customerData[0]?.discount_gallons,
        item_price:
          response.data.customerData[0]?.discount_price ||
          response.data.customerData[0]?.discounts_amount,
        unit_total: response.data.customerData[0]?.discounts_amount,
        isCustomRow: true,
        isAdded: false
      },
      {
        product_name: "Rebates/Credits",
        description: response.data.customerData[0]?.rebatescredits_description,
        item_price: response.data.customerData[0]?.rebatescredits_amount,
        unit_total: response.data.customerData[0]?.rebatescredits_amount,
        isCustomRow: true
      },
      {
        product_name: "Water Bill",
        description: response.data.customerData[0]?.waterbill_description,
        item_price: response.data.customerData[0]?.waterbill_amount,
        unit_total: response.data.customerData[0]?.waterbill_amount,
        isCustomRow: true,
        isAdded: true
      }
    ];

    // Filter and assign indices dynamically
    const validCustomRows = customRows
      .filter((row) => parseFloat(row.item_price) > 0) // Only include valid rows
      .map((row) => ({
        ...row,
        index: currentIndex++ // Assign index and increment
      }));
    console.log(validCustomRows, "validCustomRows");

    // Combine original invoice items and valid custom rows
    const updatedItems = [...invoiceItems, ...validCustomRows];

    // Update the state
    setItems(updatedItems);
  };

  const calculateInvoiceAmount = (subTotal, customRows, taxes) => {
    // Convert to numbers and ensure they're valid
    const fuelTotal = parseFloat(subTotal) || 0;

    // Calculate freight charges for each fuel product with quantity > 0
    const freightChargeTotal = items
      .filter((row) => !row.isCustomRow && parseFloat(row.item_quantity) > 0)
      .reduce((total, row) => {
        const productFreight =
          CustomerInfo.freigt_charge * parseFloat(row.item_quantity);
        return total + productFreight;
      }, 0);

    // Now that we're handling freight per product, we should exclude any existing freight charge custom row
    const customRowsTotal = items
      .filter((row) => row.isCustomRow && row.product_name !== "Freight Charge")
      .reduce((total, row) => {
        if (
          ["Discount", "Rebates/Credits"].includes(row.product_name?.trim())
        ) {
          return total - parseFloat(row.unit_total || 0);
        } else {
          return total + parseFloat(row.unit_total || 0);
        }
      }, 0);

    const taxTotal = parseFloat(taxes) || 0;

    // Calculate final amount
    const invoiceAmount =
      fuelTotal + freightChargeTotal + customRowsTotal + taxTotal;

    return invoiceAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const [boxDetails, setBoxDetails] = useState([]);
  useEffect(() => {
    const fetchDataByInvoice = async () => {
      const customerId = localStorage.getItem("customerId");

      try {
        const apiUrl = `${baseURL}/${btoa(
          "invoice/fetchinvoicebyinvooiocenumber"
        )}/${btoa(invoicenumber)}/${btoa(businessId)}/${btoa(customerId)}`;
        const response = await axios.get(apiUrl);
        console.log("invoice on on invoice number: ", response);
        setBoxDetails(response.data.customerData[0]);
        setCustomerEmail(response.data.customerData[0].contact_email);
        setFinalInvoiceAmount(
          response.data.customerData[0].final_invoice_amount
        );
        setBusinessName(response.data.customerData[0].business_name);
        setStatus(response.data.customerData[0].invoice_status);

        appendCustomRows(response);
        setCustomerData(response.data.businessData[0]);
        setBillShip(response.data.customerData[0]);
        setCustomerInfo(response.data.customerInfo[0]);

        // Initialize the editedTerms state
        if (response.data.customerInfo[0].terms_conditions) {
          setEditedTerms(
            response.data.customerInfo[0].terms_conditions === "null"
              ? ""
              : response.data.customerInfo[0].terms_conditions
          );
        }

        setTaxesView(response.data.customersTaxesAndValuesAndResult);
      } catch (error) {
        console.error("Error fetching invoice by invoice number", error);
      }
    };
    fetchDataByInvoice();
  }, []);

  const addRow = (product) => {
    setDiscountRebateError("");
    setSelectedDiscountType("");
    if (!product) return;

    // Check for conflicting conditions: Discount and Rebate
    const discountExists = InvoiceCustomerData.some((item) => item.isDiscount);
    const rebateExists = InvoiceCustomerData.some(
      (item) => item.product_name.toLowerCase() === "rebates/credits"
    );

    if (product.toLowerCase() === "discount" && rebateExists) {
      setDiscountRebateError(
        "Both discount and rebate cannot be added at the same time."
      );
      return; // Prevent adding the discount if rebate exists
    }

    if (product.toLowerCase() === "rebates/credits" && discountExists) {
      setDiscountRebateError(
        "Both discount and rebate cannot be added at the same time."
      );
      return; // Prevent adding the rebate if discount exists
    }

    let newRow;

    if (product.toLowerCase() === "miscellaneous") {
      const miscCount = InvoiceCustomerData.filter((item) =>
        item.product_name.toLowerCase().includes("miscellaneous")
      ).length;

      const miscProduct = `Miscellaneous${miscCount + 1}`;
      newRow = {
        product_id: InvoiceCustomerData.length + 1,
        product_name: miscProduct,
        quantity: "",
        unitPrice: "",
        total: 0.0,
        description: "",
        isCustomRow: true,
        isDiscount: product.toLowerCase() === "discount",
        isFreight:
          product.toLowerCase() === "freight charge" ||
          product.toLowerCase() === "freight surcharge",
        isAddedRow: true, // Mark the row as added
        displayPercentage:
          product.toLowerCase() === "discount"
            ? DiscountedPercentage
              ? `${DiscountedPercentage}`
              : ""
            : ""
      };

      const updatedProducts = remainingProducts.filter(
        (item) => item.toLowerCase() !== "miscellaneous" || miscCount < 4
      );
      setRemainingProducts(updatedProducts);
    } else {
      newRow = {
        product_id: InvoiceCustomerData.length + 1,
        product_name: product,
        quantity: "",
        unitPrice: "",
        total: 0.0,
        description: "",
        isCustomRow: true,
        isDiscount: product.toLowerCase() === "discount",
        isFreight:
          product.toLowerCase() === "freight charge" ||
          product.toLowerCase() === "freight surcharge",
        isAddedRow: true, // Mark the row as added
        displayPercentage:
          product.toLowerCase() === "discount"
            ? DiscountedPercentage
              ? `${DiscountedPercentage}`
              : ""
            : "" // Set displayPercentage for discount
      };

      const updatedProducts = remainingProducts.filter(
        (item) => item !== product
      );
      setRemainingProducts(updatedProducts);
    }

    // Add the new row to the invoice data
    setInvoiceCustomerData([...InvoiceCustomerData, newRow]);

    setSelectedProduct("");
    setShowDescription(true); // Show description column when a product is added
  };

  const handleDiscountTypeChange = (e, index) => {
    const newDiscountType = e.target.value;
    setSelectedDiscountType(newDiscountType);

    const updatedData = [...InvoiceCustomerData];
    if (newDiscountType === "") {
      updatedData[index].quantity = 0;
      updatedData[index].unitPrice = 0;
      updatedData[index].total = 0;
      setDiscount(0);
    } else {
      updatedData[index].quantity = 0;
      updatedData[index].unitPrice = 0;

      updatedData[index].total = 0;
      setDiscount(0);
    }
    updatedData[index].description = newDiscountType; // Set discount type in description
    setInvoiceCustomerData(updatedData);
  };

  const [loading, setLoading] = useState(true);

  const [TaxesView, setTaxesView] = useState([]);

  const [items, setItems] = useState([
    {
      productId: "",
      itemId: "",
      item: "",
      description: "",
      quantity: "",
      unitPrice: "",
      total: 0
    }
  ]);
  console.log(items, "items");
  const [DiscountRebateError, setDiscountRebateError] = useState("");

  const [latestPurchaseInvoices, setLatestPurchaseInvoices] = useState(null);

  const [customerIdInvoice, setCustomerIdInvoice] = useState("");

  const today = new Date().toISOString().split("T")[0];
  const [sellerNames, setSellerNames] = useState([]);

  const [tax, setTax] = useState("");
  const [feesOrDiscounts, setFeesOrDiscounts] = useState("");

  const [termsAndConditions, setTermsAndConditions] = useState("");

  const [latestInvoiceNumber, setLatestInvoiceNumber] = useState("");

  const [dueDate, setDueDate] = useState("");

  const [createdDate, setCreatedOnDate] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(dayjs());

  const businessId = localStorage.getItem("business_id");

  const [customerOptions, setCustomerOptions] = useState([]);
  const [taxTotal, setTaxTotal] = useState("");

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("View");

  const [products, setProducts] = useState([
    {
      product: "",
      productCode: "",
      quantity: "",
      unitPrice: "",
      total: 0,
      editableDescription: false
    }
  ]);

  // console.log("products", products);

  const [formField, setFormField] = useState({
    invoice_number: "",
    bill_from: "",
    bill_to: "",
    vehicleNumber: "",
    sellersId: "",
    originTerminal: "",
    destinationTerminal: "",
    created_on: today,
    due_on: today,
    invoiceDate: today
  });

  const existingRows = InvoiceCustomerData.filter((row) => !row.isAddedRow);
  const totalQuantity = items.reduce((acc, product) => {
    if (product.product_name !== "" && product.item_quantity !== 0) {
      return acc + parseFloat(product.item_quantity || 0);
    }
    return acc; // If the condition is not met, return the current accumulator
  }, 0); // Initial accumulator value
  console.log(totalQuantity, InvoiceCustomerData, items, "total quantity");
  const subTotal = items
    .filter((row) => !row.isCustomRow)
    .reduce((acc, product) => acc + parseFloat(product.unit_total || 0), 0);
  //  console.log(subTotal, 'subtotal checking')
  const grandTotal = parseFloat(taxTotal) + parseFloat(productSubTotal);
  const [customerIndividualData, setCustomerIndividualData] =
    useState(grandTotal);
  const handleChange = (event) => {
    setfreightCharges(0);
    setfreightsurcharge(0);
    setDiscount(0);
    setwaterBill(0);
    setRebates(0);
    setLatestPurchaseInvoices(null);

    setRemainingProducts(availableProducts);
    setCustomerIdInvoice(event.target.value);
    console.log(event.target.value);
    if (event instanceof Date) {
      // setCreatedOnDate(event);
    } else {
      setInvoiceFuelData([]);

      const { name, value } = event.target;
      setFormField({
        ...formField,
        [name]: value
      });
    }
  };

  const handleDeliveryDateChange = (date) => {
    console.log(date, "date....");
    setCreatedOnDate(date);
    setFormField({
      ...formField,
      created_on: date
    });
  };

  const business_id = localStorage.getItem("business_id");
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");

  const formatDate1 = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };

  const customerId = customerIdInvoice;
  const [TaxDataForInvoice, setTaxDataForInvoice] = useState([]);

  const [DiscountedPercentage, setDiscountedPercentage] = useState("");

  const [businessData, setBusinessData] = useState([]);
  console.log("TaxNamesForInvoice", TaxDataForInvoice);
  const taxTotal1 = (() => {
    // Calculate the sum of all fuel type taxes
    let totalTaxAmount = 0;

    // Check if items and TaxesView exist
    if (
      !items ||
      !Array.isArray(items) ||
      !TaxesView ||
      !Array.isArray(TaxesView)
    ) {
      console.error("Missing items or TaxesView array");
      return 0;
    }

    // Process each fuel type
    items
      .filter((item) => item && !item.isCustomRow)
      .forEach((fuelItem) => {
        // Ensure fuelItem and fuelItem.product_name exist
        if (!fuelItem || !fuelItem.product_name) {
          console.warn("Invalid fuel item", fuelItem);
          return; // Skip this iteration
        }

        const fuelType = fuelItem.product_name.toLowerCase();
        const fuelQuantity = parseFloat(fuelItem.item_quantity) || 0;

        if (fuelQuantity > 0) {
          // Get applicable taxes for this fuel type with safety checks
          const fuelTaxes = TaxesView.filter((tax) => {
            // Skip invalid tax objects
            if (!tax || typeof tax !== "object" || !tax.po_customertax_name) {
              console.warn("Invalid tax item", tax);
              return false;
            }

            const taxName = tax.po_customertax_name.toLowerCase();

            if (taxName.includes("diesel") && fuelType === "diesel") {
              return true;
            }

            if (!taxName.includes("diesel") && fuelType !== "diesel") {
              return true;
            }

            return false;
          });

          // Add this fuel's tax total to the grand total
          fuelTaxes.forEach((tax) => {
            if (
              tax &&
              tax.po_customertax_name &&
              tax[tax.po_customertax_name]
            ) {
              totalTaxAmount += tax[tax.po_customertax_name] * fuelQuantity;
            } else {
              console.warn("Invalid tax calculation", tax);
            }
          });
        }
      });

    console.log("Calculated tax total:", totalTaxAmount);
    return totalTaxAmount; // Return the numeric value
  })();
  console.log(taxTotal1, "taxTotal1taxTotal1taxTotal1taxTotal1");
  const [MinLengthError, setMinLengthError] = useState("");
  const handleDelete = (productId, e) => {
    setSelectedProduct("");

    e.preventDefault();

    // Ensure that we don't delete the last item
    if (InvoiceCustomerData.length === 1) {
      setMinLengthError("You cannot delete the last product.");
      return; // Exit the function to prevent deletion
    }

    // Find the product to be deleted
    const deletedProduct = InvoiceCustomerData.find(
      (row) => row.product_name === productId
    );

    // If the product to delete exists, proceed
    if (deletedProduct) {
      const updatedData = InvoiceCustomerData.filter(
        (row) => row.product_name !== productId
      );

      // Set the updated data back to the state
      setInvoiceCustomerData(updatedData);

      // Recalculate product subTotal after deletion

      const productSubTotal = updatedData
        .filter((row) => row.product_name !== "Discount") // Exclude "Discount" from the subtotal
        .reduce(
          (acc, row) => acc + (isNaN(row.total) ? 0 : Number(row.total)),
          0
        );
      setProductSubTotal(productSubTotal);

      // Update remaining products for the dropdown (only if the deleted product was custom row)
      if (deletedProduct?.isCustomRow) {
        setRemainingProducts((prev) => {
          let updatedProducts = [...prev];

          // Handle "Miscellaneous" logic
          if (
            deletedProduct.product_name.startsWith("Miscellaneous") &&
            !prev.some((product) => product === "Miscellaneous")
          ) {
            // Add "Miscellaneous" back to the dropdown only if no "Miscellaneous" is present
            updatedProducts.push("Miscellaneous");
          } else if (!deletedProduct.product_name.startsWith("Miscellaneous")) {
            // Add non-Miscellaneous custom rows back to the dropdown
            updatedProducts.push(deletedProduct.product_name);
          }

          return updatedProducts.sort(
            (a, b) =>
              availableProducts.indexOf(a) - availableProducts.indexOf(b)
          );
        });
      }

      // Check if there are any rows with "isCustomRow" and update visibility
      const hasCustomRows = updatedData.some((row) => row.isCustomRow);
      setShowDescription(hasCustomRows);

      // If the deleted product was a special one, reset the corresponding value
      if (deletedProduct?.product_name === "Discount") {
        setDiscount(0);
      }
      if (deletedProduct?.product_name === "Freight Charge") {
        setfreightCharges(0);
      }
      if (deletedProduct?.product_name === "Freight Surcharge") {
        setfreightsurcharge(0);
      }
      if (deletedProduct?.product_name === "Water Bill") {
        setwaterBill(0);
      }
      if (deletedProduct?.product_name === "Rebates/Credits") {
        setRebates(0);
      }
    }
  };

  const formatNumberWithCommas = (value) => {
    if (!value || isNaN(value)) return ""; // Handle invalid or empty values
    return parseFloat(value).toLocaleString("en-US");
  };

  const disablefutureDates = (current) => {
    return current && current > dayjs().startOf("day");
  };

  const availableProducts = [
    "Freight Charge",
    "Freight Surcharge",
    "Water Bill",
    "Discount",
    "Rebates/Credits",
    "Miscellaneous"
  ];

  const [remainingProducts, setRemainingProducts] = useState(availableProducts);

  const [activeItem, setActiveItem] = useState("View");

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };
  const userRole = localStorage.getItem("user_role");
  const managerId = localStorage.getItem("user_id");
  function replaceBeforeHyphen(input) {
    return input.replace(/^.*?(?=-)/, "");
  }

  const handleApproveInvoice = async (e) => {
    e.preventDefault();
    const invoiceNumbers = invoicenumber;
    // console.log(invoiceNumbers)
    try {
      const response = await axios.get(
        `${baseURL}${btoa("invoice/approveinvoice")}/${btoa(
          invoiceNumbers
        )}/${btoa(managerId)}/${btoa(businessId)}/${btoa(userRole)}`
      );
      if (response.data)
        // showToastForUpdateInvoice(`${businessName} Approved successfully` , "success")
        dispatch(
          addToast({
            type: "success",
            message: `${businessName} Approved successfully`
          })
        );

      // console.log("approved status :", response.data);
      // table.resetRowSelection();
      // setTriggerFetch(!triggerFetch); // Toggle the triggerFetch state
      navigate("/invoice");
    } catch (error) {
      console.log("error details ", error);
    }
  };
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);

  const handleDeleteModalInvoice = (invoicenumber) => {
    // alert(selectedRows.invoice_number)
    // console.log("sel" , selectedRows);

    setOpenDeleteModalId(invoicenumber);
  };
  const navigationPath = ["4", "7", "8"].includes(status)
    ? "/payins"
    : "/invoice";

  // const tabs = [
  // { key: "View", label: "View", component: <InvoiceView /> }, // No separate component—InvoiceView itself is shown
  // ...(status === "1"
  // ? [
  // {
  // key: "Edit",
  // label: "Edit",
  // component: <EditInvoice invoicenumber={invoicenumber} />,
  // },
  // // { key: "Delete", label: "Delete", onClick: () => handleDeleteModalInvoice(invoicenumber) }, // Calls function on click
  // {
  // key: "Delete",
  // label: "Delete",
  // onClick: () => handleDeleteModalInvoice(invoicenumber),
  // },
  // ]
  // : []),
  // ...(status === "2"
  // ? [
  // // { key: "Edit", label: "Edit", component: <EditInvoice  invoicenumber={invoicenumber} /> },
  // // { key: "Delete", label: "Delete", onClick: () => handleDeleteModalInvoice(invoicenumber) }, // Calls function on click
  // {
  // key: "Delete",
  // label: "Delete",
  // onClick: () => handleDeleteModalInvoice(invoicenumber),
  // }, // Calls function on click
  // ]
  // : []),

  // { key: "Cancel", label: "Cancel", navigationPath: navigationPath },
  // ];
  function formatDate(date) {
    const day = date.getDate() + 1;
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear().toString(); // Extract last two digits of year
    return `${month}-${day}-${year}`;
  }
  const handleDownloadInvoice = async () => {
    const invoiceNumbers = invoicenumber;
    // console.log("selected rrr" , selectedRows);
    try {
      const response = await axios.get(
        `${baseURL}/${btoa("invoice/downloadinvoice")}/${btoa(
          invoiceNumbers
        )}/${btoa(business_id)}/${btoa(customer_id)}/${btoa(managerId)}/${btoa(
          userRole
        )}`,
        {
          responseType: "blob"
        }
      );
      const filename = `${businessName}_${invoicenumber}_${formatDate(
        new Date()
      )}.pdf`;
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename; // Set the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // table.resetRowSelection();
      window.URL.revokeObjectURL(url);

      dispatch(
        addToast({
          type: "success",
          message: `Invoice for ${businessName} downloaded successfully`
        })
      );

      // console.log("Invoice downloaded successfully.");
    } catch (error) {
      console.log("Error downloading invoice:", error);
    }
  };
  const calculateTaxTotals = () => {
    // Prepare result object for different tax types
    const result = {
      pptTaxes: 0,
      otherTaxes: 0,
      freightCharges: 0
    };

    // Check if necessary data exists
    if (
      !items ||
      !Array.isArray(items) ||
      !TaxesView ||
      !Array.isArray(TaxesView)
    ) {
      return result;
    }

    // Process each fuel type
    items
      .filter((item) => item && !item.isCustomRow)
      .forEach((fuelItem) => {
        // Safety check for item
        if (!fuelItem || !fuelItem.product_name) {
          return;
        }

        const fuelType = fuelItem.product_name.toLowerCase();
        const fuelQuantity = parseFloat(fuelItem.item_quantity) || 0;

        if (fuelQuantity > 0) {
          // Calculate freight charge for this product
          if (CustomerInfo.freigt_charge > 0) {
            result.freightCharges += CustomerInfo.freigt_charge * fuelQuantity;
          }

          // Get applicable taxes for this fuel type
          TaxesView.forEach((tax) => {
            // Skip invalid tax objects
            if (!tax || typeof tax !== "object" || !tax.po_customertax_name) {
              return;
            }

            const taxName = tax.po_customertax_name.toLowerCase();
            const taxCustomerName = tax.customer_taxes;

            // Check if it's applicable to this fuel type
            let isApplicable = false;

            if (taxName.includes("diesel") && fuelType === "diesel") {
              isApplicable = true;
            } else if (!taxName.includes("diesel") && fuelType !== "diesel") {
              isApplicable = true;
            }

            if (isApplicable) {
              const taxAmount = tax[tax.po_customertax_name] * fuelQuantity;

              // Separate PPT from other taxes
              if (taxCustomerName === "Pre Paid Tax") {
                result.pptTaxes += taxAmount;
              } else {
                result.otherTaxes += taxAmount;
              }
            }
          });
        }
      });

    return result;
  };

  // In your component, display PPT and other taxes separately:
  const taxTotals = calculateTaxTotals();
  // Initialize editedItems when items are updated
  useEffect(() => {
    if (items && items.length > 0) {
      setEditedItems([...items]);
    }
  }, [items]);
  const handletheEditInvoice = (invoiceNumbers, customeridforInvoice) => {
    navigate(`/editInvoice/${invoiceNumbers}`);
    // fetchDataByInvoice(customeridforInvoice)
    localStorage.setItem("customerId", customeridforInvoice);
  };
  const nonPrepaidTaxesTotal = TaxesView.filter(
    (item) => item.customer_taxes !== "Pre Paid Tax"
  ).reduce(
    (sum, item) => sum + Number(item[item.po_customertax_name] * totalQuantity),
    0
  );

  const menuItems = [{ name: "Edit Invoice" }];

  const [isMemoOpen, setIsMemoOpen] = useState(false);
  const [currentMemo, setCurrentMemo] = useState("");
  const [memos, setMemos] = useState([]);
  const memoTextareaRef = useRef(null);
  const toggleMemoInput = useCallback(() => {
    setIsMemoOpen((prev) => !prev);
  }, []);
  useEffect(() => {
    if (isMemoOpen && memoTextareaRef.current) {
      memoTextareaRef.current.focus();
      memoTextareaRef.current.setSelectionRange(
        memoTextareaRef.current.value.length,
        memoTextareaRef.current.value.length
      );
    }
  }, [isMemoOpen]);
  const formatMemoDate = (dateString) => {
    return dayjs(dateString).format("MM/DD/YYYY hh:mm A");
  };

  // Loads dummy memo data when the component mounts
  useEffect(() => {
    // This would be replaced with an API call in production
    setMemos([
      {
        id: 1,
        text: "Customer requested extension on payment due to cash flow issues. Extended due date by 14 days.",
        created_by: "John Smith",
        created_on: "2025-03-20T10:30:00",
        edited_on: null
      },
      {
        id: 2,
        text: "Customer made partial payment of $1,250.00. Balance remaining: $3,750.00",
        created_by: "Jane Doe",
        created_on: "2025-03-22T14:15:00",
        edited_on: null
      },
      {
        id: 3,
        text: "Discussed remaining balance with customer. They will pay the remaining amount by the end of next week.",
        created_by: "John Smith",
        created_on: "2025-03-24T09:45:00",
        edited_on: "2025-03-24T11:20:00"
      }
    ]);
  }, []);
  const handleSaveMemo = () => {
    if (currentMemo.trim() === "") return;

    // Create a new memo object
    const newMemo = {
      id: memos.length + 1, // In real app, this would be generated by the server
      text: currentMemo,
      created_by: localStorage.getItem("user_id") || "Current User",
      created_on: new Date().toISOString(),
      edited_on: null
    };

    // Add the new memo to the beginning of the array (latest first)
    setMemos([newMemo, ...memos]);
    setCurrentMemo(""); // Clear the input after saving

    // In a real application, you would save to the server here
    /* API call would go here */
  };

  return (
    <>
      {/* {loading && <FullScreenLoader/>} */}
      <ToastNotificationBox />
      {location.pathname.includes("invoiceedit") && (
        <div className="flex w-full px-2 ">
          <div className="flex-1 flex justify-start items-center ">
            {/* <nav aria-label="breadcrumb">
  <ol className="flex space-x-2 text-md font-bold text-gray-700 mb-6">
    <li>Invoice</li>
    <li>
      <span className="text-gray-400">{">>"}</span>
    </li>
    <li>View Invoice</li>
  </ol>
</nav> */}
          </div>

          <div className="flex-1 flex justify-end"></div>
        </div>
      )}
      {location.pathname.includes("PayInvoice") && (
        <div className="mt-[40px]"></div>
      )}

      <CustomCommonTab items={menuItems} />
      <div
        className="relative w-[62vw]  border mx-auto mb-5 bg-white "
        style={{
          boxShadow: "0 0 20px 0 rgba(0, 0, 0,.3)"
        }}
      >
        <div className="absolute -top-4 right-20 flex items-center space-x-1 z-10 -mr-[8px] ">
          {/* 
<CustomNavigationButtons type="download" onClick={()=> handleDownloadInvoice()}/>

{(status === "1" || status === "2") &&
<CustomNavigationButtons type="delete" onClick={() => handleDeleteModalInvoice(invoicenumber)} />
} */}
        </div>
        <div className="ml-2">
          <div className="flex items-center justify-end">
            {/* Home button - bordered/unfilled by default, filled blue on hover */}
            <div
              className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-8 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => {
                navigate("/");
              }}
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
            >
              <FaHome
                className="text-[#25caed] group-hover:text-white transition-colors duration-200"
                size={16}
              />
            </div>

            {/* Close Icon - bordered/unfilled by default, filled red on hover */}
            <div
              className="absolute -top-4 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => navigate(`/invoiceview/${invoicenumber}`)}
            >
              <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">
                &times;
              </span>
            </div>
          </div>
        </div>

        <div className="absolute top-[400px] left-1/2 -translate-x-1/2 -translate-y-1/2 z-0">
          <p className="text-red-400 opacity-20 font-bold text-[5rem] rotate-[-45deg] select-none">
            {(status === "1" || statuspay === "1") && "DRAFT"}
            {(status === "2" || statuspay === "2") && "APPROVED"}
            {(status === "3" || statuspay === "3") && "SENT TO CUSTOMER"}
            {/* {(status  === "7" || statuspay === "7") && "FULLPAID"} */}
            {(status === "8" || statuspay === "8") && "PAST DUE"}
            {(status === "4" || statuspay === "4") && "PARTIALPAY"}
          </p>
        </div>
        {/* <TabMenu tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} /> */}

        {activeTab === "View" && (
          <form className="">
            <div className="flex justify-between items-center  px-[50px] py-14 pt-24 ">
              {/* <div className='mt-[100px]' >
  <h1 className="font-bold text-xl">{CustomerData.business_name}</h1>
  <p className="">{CustomerData.business_address} {CustomerData.business_city}</p>
  <p className="">{CustomerData.business_state}{CustomerData.business_zip_code}</p>
</div> */}
              <div className="text-left">
                <h2
                  className={`${
                    (status === "1" || statuspay === "1") && "text-blue-600 "
                  }
   ${(status === "2" || statuspay === "2") && "text-green-600 "}
   ${(status === "3" || statuspay === "3") && "text-purple-600 "}
    font-bold text-4xl mb-2 text-left`}
                >
                  INVOICE
                </h2>
                <p
                  className={` ${
                    (status === "1" || statuspay === "1") && "text-blue-600 "
                  }
   ${(status === "2" || statuspay === "2") && "text-green-600 "}
   ${
     (status === "3" || statuspay === "3") && "text-purple-600 "
   } text-2xl font-bold text-left`}
                >
                  {invoicenumber}
                </p>
              </div>
              {status !== "7" && status && (
                <div className={`text-right ${status === "7" ? "hidden" : ""}`}>
                  <h2 className="text-orange-600 font-bold text-4xl mb-2 text-left">
                    AMT DUE
                  </h2>
                  <p className="text-orange-600 text-3xl font-bold text-right">
                    {status !== "4" &&
                      statuspay !== "4" &&
                      `$ ${calculateInvoiceAmount(subTotal, [], taxTotal1)}`}

                    {status === "4" || statuspay === "4"
                      ? `$ ${Number(BillShip?.due_amount || 0).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        )}`
                      : ""}
                  </p>
                </div>
              )}

              {status === "7" && (
                <div className="text-right">
                  <img
                    style={{ width: "200px", height: "120px" }}
                    src={fullpay}
                    alt="InvoiceFile"
                    className={`${"w-max"}`}
                  />
                </div>
              )}
            </div>
            <div>
              {(status === "1" || statuspay === "1") && (
                <div className="flex justify-between items-between mb-4 mr-[60px]">
                  <div>
                    {CustomerInfo.brand_logo && (
                      <div className="flex items-center justify-center pl-10 my-auto">
                        <img
                          src={`${baseURL}/img/${CustomerInfo.brand_logo}`}
                          alt="Business Logo"
                          className="w-20 h-15 rounded-full object-contain" // Ensures the image scales proportionally
                        />
                      </div>
                    )}
                  </div>
                  <button
                    className="w-[150px] h-[35px] px-2 px-12 py-1 border-2 border-blue-600 text-blue-600 hover:font-bold rounded-md hover:bg-blue-600 hover:shadow-blue-700/50 uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90 "
                    // className="mb-4 px-6 py-2 text-green-600 font-bold text-lg border-2 border-green-600 bg-green-100 hover:bg-green-200 rounded-md"
                    onClick={(e) => handleApproveInvoice(e)}
                  >
                    APPROVE
                  </button>
                </div>
              )}
              {(status === "2" || statuspay === "2") && (
                <div className="flex justify-between items-between mb-4 mr-[60px]">
                  <div>
                    {CustomerInfo.brand_logo && (
                      <div className="flex items-center justify-center pl-24 my-auto">
                        <img
                          src={`${baseURL}/img/${CustomerInfo.brand_logo}`}
                          alt="Business Logo"
                          className="w-20 h-15 rounded-full object-contain" // Ensures the image scales proportionally
                        />
                      </div>
                    )}
                  </div>

                  <button
                    className="w-[150px] px-2 px-12 py-1 border-2 border-green-600 text-green-600 hover:font-bold rounded-md hover:bg-green-600 hover:shadow-green-700/50 uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90 "
                    // className="mb-4 px-6 py-2 text-green-600 font-bold text-lg border-2 border-green-600 bg-green-100 hover:bg-green-200 rounded-md"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchDataByInvoice();
                      const invoiceNumber = invoicenumber;
                      setOpenSendInvoiceModalId(invoiceNumber);
                    }}
                  >
                    SEND
                  </button>
                </div>
              )}
              {(status === "3" || statuspay === "3") &&
                location.pathname.includes("invoiceview") && (
                  <div className="flex justify-between items-between mb-4 mr-[50px]">
                    <div>
                      {CustomerInfo.brand_logo && (
                        <div className="flex items-center justify-center pr-2 my-auto">
                          <img
                            src={`${baseURL}/img/${CustomerInfo.brand_logo}`}
                            alt="Business Logo"
                            className="w-20 h-15 rounded-full object-contain" // Ensures the image scales proportionally
                          />
                        </div>
                      )}
                    </div>

                    <button
                      className="w-[150px] px-2 px-12 py-1 border-2 border-purple-600 text-purple-600  hover:font-bold rounded-md hover:bg-purple-600 hover:shadow-purple-700/50 uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90 "
                      // className="mb-4 px-6 py-2 text-green-600 font-bold text-lg border-2 border-green-600 bg-green-100 hover:bg-green-200 rounded-md"
                      onClick={(e) => {
                        e.preventDefault();
                        fetchDataByInvoice();
                        //   handlePayModalInvoice()
                        navigate(`/PayInvoice/${invoicenumber}`, {
                          state: {
                            businessName: businessName,
                            FinalInvoiceAmount: FinalInvoiceAmount,
                            invoicenumber: invoicenumber,
                            triggerFetch: triggerFetch
                            // setTriggerFetch:setTriggerFetch,
                          }
                        });
                      }}
                    >
                      PAY
                    </button>
                  </div>
                )}
              {/* {(status === "8" || statuspay === "8") && location.pathname.includes('invoiceview') &&
<div className="flex justify-end items-end mb-4 mr-[50px]">
<button
className='w-[150px] px-2 px-12 py-1 border-2 border-purple-600 text-purple-600  hover:font-bold rounded-md hover:bg-purple-600 hover:shadow-purple-700/50 uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90 '

// className="mb-4 px-6 py-2 text-green-600 font-bold text-lg border-2 border-green-600 bg-green-100 hover:bg-green-200 rounded-md"
onClick={(e)=> {
e.preventDefault()
fetchDataByInvoice()
//   handlePayModalInvoice()
navigate(`/PayInvoice/${invoicenumber}`, {
state: {
businessName: businessName, 
FinalInvoiceAmount: FinalInvoiceAmount, 
invoicenumber: invoicenumber,
triggerFetch:triggerFetch,
// setTriggerFetch:setTriggerFetch,
},
});
}}
>
PAY
</button>
</div>
} */}
            </div>
            <div className="flex flex-wrap gap-6 mt-[60px] px-[40px]">
              {/* Bill To */}
              <div className="flex-1 p-2 rounded-md">
                <p
                  className={`font-bold text-xl uppercase ${
                    (status === "1" || statuspay === "1") && "text-blue-600"
                  }
${(status === "2" || statuspay === "2") && "text-green-600"} ${
                    (status === "3" || statuspay === "3") && "text-purple-600"
                  }`}
                >
                  Bill To
                </p>
                <p>{BillShip.business_name || business_name}</p>
                <p>{CustomerInfo.billing_address}</p>
                <p>
                  {CustomerInfo.billing_city} {CustomerInfo.billing_state}{" "}
                  {CustomerInfo.billing_zip_code}
                </p>
                <p className=" ">{CustomerInfo.contact_name}</p>
                <p className=" ">{CustomerInfo.contact_email}</p>
                <p className=" ">{CustomerInfo.contact_no}</p>
              </div>

              {/* Ship To */}
              <div className="flex-1 p-2 rounded-md">
                <p
                  className={`font-bold text-xl uppercase   ${
                    (status === "1" || statuspay === "1") && "text-blue-600"
                  } 
${(status === "2" || statuspay === "2") && "text-green-600"} ${
                    (status === "3" || statuspay === "3") && "text-purple-600"
                  }`}
                >
                  Ship To
                </p>
                {/* <p>{CustomerInfo.shipping_to}</p> */}
                <p>{CustomerInfo.business_name}</p>

                <p>{CustomerInfo.shipping_address}</p>
                <p>
                  {CustomerInfo.shipping_city} {CustomerInfo.shipping_state}{" "}
                  {CustomerInfo.shipping_zip_code}
                </p>
              </div>
              {/* <div>
      {CustomerInfo.brand_logo && (
        <div className="flex items-center justify-center pr-24 my-auto">
          <img
            src={`${baseURL}/img/${CustomerInfo.brand_logo}`}
            alt="Business Logo"
            className="w-20 h-20 rounded-full object-contain" // Ensures the image scales proportionally
          />
        </div>
      )}
    </div> */}

              {/* Invoice Details */}
              <div className=" p-2 rounded-md">
                <div className="flex justify-between items-center mb-1">
                  <span
                    className={`font-bold text-[14px] uppercase   ${
                      (status === "1" || statuspay === "1") && "text-blue-600"
                    } 
${(status === "2" || statuspay === "2") && "text-green-600"} ${
                      (status === "3" || statuspay === "3") && "text-purple-600"
                    }`}
                  >
                    Account #
                  </span>
                  <span className="text-gray-700 text-[14px] ml-[10px]">
                    {BillShip.business_account}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-1">
                  <h3
                    className={`font-bold text-[14px] uppercase   ${
                      (status === "1" || statuspay === "1") && "text-blue-600"
                    }
${(status === "2" || statuspay === "2") && "text-green-600"} ${
                      (status === "3" || statuspay === "3") && "text-purple-600"
                    }`}
                  >
                    Delivery Date
                  </h3>
                  {/* <p className="text-gray-700 ml-[10px] text-[14px]">{BillShip.delivery_date}</p> */}
                  <p className="text-gray-700 ml-[10px] text-[14px]">
                    {(() => {
                      const date = new Date(BillShip.delivery_date);
                      const month = (date.getMonth() + 1)
                        .toString()
                        .padStart(2, "0"); // Months are 0-11, so add 1 and pad
                      const day = (date.getDate() + 1)
                        .toString()
                        .padStart(2, "0"); // Pad single-digit days with a leading zero
                      const year = date.getFullYear();
                      return `${month}-${day}-${year}`;
                    })()}
                  </p>
                </div>
                <div className="flex justify-between items-center mb-1">
                  <h3
                    className={`font-bold text-[14px] uppercase text-[14px]  ${
                      (status === "1" || statuspay === "1") && "text-blue-600"
                    }
${(status === "2" || statuspay === "2") && "text-green-600"} ${
                      (status === "3" || statuspay === "3") && "text-purple-600"
                    }`}
                  >
                    Invoice Date
                  </h3>
                  {/* <p className="text-gray-700 ml-[10px] text-[14px]">{BillShip.added_on}</p> */}
                  <p className="text-gray-700 ml-[10px] text-[14px]">
                    {(() => {
                      const date = new Date(BillShip.added_on);
                      const month = (date.getMonth() + 1)
                        .toString()
                        .padStart(2, "0"); // Months are 0-11, so add 1 and pad
                      const day = (date.getDate() + 1)
                        .toString()
                        .padStart(2, "0"); // Pad single-digit days with a leading zero
                      const year = date.getFullYear();
                      return `${month}-${day}-${year}`;
                    })()}
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <h3
                    className={`text-[14px] font-bold  uppercase  ${
                      (status === "1" || statuspay === "1") && "text-blue-600"
                    } 
${(status === "2" || statuspay === "2") && "text-green-600"} ${
                      (status === "3" || statuspay === "3") && "text-purple-600"
                    }`}
                  >
                    Due Date
                  </h3>
                  {/* <p className="text-gray-700 ml-[10px] text-[14px]">{BillShip.due_date}</p> */}
                  <p className="text-gray-700 ml-[10px] text-[14px]">
                    {(() => {
                      const date = new Date(BillShip.due_date);
                      const month = (date.getMonth() + 1)
                        .toString()
                        .padStart(2, "0"); // Months are 0-11, so add 1 and pad
                      const day = (date.getDate() + 1)
                        .toString()
                        .padStart(2, "0"); // Pad single-digit days with a leading zero
                      const year = date.getFullYear();
                      return `${month}-${day}-${year}`;
                    })()}
                  </p>
                </div>
              </div>
            </div>
            <table
              className="table-auto border-collapse border w-[92%]  mt-[15px] mx-auto"
              style={{ fontSize: "14px", tableLayout: "auto" }}
            >
              <thead>
                <tr>
                  <th
                    colSpan="100%"
                    className="border-b-2 border-black bg-white h-0 p-0 m-0"
                  ></th>
                </tr>
                <tr className="bg-white border-b-[2px] border-black">
                  <th className="px-4 py-2 text-center w-[5%]">#</th>
                  <th className="px-4 py-2 text-left uppercase w-[30%]">
                    Product
                  </th>
                  {showDescription ? (
                    <th className=" py-2 text-left uppercase w-[20%]">
                      Description
                    </th>
                  ) : (
                    <th className="py-2 text-left uppercase w-[20%]">
                      Description
                    </th>
                    // <th className="px-4 py-2 text-center w-[20%]"></th>
                  )}
                  <th className="text-right uppercase w-[10%]">Quantity</th>
                  <th
                    className="text-right uppercase w-[15%]"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Unit Price
                  </th>
                  <th className="px-4 text-right uppercase w-[15%]">Amount</th>
                </tr>
                <tr>
                  <th
                    colSpan="100%"
                    className="border-t-2 border-black bg-white h-0 p-0 m-0"
                  ></th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "16px", fontFamily: "Sans-Serif" }}>
                {items.map((row, index) => {
                  if (
                    row.item_price <= 0 ||
                    row.product_name === "Freight Charge"
                  )
                    return null;

                  // Check if this row can have taxes (not a custom row)
                  const canHaveTaxes = !row.isCustomRow;

                  // Get applicable taxes for this product
                  const productTaxes = canHaveTaxes
                    ? TaxesView.filter((tax) => {
                        if (!tax || !tax.po_customertax_name) return false;
                        const taxName = tax.po_customertax_name.toLowerCase();
                        const fuelType = (row.product_name || "").toLowerCase();

                        // Match diesel taxes with diesel fuel
                        if (
                          taxName.includes("diesel") &&
                          fuelType === "diesel"
                        ) {
                          return true;
                        }

                        // Match gasoline taxes with non-diesel fuels
                        if (
                          !taxName.includes("diesel") &&
                          fuelType !== "diesel"
                        ) {
                          return true;
                        }

                        return false;
                      })
                    : [];

                  // Determine if taxes should be shown for this product
                  const showTaxes =
                    canHaveTaxes &&
                    parseFloat(row.item_quantity) > 0 &&
                    expandedTaxRow === row.product_id;
                  return (
                    <React.Fragment key={index}>
                      <tr key={index} className="border-t">
                        <td className="px-4 py-2 text-center">{index + 1}</td>
                        <td className="text-left px-4 py-2">
                          <div className="flex items-center">
                            {/* Expansion button for products with taxes */}
                            {productTaxes.length > 0 && (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  toggleTaxRow(row.product_id);
                                }}
                                className="mr-2 text-blue-500 focus:outline-none"
                              >
                                {expandedTaxRow === row.product_id ? "▼" : "►"}
                              </button>
                            )}
                            {row.product_name}
                          </div>
                        </td>
                        {/* <td className="px-2 py-2 text-left" colSpan={row.description && row.isCustomRow || (row.product_name === "Water Bill" || 
    row.product_name === "Freight Charges"  || row.product_name === "Freight Surcharge"
    || row.product_name === "Rebates/Credits"
     ) ? 2 : 1}>
    {row.description || ''}
  </td> */}
                        <td
                          className=" py-2 text-left"
                          style={{ whiteSpace: "nowrap" }}
                          colSpan={
                            (row.isCustomRow && row.description) ||
                            [
                              "Water Bill",
                              "Freight Charge",
                              "Freight Surcharge",
                              "Rebates/Credits"
                            ].includes(row.product_name)
                              ? 2
                              : 1
                          }
                        >
                          {row.isCustomRow
                            ? row.description || ""
                            : row.prod_decsription || ""}
                        </td>

                        {!row.isCustomRow && (
  <td className="py-2 text-right relative">
    {editingRowId === row.product_id && status === "1" ? (
      <div className="flex items-center justify-end">
        <input
          className="w-24 p-1 text-right border rounded quantity-input"
          type="number"
          value={editedItems[index]?.item_quantity || ""}
          onChange={(e) => handleQuantityChange(index, e.target.value)}
          min="0"
          step="0.01"
          autoFocus
        />
      </div>
    ) : (
      <div className="flex items-center justify-end">
        {formatNumberWithCommas(row.item_quantity) || ""}
        {status === "1" && (
          <button
            className="ml-2 text-gray-600 hover:text-blue-500 edit-btn"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation(); // Prevent event bubbling
              toggleQuantityEditable(row.product_id);
            }}
          >
            <FaPen size={14} />
          </button>
        )}
      </div>
    )}
  </td>
)}

                        <td className=" py-2 text-right">
                          ${" "}
                          {Number(row.item_price).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }) || "0.00"}
                        </td>
                        <td
                          className="px-4 text-right"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          ${" "}
                          {Number(row.unit_total || 0).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          )}
                        </td>
                      </tr>
                      {/* Tax rows - only shown when expanded */}
                      {showTaxes && (
                        <>
                          {/* First display all tax rows */}
                          {productTaxes.map((tax, taxIndex) => {
                            const taxRate = tax[tax.po_customertax_name];
                            const quantity = parseFloat(row.item_quantity);
                            const taxAmount = taxRate * quantity;

                            return (
                              <tr
                                key={`tax-${row.product_id}-${taxIndex}`}
                                className="bg-gray-50 border-t border-gray-200"
                              >
                                <td className="px-4 py-1"></td>
                                <td
                                  className="py-1 pl-10 text-gray-600 text-md text-left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {tax.customer_taxes}
                                </td>
                                <td className="py-1" colSpan={1}></td>
                                <td
                                  className="py-1 text-center text-gray-600 text-md text-right"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {Number(quantity).toLocaleString()} gl
                                </td>
                                <td
                                  className="py-1 text-center text-gray-600 text-md text-right"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  ${" "}
                                  {Number(taxRate).toLocaleString(undefined, {
                                    minimumFractionDigits: 5,
                                    maximumFractionDigits: 5
                                  })}
                                </td>
                                <td
                                  className="px-4 text-right text-gray-600 text-md"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  ${" "}
                                  {Number(taxAmount).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                </td>
                              </tr>
                            );
                          })}

                          {/* Then display freight charge as the last item */}
                          {CustomerInfo.freigt_charge > 0 &&
                            row.item_quantity > 0 && (
                              <tr className="bg-gray-50 border-t border-gray-200">
                                <td className="px-4 py-1"></td>
                                <td
                                  className="py-1 pl-10 text-gray-600 text-md text-left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  Freight Charges
                                </td>
                                <td className="py-1" colSpan={1}></td>
                                <td
                                  className="py-1 text-center text-gray-600 text-md text-right"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {Number(row.item_quantity).toLocaleString()}{" "}
                                  gl
                                </td>
                                <td
                                  className="py-1 text-center text-gray-600 text-md text-right"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  ${" "}
                                  {Number(
                                    CustomerInfo.freigt_charge
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 5,
                                    maximumFractionDigits: 5
                                  })}
                                </td>
                                <td
                                  className="px-4 text-right text-gray-600 text-md"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  ${" "}
                                  {Number(
                                    CustomerInfo.freigt_charge *
                                      row.item_quantity
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                </td>
                              </tr>
                            )}
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>

            <div className=" mb-4">
              <div className="w-100 bg-white rounded-lg space-y-0  mt-2">
                {/* checking */}
                {brandInvoiceNumber && formField.bill_to !== "" && (
                  <div className="flex justify-start items-center pl-4 gap-4 ">
                    {remainingProducts.length > 0 && formField.bill_to ? (
                      <select
                        className="px-4 py-1 text-left w-[210px] border rounded-md  my-2 h-[35px]"
                        value={selectedProduct}
                        onChange={(e) => {
                          const selected = e.target.value;
                          setSelectedProduct(selected); // Update state
                          addRow(selected); // Add row and filter dropdown
                        }}
                      >
                        <option value="">--Add Product/--</option>
                        {remainingProducts.map((product, index) => (
                          <option key={index} value={product}>
                            {product}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <p className="text-green-500 ml-4"></p>
                    )}
                    <div className="text-red-500">{DiscountRebateError} </div>

                    <div className="text-red-500">{MinLengthError} </div>
                  </div>
                )}

                {/* {subTotal ? ( */}
                <div className="flex flex-col lg:flex-row p-6 pt-0 px-2 gap-6 pr-[30px]">
                  <div className="lg:w-2/3" style={{ display: "hidden" }}></div>
                  {/* Right Half: Subtotal and Taxes */}
                  <div
                    className="lg:w-1/3  rounded-md p-4 "
                    style={{ fontSize: "16px" }}
                  >
                    {/* {isSubtotalExpanded && ( */}
                    <div className="ml-6 mb-5">
                      <div className="flex justify-between font-semibold">
                        <span>Fuel Amount:</span>
                        <span>
                          ${" "}
                          {subTotal
                            ? Number(subTotal).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })
                            : Number(0.0).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}
                        </span>
                      </div>

                      <div className="mt-1 pt-2 flex justify-between items-center font-semibold">
                        <span>Fuel Tax</span>
                        <span>
                          ${" "}
                          {(() => {
                            // Calculate the sum of all fuel type taxes and freight charges
                            let totalTaxAmount = 0;
                            let totalFreightAmount = 0;

                            // Process each fuel type
                            items
                              .filter((item) => !item.isCustomRow)
                              .forEach((fuelItem) => {
                                const fuelType =
                                  fuelItem?.product_name?.toLowerCase();
                                const fuelQuantity =
                                  parseFloat(fuelItem.item_quantity) || 0;

                                if (fuelQuantity > 0) {
                                  // Add freight charge for this product

                                  // Get applicable taxes for this fuel type
                                  const fuelTaxes = TaxesView.filter((tax) => {
                                    const taxName =
                                      tax.po_customertax_name.toLowerCase();

                                    if (
                                      taxName.includes("diesel") &&
                                      fuelType === "diesel"
                                    ) {
                                      return true;
                                    }

                                    if (
                                      !taxName.includes("diesel") &&
                                      fuelType !== "diesel"
                                    ) {
                                      return true;
                                    }

                                    return false;
                                  });

                                  // Add this fuel's tax total to the grand total
                                  fuelTaxes.forEach((tax) => {
                                    totalTaxAmount +=
                                      tax[tax.po_customertax_name] *
                                      fuelQuantity;
                                  });
                                }
                              });

                            // Return combined total
                            return Number(totalTaxAmount).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                            );
                          })()}
                        </span>
                      </div>
                      <div className="mt-1 border-b border-gray-300 pt-2 flex justify-between items-center font-semibold">
                        <span>Freight Charges</span>
                        <span>
                          ${" "}
                          {(() => {
                            // Calculate the sum of all fuel type taxes and freight charges
                            let totalTaxAmount = 0;
                            let totalFreightAmount = 0;

                            // Process each fuel type
                            items
                              .filter((item) => !item.isCustomRow)
                              .forEach((fuelItem) => {
                                const fuelType =
                                  fuelItem?.product_name?.toLowerCase();
                                const fuelQuantity =
                                  parseFloat(fuelItem.item_quantity) || 0;

                                if (fuelQuantity > 0) {
                                  // Add freight charge for this product
                                  if (CustomerInfo.freigt_charge > 0) {
                                    totalFreightAmount +=
                                      CustomerInfo.freigt_charge * fuelQuantity;
                                  }
                                }
                              });

                            // Return combined total
                            return Number(
                              totalTaxAmount + totalFreightAmount
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            });
                          })()}
                        </span>
                      </div>
                    </div>

                    <div className="ml-6 mt-5">
                      {/* {items.filter(item => !item.isCustomRow  && item.product_name).length > 0 && (
<>
{items.filter(item => !item.isCustomRow).map((fuelItem) => {
const fuelType = (fuelItem.product_name || "").toLowerCase();
const fuelQuantity = parseFloat(fuelItem.item_quantity) || 0;

const fuelTaxes = TaxesView.filter((tax) => {
if (!tax || !tax.po_customertax_name) return false;

const taxName = tax.po_customertax_name.toLowerCase();

if (taxName.includes('diesel') && fuelType === 'diesel') {
return true;
}

if (
(!taxName.includes('diesel')) && 
fuelType !== 'diesel'
) {
return true;
}

return false;
});

if (fuelTaxes.length > 0 && fuelQuantity > 0) {
const fuelTaxTotal = fuelTaxes.reduce((total, tax) => {
return total + (parseFloat(tax[tax.po_customertax_name] || 0) * fuelQuantity);
}, 0);

return (
<div key={fuelItem.product_id || fuelItem.item_id} className="mb-1">
<div 
className="flex justify-between items-center cursor-pointer hover:bg-gray-50 py-1"
onClick={(e) => {
e.preventDefault();
setActiveFuelSection(activeFuelSection === fuelItem.product_id ? null : fuelItem.product_id);
}}
>
<div className="flex items-center gap-1">
<button className="text-blue-500 focus:outline-none">
{activeFuelSection === fuelItem.product_id ? "▼" : "►"}
</button>
<span className="font-semibold">
{fuelItem.product_name} Taxes
</span>
</div>
<span>
${" "}
{Number(fuelTaxTotal).toLocaleString(undefined, {
minimumFractionDigits: 2,
maximumFractionDigits: 2,
})}
</span>
</div>

{activeFuelSection === fuelItem.product_id && (      
<div className="pl-6 pt-1">
{fuelTaxes.map((tax, idx) => {
const specificTaxAmount = tax[tax.po_customertax_name] * fuelQuantity;

return (
<div 
  className="flex justify-between items-center" 
  key={`${fuelItem.product_id || idx}-${tax.po_customertax_name}-${idx}`}
>
  <span>{tax.customer_taxes}</span>
  <span>
    ${" "}
    {Number(specificTaxAmount).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}
  </span>
</div>
);
})}
</div>
)}
</div>
);
}
return null;
})}

<div className="mt-3 pt-2 border-t border-gray-300 flex justify-between items-center font-semibold">
<span>Total Tax</span>
<span>
${" "}
{(() => {
// Calculate the sum of all fuel type taxes
let totalTaxAmount = 0;

// Process each fuel type
items.filter(item => !item.isCustomRow).forEach((fuelItem) => {
const fuelType = fuelItem.product_name.toLowerCase();
const fuelQuantity = parseFloat(fuelItem.item_quantity) || 0;

if (fuelQuantity > 0) {
  // Get applicable taxes for this fuel type
  const fuelTaxes = TaxesView.filter((tax) => {
    const taxName = tax.po_customertax_name.toLowerCase();
    
    if (taxName.includes('diesel') && fuelType === 'diesel') {
      return true;
    }
    
    if (
      (!taxName.includes('diesel')) && 
      fuelType !== 'diesel'
    ) {
      return true;
    }
    
    return false;
  });
  
  // Add this fuel's tax total to the grand total
  fuelTaxes.forEach((tax) => {
    totalTaxAmount += tax[tax.po_customertax_name] * fuelQuantity;
  });
}
});

return Number(totalTaxAmount).toLocaleString(undefined, {
minimumFractionDigits: 2,
maximumFractionDigits: 2,
});
})()}
</span>
</div>
</>
)} */}
                    </div>

                    <div className="mt-2 flex justify-between items-center bg-gray-100">
                      <div className="flex items-center gap-2">
                        <span className="text-gray-800 font-[20px] font-bold ml-[24px]">
                          Invoice Amount
                        </span>
                      </div>
                      <span className="text-gray-800 font-[20px] font-bold ">
                        $ {calculateInvoiceAmount(subTotal, [], taxTotal1)}{" "}
                        {/* Includes all products, custom rows, and taxes */}
                      </span>
                    </div>
                    {(status === "4" || statuspay === "4") && (
                      <div className="mt-2 flex justify-between items-center">
                        <div className="flex items-center gap-2">
                          <span className="text-gray-800 font-[20px] font-semibold ml-[20px]">
                            Amount Paid (
                            {`${formatDate1(BillShip?.amount_paid_date || 0)}`})
                          </span>
                        </div>
                        <span className="text-gray-800 text-[15px] font-semibold">
                          ${" "}
                          {Number(BillShip.invoice_amount_paid).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          )}{" "}
                        </span>
                      </div>
                    )}
                    {(status === "4" || statuspay === "4") && (
                      <div className="mt-2 flex justify-between items-center">
                        <div className="flex items-center gap-2">
                          <span className="text-gray-800 font-[20px] font-semibold ml-[20px]">
                            Balance Due
                          </span>
                        </div>
                        <span className="text-gray-800 font-[20px] font-semibold ">
                          {status === "4" || statuspay === "4"
                            ? `$ ${Number(
                                BillShip?.due_amount || 0
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}`
                            : ""}
                          {/* Includes all products, custom rows, and taxes */}
                        </span>
                      </div>
                    )}
                    {status === "4" ||
                      (statuspay === "4" && (
                        <div className="mt-2 flex justify-between items-center">
                          <div className="flex items-center gap-2">
                            <span className="text-gray-800 font-[20px] font-bold ml-[20px]">
                              Due Amount
                            </span>
                          </div>
                          <span className="text-gray-800 font-[20px] font-bold ">
                            ${" "}
                            {Number(BillShip.due_amount).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                            )}{" "}
                            {/* Includes all products, custom rows, and taxes */}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
                {/* ) : ( */}
                {/* ""
)} */}

                <div
                  className="flex justify-start items-end"
                  style={{ paddingBottom: "40px", paddingLeft: "45px" }}
                >
                  {/* <img
    src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=DummyQRCode"
    alt=" QR Code"
    className="w-20 h-20"
  /> */}
                </div>

                {/* Terms & Conditions Section with Edit Pencil */}
                {/* {!isOpen ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "10px",
                      width: "58vw",
                      marginLeft: "30px",
                      marginBottom: "30px"
                    }}
                    className="bg-gray-100"
                    onClick={toggleInput}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Terms & Conditions
                    </span>

                    <div className="flex items-center">
                      {status === "1" && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleTermsEditable();
                          }}
                          className="mr-2 text-gray-600 hover:text-blue-500"
                        >
                          {/* <FaPen size={16} /> */}
                {/* </button>
                      )}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{
                          transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                          transition: "transform 0.2s ease"
                        }}
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </div>
                  </div>
                ) : (
                  <div
                    className="relative bg-gray-100 px-2 rounded-md mb-2"
                    style={{
                      width: "58vw",
                      marginLeft: "30px",
                      marginBottom: "30px"
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <LightbulbOutlinedIcon
                          className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
                          style={{ borderRadius: "50%", fontSize: "28px" }}
                        />
                        <div className="pl-8 pt-1 text-md font-semibold flex items-center">
                          Terms & Conditions
                          {status === "1" && !isTermsEditable && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                // toggleTermsEditable();
                              }}
                              className="ml-2 text-gray-600 hover:text-blue-500"
                            >
                              <FaPen size={16} />
                            </button>
                          )}
                        </div>
                      </div>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        onClick={toggleInput}
                        style={{
                          transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                          transition: "transform 0.2s ease",
                          cursor: "pointer"
                        }}
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </div>

                    <textarea
                      ref={termsTextareaRef}
                      className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
                      placeholder="Enter terms and conditions here..."
                      rows={1}
                      style={{
                        // height: "40px",  // Fixed height regardless of edit mode
                        overflow: isTermsEditable ? "auto" : "hidden" // Only show scrollbar in edit mode
                      }}
                      value={
                        isTermsEditable
                          ? editedTerms
                          : BillShip.terms_conditions === null ||
                            BillShip.terms_conditions === "null"
                          ? ""
                          : BillShip.terms_conditions
                      }
                      disabled={!isTermsEditable}
                      onChange={(e) => setEditedTerms(e.target.value)}
                      maxLength={600}
                    />
                  </div>
                )} */}

                {/* below box */}

                <div className="flex flex-row text-md w-90 text-[15.2px] py-[30px] px-[30px] pr-[30px]">
                  <div
                    className="flex flex-grow flex-shrink-0 min-w-0 rounded-lg overflow-hidden "
                    style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                  >
                    <div className="flex flex-col md:flex-row w-full">
                      {/* Total Gallons Delivered */}
                      <div className="px-2 py-3 flex flex-col items-center flex-grow">
                        <div className="font-semibold text-center whitespace-nowrap">
                          Total Gallons Delivered
                        </div>
                        <div className="font-bold">
                          {Number(boxDetails.quantity)}
                        </div>
                      </div>

                      {/* Fuel Amount */}
                      <div className="px-2 py-3 flex flex-col items-center flex-grow">
                        <div className="font-semibold text-center whitespace-nowrap">
                          Fuel Amount
                        </div>
                        <div className="font-bold">
                          ${" "}
                          {subTotal
                            ? Number(subTotal).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })
                            : Number(0.0).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}
                        </div>
                      </div>

                      {/* Plus Sign */}
                      <div className="flex items-center justify-center px-2">
                        <span className="text-lg font-bold">+</span>
                      </div>

                      {/* Taxes/Other Charges */}
                      <div className="px-2 py-3 flex flex-col items-center flex-grow">
                        <div className="font-semibold text-center whitespace-nowrap">
                          Taxes/Other Charges
                        </div>
                        <div className="font-bold">
                          {(() => {
                            // Calculate taxes
                            const taxTotal =
                              Number(taxTotals.pptTaxes) +
                              Number(taxTotals.otherTaxes);

                            // Calculate per-product freight charges
                            const freightChargesTotal = Number(
                              taxTotals.freightCharges || 0
                            );

                            const customRowsTotal = items
                              .filter(
                                (row) =>
                                  row.isCustomRow &&
                                  row.product_name !== "Freight Charge"
                              )
                              .reduce((sum, row) => {
                                if (row.unit_total > 0) {
                                  return sum + Number(row.unit_total);
                                }
                                return sum;
                              }, 0);

                            // Combine all totals
                            const totalSum =
                              taxTotal + freightChargesTotal + customRowsTotal;

                            return (
                              <div style={{ whiteSpace: "nowrap" }}>
                                ${" "}
                                {totalSum.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}
                              </div>
                            );
                          })()}
                        </div>
                      </div>

                      {/* Equals Sign */}
                      <div className="flex items-center justify-center px-2">
                        <span className="text-lg font-bold">=</span>
                      </div>

                      {/* Invoice Amount */}
                      <div className="px-2 py-3 flex flex-col items-center flex-grow bg-gray-50">
                        <div className="font-semibold text-center whitespace-nowrap">
                          Invoice Amt
                        </div>
                        <div className="font-bold">
                          $ {calculateInvoiceAmount(subTotal, [], taxTotal1)}
                        </div>
                      </div>

                      {/* Late Payment Section */}
                      {status !== "7" && (
                        <div className="px-2 py-3 flex flex-col flex-grow bg-gray-100">
                          <div className="font-semibold whitespace-nowrap">
                            If paid after due date
                          </div>
                          <div className="flex items-center w-auto">
                            <span className="font-semibold mr-1 whitespace-nowrap">
                              Invoice amt:
                            </span>
                            <span className="font-bold text-red-600">
                              ${" "}
                              {(() => {
                                // Get the original invoice amount
                                const originalAmount = calculateInvoiceAmount(
                                  subTotal,
                                  items,
                                  taxTotal1
                                );

                                // Parse it in case it's a formatted string
                                const originalAmountNumber =
                                  typeof originalAmount === "string"
                                    ? parseFloat(
                                        originalAmount
                                          .replace(/,/g, "")
                                          .replace("$", "")
                                      )
                                    : Number(originalAmount);

                                // Calculate 5% more
                                const amountWithLatePaymentPenalty =
                                  originalAmountNumber * 1.05;

                                // Format and return
                                return amountWithLatePaymentPenalty.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  }
                                );
                              })()}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* below box ends */}
                {/* Save button appears when changes are made */}
                {showSaveButton && status === "1" && (
                  <div className="flex justify-end mb-8">
                    <button
                      // saveChanges
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      className="w-[150px] mr-[30px] mb-[30px] px-2 px-12 py-1 border-2 border-blue-600 text-blue-600 hover:font-bold rounded-md hover:bg-blue-600 hover:shadow-blue-700/50 uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90 "
                    >
                      Save
                    </button>
                  </div>
                )}
                <div className="text-center font-sans border-t-2 border-grey-800 pt-2 mt-12">
                  <p className="text-gray-600 font-bold text-sm">
                    {/* Any questions please contact your account manager @{" "} */}
                    {/* Thanks for your business! */}
                    {/* {BillShip.footer} */}
                    {/* <span className="font-normal">{CustomerData.contact_no}</span> */}
                  </p>
                  <div className="flex mb-[0px] h-[90px] items-start justify-between">
                    {/* Left Section: Image */}
                    <div className="flex mb-[0px] h-[90px] items-start justify-start">
                      <img
                        src={`${baseURL}/uploads/img/business/${CustomerData.image_file}`}
                        alt="Business Logo"
                        // style={{visibility:'hidden'}}
                        // className="w-20 h-20 object-contain ml-[50px] mb-[30px]" // Ensures the image scales proportionally
                        className="w-20 h-20 object-contain ml-[30px] mb-[30px] mr-[30px]"
                      />
                      <div>
                        <div className="text-left mr-[20px] text-gray-600 text-sm pr-[20px] mt-3">
                          <p
                            className="whitespace-nowrap
"
                          >
                            {CustomerData.business_name}
                          </p>
                          <p
                            className="whitespace-nowrap
"
                          >
                            {CustomerData.business_address}{" "}
                          </p>
                          <p
                            className="whitespace-nowrap
"
                          >
                            {CustomerData.business_city}{" "}
                            {CustomerData.business_state}{" "}
                            {CustomerData.business_zip_code}
                          </p>{" "}
                          {/* <p><span className='ml-2'></span></p> */}
                        </div>
                      </div>
                    </div>

                    {/* Center Section: Thank you message */}
                    <div className="text-center mt-3">
                      <p className="text-gray-600 text-sm  mr-[80px]">
                        Thanks for your business! <br />
                        For any questions on this bill <br />
                        please contact your account manager
                      </p>
                      {/* <div className="flex justify-center gap-4">
      <p className="flex items-center justify-center text-gray-600 text-sm">
        {CustomerData.fax_number ? (
          <img
            src={FaxIcon}
            alt="Fax Icon"
            className="w-3 h-3 mr-2"
          />
        ) : (
          ""
        )}
        {CustomerData.fax_number}
      </p>
      <p className="flex items-center justify-center text-gray-600 text-sm">
        <p>
          <ContactPhoneOutlinedIcon
            className="mr-2"
            fontSize="10px"
          />
        </p>

        {CustomerData.contact_no}
      </p>
    </div> */}
                      {/* <p className="text-md font-bold"></p> */}
                    </div>
                    <div className="mt-3 gap-4 mx-[60px]">
                      <div>
                        {CustomerData.fax_number ? (
                          <div>
                            {CustomerData.fax_number !== null &&
                            CustomerData.fax_number !== "null" &&
                            CustomerData.fax_number !== undefined &&
                            CustomerData.fax_number !== "undefined" ? (
                              <p className="flex items-center justify-center text-gray-600 text-sm">
                                <img
                                  src={FaxIcon}
                                  alt="Fax Icon"
                                  className="w-3 h-3 mr-2 whitespace-nowrap"
                                />
                                {CustomerData.fax_number}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {CustomerData.business_contact_no ? (
                          <div>
                            {CustomerData.business_contact_no !== null ||
                            CustomerData.business_contact_no !== "null" ||
                            CustomerData.business_contact_no !== undefined ||
                            CustomerData.business_contact_no !== "undefined" ? (
                              <p
                                className="flex items-center justify-center text-gray-600 text-sm whitespace-nowrap"
                              >
                                <p>
                                  <ContactPhoneOutlinedIcon
                                    className="mr-2"
                                    fontSize="10px"
                                  />
                                </p>

                                {CustomerData.business_contact_no}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* Right Section: Customer Data */}
                    {/* <div className="text-right mr-[20px] text-gray-600 text-sm pr-[20px]">
    <p >{CustomerData.business_name}</p>
    <p>{CustomerData.business_address} </p>
    <p>
      {CustomerData.business_city}{" "}
      {CustomerData.business_state}{" "}
      {CustomerData.business_zip_code}
    </p>{" "} */}
                    {/* <p><span className='ml-2'></span></p> */}
                    {/* </div> */}
                  </div>
                </div>

                {/* </div> */}
              </div>
            </div>
          </form>
        )}
        {/* {activeTab === "Edit" && (
<div>{tabs.find((tab) => tab.key === "Edit")?.component}</div>
)} */}
        {openSendInvoiceModalId && (
          <PopUpModalForSendInvoice
            onClose={() => setOpenSendInvoiceModalId(null)}
            invoiceNumber={openSendInvoiceModalId}
            invoiceName={businessName}
            // resetRowSelection={resetRowSelection}
            customerEmail={customerEmail}
            finalInvoiceAmount={final_invoice_amount}
            dueDate={dueDateinvoice}
            setTriggerFetch={setTriggerFetch}
            triggerFetch={triggerFetch}
          />
        )}
        {payModalInvoice && (
          <InvoicePayModal
            open={payModalInvoice}
            handleClose={() => setpayModalInvoice(false)}
            customerName={businessName}
            invoiceNumber={invoicenumber}
            finalInvoiceAmount={Number(FinalInvoiceAmount)}
            setTriggerFetch={setTriggerFetch}
            triggerFetch={triggerFetch}
            // resetRowSelection={table.resetRowSelection}
          />
        )}
        {openDeleteModalId && (
          <PopUpModal
            onClose={() => setOpenDeleteModalId(null)}
            invoiceNumber={openDeleteModalId}
            // invoiceDelete={handleDeleteInvoices}
            invoiceName={businessName}
            // resetRowSelection={table.resetRowSelection}
            setTriggerFetch={setTriggerFetch}
            triggerFetch={triggerFetch}
          />
        )}
      </div>
      <div className="flex justify-center items-center ">
        <div
          className="relative w-[62vw] bg-white rounded-lg space-y-0  mt-2 mb-[20px] p-5"
          style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
        >
          {!isOpen ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px"
                // width: "6vw",
                // marginLeft: "30px",
                // marginBottom: "30px"
              }}
              className="bg-gray-100"
              onClick={toggleInput}
            >
              <span style={{ fontWeight: "bold" }}>Terms & Conditions</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{
                  transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                  transition: "transform 0.2s ease"
                }}
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          ) : (
            <div
              className="relative bg-gray-100 rounded-m"
              style={
                {
                  // width: "58vw",
                  // marginLeft: "30px",
                  // marginBottom: "30px"
                }
              }
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <LightbulbOutlinedIcon
                    className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
                    style={{ borderRadius: "50%", fontSize: "28px" }}
                  />
                  <div className="pl-8 pt-1 text-md font-semibold">
                    Terms & Conditions
                  </div>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  onClick={toggleInput}
                  style={{
                    transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                    transition: "transform 0.2s ease",
                    cursor: "pointer"
                  }}
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </div>

              <textarea
                className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
                placeholder="Enter terms and conditions here..."
                rows={1}
                value={
                  BillShip.terms_conditions === null ||
                  BillShip.terms_conditions === "null"
                    ? ""
                    : BillShip.terms_conditions
                }
                disabled
                // onChange={(e) => setTermsAndConditions(e.target.value)}
                maxLength={600}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center items-center ">
        <div
          className="relative w-[62vw] bg-white rounded-lg space-y-0  mt-2 mb-[20px] p-5"
          style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
        >
          {/* <div className="mx-0" >
          {!isOpen1 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            cursor: "pointer",
                            // border: "1px solid #ccc",
                            padding: "10px",
                            // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)"
                          }}
                          className="w-full mb-0 bg-gray-100"
                          onClick={toggleInput1}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Memo & Notes
                          </span>
      
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              transform: isOpen1 ? "rotate(90deg)" : "rotate(0deg)",
                              transition: "transform 0.2s ease",
                            }}
                          >
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </div>
                      ) : (
                        <div className="relative bg-gray-100 px-2 rounded-md mb-0">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <NoteOutlinedIcon
                                className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
                                style={{ borderRadius: "50%", fontSize: "16px" }}
                              />
                              <div className="pl-8 pt-1 text-md font-semibold">
                                Memo & Notes
                              </div>
                            </div>
      
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              onClick={toggleInput1}
                              style={{
                                transform: isOpen1 ? "rotate(90deg)" : "rotate(0deg)",
                                transition: "transform 0.2s ease",
                                cursor: "pointer",
                              }}
                            >
                              <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                          </div>
      
                          <textarea
                            className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
                            placeholder="Enter Memo and Notes here..."
                            rows={1}
                            value={
                              termsAndConditions1 === null ||
                                termsAndConditions1 === "null"
                                ? ""
                                : termsAndConditions1
                            }
                            onChange={(e) => setTermsAndConditions1(e.target.value)}
                            maxLength={600}
                          />
                        </div>
                      )}
                      </div> */}
          {/* Memo & Notes Section - Always show in Edit mode, but with special requirements if partially paid */}

          {/* Memo & Notes Section - Always show in Edit mode, but with special requirements if partially paid */}
          <div className="relative my-0 bg-white overflow-hidden">
            <div
              className="bg-gray-100"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
                backgroundColor: "#f3f4f6"
              }}
              onClick={toggleMemoInput}
            >
              <div className="flex items-center">
                {/* <NotesIcon
                          className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
                          style={{ borderRadius: "50%", fontSize: "28px" }}
                        /> */}
                <div className="text-md font-bold">Memos & Notes</div>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{
                  transform: isMemoOpen ? "rotate(90deg)" : "rotate(0deg)",
                  transition: "transform 0.2s ease"
                }}
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>

            {isMemoOpen && (
              <>
                <div className="mb-4">
                  <textarea
                    ref={memoTextareaRef}
                    className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
                    placeholder={"Enter memo or note here..."}
                    rows={1}
                    value={currentMemo}
                    onChange={(e) => setCurrentMemo(e.target.value)}
                    maxLength={500}
                  />
                  <div className="flex justify-between mt-2 mx-2">
                    <small className="text-gray-500">
                      {currentMemo.length}/500 characters
                    </small>
                    {/* <button
          onClick={handleSaveMemo}
          className="px-4 py-1 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50"
          disabled={currentMemo.trim() === ""}
        >
          Save Memo
        </button> */}
                  </div>
                </div>
                <div className="p-4 pt-0">
                  {/* Input area for new memo */}

                  {/* List of previous memos (maximum 5) */}
                  {memos.length > 0 && (
                    <div className="border border-gray-200 rounded-md max-h-60 overflow-y-auto">
                      {memos.map((memo, index) => (
                        <div
                          key={memo.id}
                          className={`p-3 ${
                            index < memos.length - 1
                              ? "border-b border-gray-200"
                              : ""
                          }`}
                        >
                          <div className="text-sm mb-1">{memo.text}</div>
                          <div className="flex justify-between text-xs text-gray-500 mt-1">
                            {/* <span>By: {memo.created_by}</span>
                <span>
                  {memo.edited_on
                    ? `Edited: ${formatMemoDate(memo.edited_on)}`
                    : `Created: ${formatMemoDate(memo.created_on)}`}
                </span> */}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {memos.length === 0 && (
                    <div className="text-center text-gray-500 py-4">
                      No memos available for this statement.
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditInvoice;
