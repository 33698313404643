import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { FaEnvelope, FaLock, FaSignInAlt } from "react-icons/fa";
// import img1 from "../../assets/images/Login.jpg"
// import img2 from "../../assets/images/snapedit_1701426729284.png";
import { IoPerson } from "react-icons/io5";
import { useNavigate, Link, resolvePath } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import ToastNotificationBox from "./ToastBox/ToastNotificationBox";

import * as Yup from "yup";
import Header from "../Headerside";
import { RiLoginCircleFill } from "react-icons/ri";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import OwnerPasswordChangeModal from "./OwnerPasswordChangeModal";
import { GiCancel } from "react-icons/gi";
import CloseIcon from "@mui/icons-material/Close";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
// import { FaXmark } from 'react-icons/fa6'
import config from "../../config/config";
import PersonIcon from "@mui/icons-material/Person";
import InvoiceFileLogo from "../../assets/images/InvoiceFileLogo.png";
import OtpLogo from "../../assets/images/otpImage.png";

import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import { useDispatch } from "react-redux";

const OtpPage = ({
  onLogin,
  handleSignUpClick,
  handleSignOut,
  userEmail,
  dummyOtp,
  handleTerms,
  handleShowOTP,
  isaccepted
}) => {
  const [password, setPassword] = useState("");

  const [timer, setTimer] = useState(60);
  const [errorMessage, setErrorMessage] = useState(
    "Please enter a 6-digit PIN"
  );

  const [showSignUp, setShowSignUp] = useState(false);
  const dispatch = useDispatch();

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").trim(); // Get pasted data
    if (pastedData.length === otpValues.length) {
      const newOtpValues = pastedData.split(""); // Split into individual characters
      setOtpValues(newOtpValues); // Update the state with the new values
    }
  };

  const handleResendOtp = async () => {
    setOtpError("Please enter a 6-digit PIN");
    console.log("resending");
    try {
      const response = await axios.post(
        `${baseURL}/${btoa("login/resendotp")}`,
        {
          email: userEmail
        }
      );
      console.log(response, "response");
      localStorage.setItem("userOtp", response.data.otp);
      // Assuming the new OTP comes from the response
      const newOtp = response.data.otp;
      setOtp(newOtp);
      setOtpValues(["", "", "", "", "", ""]); // Reset OTP input fields
      setTimer(50); // Reset timer
      setOtpExpired(false); // Mark OTP as active
      handleLoginClick();
    } catch (error) {
      console.log("Error resending OTP:", error);
    }
  };

  const [user, setUser] = useState({ contact_name: "", contact_email: "" });

  const handleLocalSignUpClick = () => {
    setShowSignUp(!showSignUp);
  };

  const [otp, setOtp] = useState("");
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [otpExpired, setOtpExpired] = useState(false);
  const [otpError, setOtpError] = useState("");

  const navigate = useNavigate();
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

  useEffect(() => {
    setOtp(localStorage.getItem("userOtp"));
  }, []);

  const handleOtpChange = (value, index) => {
    // Validate numeric input
    if (!/^\d*$/.test(value)) return;

    const updatedOtpValues = [...otpValues];

    updatedOtpValues[index] = value.slice(0, 1); // Ensure only one digit is allowed
    setOtpValues(updatedOtpValues);

    if (updatedOtpValues.every((digit) => digit !== "")) {
      setErrorMessage(""); // Clear error if all 6 digits are filled
    } else {
      setErrorMessage("Please enter a 6-digit PIN"); // Show error if incomplete
    }
    // Automatically focus next input field if value is entered
    if (value && index < otpValues.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otpValues[index] && index > 0) {
      // Focus the previous input on backspace
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const isOtpExpired = () => timer === 0;

  const handleOtpExpired = () => {
    setOtpValues(["", "", "", "", "", ""]);
    setOtpExpired(true);
  };

  useEffect(() => {
    // Update the timer every second
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]); // Empty dependency array means this effect runs once after the initial render

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const userStatus = localStorage.getItem("userStatus");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSignOutModel = () => {
    setIsModalOpen(!isModalOpen);
    // window.location.reload();
  };

  const handleLoginClick = async function (e) {
    if (e) {
      e.preventDefault();
      console.log(userEmail);

      if (isOtpExpired()) {
        handleOtpExpired();
        return;
      }

      try {
        const response = await axios.post(
          `${baseURL}/${btoa("login/validateotp")}`,
          {
            email: userEmail,
            userotp1: otpValues[0] || "",
            userotp2: otpValues[1] || "",
            userotp3: otpValues[2] || "",
            userotp4: otpValues[3] || "",
            userotp5: otpValues[4] || "",
            userotp6: otpValues[5] || ""
          }
        );

        console.log("OTP Response", response);
        setTimer(50); // Reset timer

        if (
          response.data.isOtpValid === true &&
          response.data.userStatus === 4
        ) {
          setOtpError("");
          setIsModalOpen(!isModalOpen);
          return;
          // handleTerms(true, true)
        }
        setUser({
          contact_name: response.data.loggedin[0].contact_name,
          contact_email: response.data.loggedin[0].contact_email
        });

        localStorage.setItem(
          "userName",
          response.data.loggedin[0].contact_name
        );
        localStorage.setItem(
          "userEmail",
          response.data.loggedin[0].contact_email
        );

        if (response.data.eulaAcceptance === "0") {
          handleTerms(true, true);
        } else {
          window.location.reload();
        }

        localStorage.setItem("user_role", response.data.loggedin[0].user_role);
        localStorage.setItem("id", response.data.loggedin[0].id);
        localStorage.setItem("status", "true");
        localStorage.setItem("token", response.data.token);
        localStorage.setItem(
          "userName",
          response.data.loggedin[0].contact_name
        );
        localStorage.setItem(
          "userEmail",
          response.data.loggedin[0].contact_email
        );

        if (response.data.loggedin[0].user_role === "1") {
          localStorage.setItem("user_id", response.data.loggedin[0].id);
        } else if (response.data.loggedin[0].user_role === "2") {
          localStorage.setItem(
            "business_id",
            response.data.loggedin[0].business_id
          );
          localStorage.setItem("user_id", response.data.loggedin[0].user_id);
        } else if (response.data.loggedin[0].user_role === "3") {
          localStorage.setItem(
            "business_id",
            response.data.loggedin[0].business_id
          );
          localStorage.setItem("user_id", response.data.loggedin[0].user_id);
        }
      } catch (error) {
        dispatch(
          addToast({ type: "danger", message: `Please Enter Valid OTP` })
        );
        console.log("error posting details", error);

        if (error.response.data.login_status === false) {
          setOtpError("Invalid OTP");
        }
      }
    }
  }.bind(this);

  const handleLoginAsOwner = async () => {
    console.log("handleownerchecking");
    try {
      const response = await axios.post(
        `${baseURL}/${btoa("login/loginasou")}`,
        {
          loginAs: 2,
          email: userEmail
          // userotp1: otpValues[0] || "",
          // userotp2: otpValues[1] || "",
          // userotp3: otpValues[2] || "",
          // userotp4: otpValues[3] || "",
          // userotp5: otpValues[4] || "",
          // userotp6: otpValues[5] || "",
        }
      );

      console.log("OTP Response foe owner", response);

      setUser({
        contact_name: response.data.loggedin[0].contact_name,
        contact_email: response.data.loggedin[0].contact_email
      });

      localStorage.setItem("userName", response.data.loggedin[0].contact_name);
      localStorage.setItem(
        "userEmail",
        response.data.loggedin[0].contact_email
      );

      if (response.data.eulaAcceptance === "0") {
        console.log("checking 1");
        handleTerms(true, true);
      } else {
        console.log("checking 2");
        window.location.reload();
      }

      localStorage.setItem("user_role", response.data.loggedin[0].user_role);
      localStorage.setItem("id", response.data.loggedin[0].id);
      localStorage.setItem("status", "true");
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userName", response.data.loggedin[0].contact_name);
      localStorage.setItem(
        "userEmail",
        response.data.loggedin[0].contact_email
      );

      if (response.data.loggedin[0].user_role === "1") {
        localStorage.setItem("user_id", response.data.loggedin[0].id);
      } else if (response.data.loggedin[0].user_role === "2") {
        localStorage.setItem(
          "business_id",
          response.data.loggedin[0].business_id
        );
        localStorage.setItem("user_id", response.data.loggedin[0].user_id);
      } else if (response.data.loggedin[0].user_role === "3") {
        localStorage.setItem(
          "business_id",
          response.data.loggedin[0].business_id
        );
        localStorage.setItem("user_id", response.data.loggedin[0].user_id);
      }
      // setIsModalOpen(false);
    } catch (error) {
      dispatch(addToast({ type: "danger", message: `Please Enter Valid OTP` }));

      console.log("error posting details", error);
    }
  };

  const handleLoginAsManager = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/${btoa("login/loginasou")}`,
        {
          loginAs: 3,
          email: userEmail
          // userotp1: otpValues[0] || "",
          // userotp2: otpValues[1] || "",
          // userotp3: otpValues[2] || "",
          // userotp4: otpValues[3] || "",
          // userotp5: otpValues[4] || "",
          // userotp6: otpValues[5] || "",
        }
      );

      console.log("OTP Response", response);

      setUser({
        contact_name: response.data.loggedin[0].contact_name,
        contact_email: response.data.loggedin[0].contact_email
      });

      localStorage.setItem("userName", response.data.loggedin[0].contact_name);
      localStorage.setItem(
        "userEmail",
        response.data.loggedin[0].contact_email
      );

      if (response.data.eulaAcceptance === "0") {
        handleTerms(true, true);
      } else {
        window.location.reload();
      }

      localStorage.setItem("user_role", response.data.loggedin[0].user_role);
      localStorage.setItem("id", response.data.loggedin[0].id);
      localStorage.setItem("status", "true");
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userName", response.data.loggedin[0].contact_name);
      localStorage.setItem(
        "userEmail",
        response.data.loggedin[0].contact_email
      );

      if (response.data.loggedin[0].user_role === "1") {
        localStorage.setItem("user_id", response.data.loggedin[0].id);
      } else if (response.data.loggedin[0].user_role === "2") {
        localStorage.setItem(
          "business_id",
          response.data.loggedin[0].business_id
        );
        localStorage.setItem("user_id", response.data.loggedin[0].user_id);
      } else if (response.data.loggedin[0].user_role === "3") {
        localStorage.setItem(
          "business_id",
          response.data.loggedin[0].business_id
        );
        localStorage.setItem("user_id", response.data.loggedin[0].manager_id);
      }
      // setIsModalOpen(false);
    } catch (error) {
      dispatch(addToast({ type: "danger", message: `Please Enter Valid OTP` }));

      console.log("error posting details", error);
    }
  };

  // window.addEventListener("beforeunload", function (e) {
  //   // Check if the form is submitted
  //   const isFormSubmitted = localStorage.getItem("isFormSubmitted") === "true";

  //   if (!isFormSubmitted) {
  //     handleSignOut();
  //   }
  // });
  window.addEventListener("beforeunload", function (e) {
    // Set a flag indicating this is a page refresh
    localStorage.setItem("isRefreshing", "true");
   
    // Only log out if it's not a refresh and not submitted
    const isFormSubmitted = localStorage.getItem("isFormSubmitted") === "true";
   
    if (!isFormSubmitted && localStorage.getItem("isRefreshing") !== "true") {
      handleSignOut();
    }
  });

  // // Reset the flag when the user manually refreshes
  // window.addEventListener("unload", function (e) {
  //   localStorage.setItem("isRefreshing", "false");
  //   localStorage.setItem("isFormSubmitted", "false");
  // });
  window.addEventListener("unload", function (e) {
    
    // We don't want to reset these flags on a regular page refresh
    // Only reset them if navigating away from the site completely
   
    // The "isRefreshing" flag will be checked when the page loads again
    // and can be reset after page load completes
  });

  return (
    <div className="flex w-screen h-screen justify-center items-center flex-col relative overflow-hidden">
      <ToastNotificationBox />
      <div className="absolute top-[-12.5%] left-[55%] w-[1040px] h-[1040px] bg-[#25caed] rotate-[-13deg] shadow-lg rounded-[4%] -z-9999"></div>
      <div className="absolute top-[-10%] left-[58%] w-[1050px] h-[1050px] bg-white rotate-[-13deg] shadow-lg rounded-[4%] -z-9999"></div>

      {!isModalOpen && (
        <div className="flex h-screen w-screen">
          {/* Left Section (3/4 width) */}
          <div className="w-3/5 bg-gradient-to-br from-[#25caed] to-[#0044AB] flex flex-col justify-center items-center  pr-[40px]">
            <div className="text-center text-white max-w-[500px] px-8">
              <h1 className="text-5xl font-bold m-auto ml-[50px]">
                <img
                  style={{ width: "300px", height: "80px" }}
                  src={InvoiceFileLogo}
                  alt="InvoiceFile"
                  className={`${"w-max"}`}
                />
              </h1>
              <p className="text-xl font-normal mb-14 text-white-300">
                Smart Invoice Processing
              </p>
              <h2 className="text-[28px] font-bold mb-4">
                Create Professional Invoices to Send to Your Customers
              </h2>
              <p className="text-base leading-relaxed ">
                Streamline your billing process with
              </p>
              <p className="text-md leading-relaxed ">
                professional, easy-to-use templates.
              </p>
            </div>
          </div>

          {/* Right Section (1/4 width) */}
          <div
            className="w-2/5 flex justify-center items-center mt-[50px]"
            style={{ zIndex: 999 }}
          >
            <div className="w-full max-w-sm px-8 py-12">
              {/* <div className="text-center mb-8">
              {/* <div className="w-24 h-24 bg-gray-200 rounded-full m-auto flex items-center justify-center">
          <PersonIcon style={{ fontSize: 50, color: "gray" }} />
      
              </div> */}
              {/* <h2 className="text-2xl font-bold mt-4">Enter OTP </h2>
            </div> */}

              {/* userole selction */}
              <div className="flex flex-col justify-center space x-8">
                <div>
                  <h1 className="text-5xl font-bold m-auto mb-[20px]">
                    <img
                      style={{ width: "300px", height: "150px" }}
                      src={OtpLogo}
                      alt="OtpLogo"
                      className={`${"w-max"}`}
                    />
                  </h1>
                  <h2 className="text-2xl text-gray-600 font-bold mb-4 flex justify-center mb-8">
                    Enter Verification Code{" "}
                  </h2>
                </div>

                <div className="flex space-x-4 ">
                  {otpValues.map((value, index) => (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      type="text"
                      maxLength="1"
                      value={value}
                      autoComplete="off"
                      onPaste={(e) => handlePaste(e)}
                      disabled={isOtpExpired()}
                      onChange={(e) => handleOtpChange(e.target.value, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      className={`w-10 p-2 border-0 border-b-2 border-gray-400 text-center text-2xl focus:outline-none focus:ring-0 focus:border-blue-500 ${
                        isOtpExpired() ? "bg-gray-100" : ""
                      }`}
                    />
                  ))}
                </div>
              </div>
              <div className="flex flex-col space-y-4 mb-[100px]">
                <div className="text-center mb-3"></div>
                <button
                  onClick={isOtpExpired() ? handleResendOtp : handleLoginClick}
                  className={`${
                    isOtpExpired()
                      ? "bg-green-400 hover:bg-green-400"
                      : "bg-orange-400 hover:bg-orange-400"
                  } transition transform active:scale-95  text-white
                     py-2 px-8 rounded-lg font-semibold shadow-md m-auto w-full`}
                >
                  {isOtpExpired() ? "RESEND OTP" : "VALIDATE"}
                </button>
                <div className="m-auto mt-3 mb-[80px]">
                  {
                    // <b className="mt-4">Otp is  : {otp}</b>
                  }
                </div>
                <div className="flex justify-center  mt-6 space-x-5">
                  {" "}
                  Please Check Your Phone For OTP &nbsp;{" "}
                  <b>{formatTime(timer)}</b>
                </div>
              </div>
              {otpExpired && (
                <div className="flex justify-center text-red-500 m-auto mb-4">
                  OTP has been expired.
                </div>
              )}
              {errorMessage ? (
                <p className="text-red-500 mt-2 flex justify-center m-auto mb-4 h-[20px]">
                  {errorMessage}
                </p>) :
                (
                  <p className="text-red-500 mt-2 flex justify-center m-auto mb-4 h-[20px]">
                    {""}
                  </p>

              )}

              {/* userrole ends here */}

              <div className="mt-[220px] mt-auto text-center ">
                <div className="flex items-center text-[#26a9e1] text-sm mb-3">
                  <div className="flex-grow border-t border-[#26a9e1]"></div>
                  <span className="px-2 font-bold">Follow Us</span>
                  <div className="flex-grow border-t border-[#26a9e1]"></div>
                </div>

                <div className="flex justify-center space-x-1">
                  <a
                    href="#"
                    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
                  >
                    <i className="fab fa-facebook-f text-[#26a9e1] text-md"></i>
                  </a>
                  <a
                    href="#"
                    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
                  >
                    <i className="fab fa-instagram text-[#26a9e1] text-md"></i>
                  </a>
                  <a
                    href="#"
                    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
                  >
                    <i className="fab fa-twitter text-[#26a9e1] text-md"></i>
                  </a>
                  <a
                    href="#"
                    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
                  >
                    <i className="fab fa-linkedin-in text-[#26a9e1] text-md"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal for switching user */}

      {isModalOpen && (
        <div className="flex h-screen w-screen">
          {/* Left Section (3/4 width) */}
          <div className="w-3/5 bg-gradient-to-br from-[#25caed] to-[#0044AB] flex flex-col justify-center items-center">
            <div className="text-center text-white max-w-[500px] px-8">
              <h1 className="text-5xl font-bold m-auto ml-[50px]">
                <img
                  style={{ width: "300px", height: "80px" }}
                  src={InvoiceFileLogo}
                  alt="InvoiceFile"
                  className={`${"w-max"}`}
                />
              </h1>
              <p className="text-xl font-normal mb-14 text-white-300">
                Smart Invoice Processing
              </p>
              <h2 className="text-[28px] font-bold mb-4">
                Create Professional Invoices to Send to Your Customers
              </h2>
              <p className="text-base leading-relaxed ">
                Streamline your billing process with
              </p>
              <p className="text-md leading-relaxed ">
                professional, easy-to-use templates.
              </p>
            </div>
          </div>

          {/* Right Section (1/4 width) */}
          <div
            className="w-2/5 flex justify-center items-center"
            style={{ zIndex: 999 }}
          >
            <div className="w-full max-w-sm px-8 py-12">
              <div className="text-center mb-8">
                <div className="w-24 h-24 bg-gray-200 rounded-full m-auto flex items-center justify-center">
                  <PersonIcon style={{ fontSize: 50, color: "gray" }} />
                </div>
                <h2 className="text-2xl text-gray-600 font-bold mt-4">
                  Select User Role{" "}
                </h2>
              </div>

              {/* userole selction */}
              {/* Owner Role */}
              <div
                className=" transition transform active:scale-95 cursor-pointer flex items-center w-30 p-1 mb-4 border-2 border-orange-300 rounded-md bg-orange-50"
                onClick={handleLoginAsOwner}
              >
                <div className="w-12 h-12 bg-orange-100 rounded-full flex items-center justify-center">
                  <PersonIcon style={{ fontSize: 30, color: "#F6AD55" }} />
                </div>
                <div className="ml-4">
                  <h2 className="font-bold text-orange-600 text-md">OWNER</h2>
                  <p className="text-sm text-gray-600">
                    Full Access to All Functions
                  </p>
                </div>
              </div>

              {/* User Role */}
              <div
                className="transition transform active:scale-95 cursor-pointer  flex items-center w-30 p-1 border-2 border-green-300 rounded-md bg-green-50"
                onClick={handleLoginAsManager}
              >
                <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center">
                  <PersonIcon style={{ fontSize: 30, color: "#48BB78" }} />
                </div>
                <div className="ml-4">
                  <h2 className="font-bold text-green-600 text-md">USER</h2>
                  <p className="text-sm text-gray-600">
                    Uploading and Invoice Creation
                  </p>
                </div>
              </div>

              {/* userrole ends here */}

              <div className="mt-[270px] text-center ">
                <div className="flex items-center text-[#26a9e1] text-sm mb-3">
                  <div className="flex-grow border-t border-[#26a9e1]"></div>
                  <span className="px-2 font-bold">Follow Us</span>
                  <div className="flex-grow border-t border-[#26a9e1]"></div>
                </div>

                <div className="flex justify-center space-x-1">
                  <a
                    href="#"
                    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
                  >
                    <i className="fab fa-facebook-f text-[#26a9e1] text-md"></i>
                  </a>
                  <a
                    href="#"
                    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
                  >
                    <i className="fab fa-instagram text-[#26a9e1] text-md"></i>
                  </a>
                  <a
                    href="#"
                    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
                  >
                    <i className="fab fa-twitter text-[#26a9e1] text-md"></i>
                  </a>
                  <a
                    href="#"
                    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
                  >
                    <i className="fab fa-linkedin-in text-[#26a9e1] text-md"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OtpPage;
