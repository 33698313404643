// // import React, { useState } from 'react';
// // import DescriptionIcon from '@mui/icons-material/Description';
// // import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
// // import StarIcon from '@mui/icons-material/Star';
// // import BoltIcon from '@mui/icons-material/Bolt';

// // // Dummy data for fuel cards
// // const fuelCardsData = [
// //   {
// //     id: 1,
// //     fuelType: "Regular",
// //     gallons: 11456,
// //     amount: 34324.59,
// //     color: "gray",
// //     icon: "DescriptionIcon"
// //   },
// //   {
// //     id: 2,
// //     fuelType: "Diesel",
// //     gallons: 8752,
// //     amount: 29845.36,
// //     color: "blue",
// //     icon: "LocalGasStationIcon"
// //   },
// //   {
// //     id: 3,
// //     fuelType: "Premium",
// //     gallons: 5324,
// //     amount: 21567.89,
// //     color: "green",
// //     icon: "StarIcon"
// //   },
// //   {
// //     id: 4,
// //     fuelType: "Ultra",
// //     gallons: 3245,
// //     amount: 15234.47,
// //     color: "purple",
// //     icon: "BoltIcon"
// //   }
// // ];

// // const FuelCards = () => {
// //   const [clicked, setClicked] = useState('Regular');
// //   const [filteredStatus, setFilteredStatus] = useState('Regular');

// //   // Function to get the appropriate icon based on fuel type
// //   const getIcon = (iconName, color) => {
// //     switch (iconName) {
// //       case 'DescriptionIcon':
// //         return <DescriptionIcon sx={{ fontSize: 30 }} className={`text-${color}-500 mt-2`} />;
// //       case 'LocalGasStationIcon':
// //         return <LocalGasStationIcon sx={{ fontSize: 30 }} className={`text-${color}-500 mt-2`} />;
// //       case 'StarIcon':
// //         return <StarIcon sx={{ fontSize: 30 }} className={`text-${color}-500 mt-2`} />;
// //       case 'BoltIcon':
// //         return <BoltIcon sx={{ fontSize: 30 }} className={`text-${color}-500 mt-2`} />;
// //       default:
// //         return <DescriptionIcon sx={{ fontSize: 30 }} className={`text-${color}-500 mt-2`} />;
// //     }
// //   };

// //   // Function to format numbers with commas
// //   const formatNumber = (number) => {
// //     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// //   };

// //   // Function to format currency
// //   const formatCurrency = (amount) => {
// //     return new Intl.NumberFormat('en-US', {
// //       style: 'currency',
// //       currency: 'USD',
// //       minimumFractionDigits: 2
// //     }).format(amount);
// //   };

// //   return (
// //     <div className="flex justify-start space-x-6 mt-[20px]">
// //       {fuelCardsData.map((card) => (
// //         <div
// //           key={card.id}
// //           className={`flex flex-col items-center justify-between w-[200px] bg-white rounded-lg p-4 relative hover:scale-105 hover:border-l-[6px] hover:border-${card.color}-500 transition-all duration-300 ${
// //             clicked === card.fuelType ? `border-l-[6px] border-${card.color}-500` : ''
// //           }`}
// //           style={{
// //             boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
// //             height: '90px',
// //           }}
// //           onClick={() => { 
// //             setFilteredStatus(card.fuelType); 
// //             setClicked(card.fuelType); 
// //           }}
// //         >
// //           <div className="flex justify-between w-full">
// //             <span className={`text-sm text-${card.color}-500 font-bold`}>{card.fuelType}</span>
// //           </div>
// //           <div className="flex flex-col">
// //             <h2 className={`absolute bottom-2 left-4 text-2xl font-bold text-${card.color}-500 mt-2`}>
// //               {formatNumber(card.gallons)} gl
// //             </h2>
// //             <span className="text-xs text-gray-400 absolute bottom-8 left-4">
// //               {formatCurrency(card.amount)}
// //             </span>
// //           </div>
// //           <div className="absolute bottom-0 right-0 w-[100px] h-[55px] bg-gradient-to-tl from-[#ffffff] to-transparent rounded-tl-full flex items-center justify-center">
// //             {getIcon(card.icon, card.color)}
// //           </div>
// //         </div>
// //       ))}
// //     </div>
// //   );
// // };

// // export default FuelCards;



// import React, { useState, useEffect } from 'react';
// import DescriptionIcon from '@mui/icons-material/Description';
// import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
// import StarIcon from '@mui/icons-material/Star';
// import BoltIcon from '@mui/icons-material/Bolt';
// import TruckIcon from '../../src/assets/images/TruckIcon.png';
// // Fuel card data
// const fuelCardsData = [
//   {
//     id: 1,
//     fuelType: "Regular",
//     gallons: 11456,
//     amount: 34324.59,
//     color: "gray",
//     icon: "TruckIcon"
//   },
//   {
//     id: 2,
//     fuelType: "Diesel",
//     gallons: 8752,
//     amount: 29845.36,
//     color: "blue",
//     icon: "LocalGasStationIcon"
//   },
//   {
//     id: 3,
//     fuelType: "Premium",
//     gallons: 5324,
//     amount: 21567.89,
//     color: "green",
//     icon: "StarIcon"
//   },
//   {
//     id: 4,
//     fuelType: "Ultra",
//     gallons: 3245,
//     amount: 15234.47,
//     color: "purple",
//     icon: "BoltIcon"
//   }
// ];

// const FuelCards = ({apiData}) => {
//   console.log(apiData, 'apidata')
//   const convertApiDataToFuelCards = (apiData) => {
//     if (!apiData || !Array.isArray(apiData)) {
//       return [];
//     }
  
//     // Icon and color mapping for different fuel types
//     const fuelTypeMapping = {
//       "Regular": { color: "gray", icon: "TruckIcon" },
//       "Premium": { color: "green", icon: "StarIcon" },
//       "Diesel": { color: "blue", icon: "LocalGasStationIcon" },
//       "Ultra": { color: "purple", icon: "BoltIcon" }
//     };
  
//     return apiData.map((item, index) => {
//       const fuelType = item.product_name;
//       const mapping = fuelTypeMapping[fuelType] || { color: "gray", icon: "DescriptionIcon" };
      
//       return {
//         id: index + 1,
//         fuelType: fuelType,
//         gallons: parseInt(item.total_gallons, 10),
//         amount: parseFloat(item.total_amount),
//         color: mapping.color,
//         icon: mapping.icon
//       };
//     });
//   };
//   const [clicked, setClicked] = useState('Regular');
//   const [fuelCardsData, setFuelCardsData] = useState([]);

//   // Process API data when it changes
//   useEffect(() => {
//     if (apiData && apiData.gasTotalsInvdashboard) {
//       const formattedData = convertApiDataToFuelCards(apiData);
//       setFuelCardsData(formattedData);
      
//       // Set first fuel type as clicked by default if data exists
//       // if (formattedData.length > 0 && !clicked) {
//       //   setClicked(formattedData[0].fuelType);
//       // }
//     }
//   }, [apiData]);
//   // Function to return appropriate icon
//   const getIcon = (iconName, color) => {
//     switch (iconName) {
//       case 'TruckIcon':
//         return <img src={TruckIcon} width={70} height={40} sx={{ fontSize: 50 }} className={`text-${color}-500`} />;
//       case 'LocalGasStationIcon':
//         return <LocalGasStationIcon sx={{ fontSize: 30 }} className={`text-${color}-500`} />;
//       case 'StarIcon':
//         return <StarIcon sx={{ fontSize: 30 }} className={`text-${color}-500`} />;
//       case 'BoltIcon':
//         return <BoltIcon sx={{ fontSize: 30 }} className={`text-${color}-500`} />;
//       default:
//         return <DescriptionIcon sx={{ fontSize: 30 }} className={`text-${color}-500`} />;
//     }
//   };

//   // Format numbers with commas
//   const formatNumber = (number) => number.toLocaleString();

//   // Format currency as USD with space after $ symbol
//   const formatCurrency = (amount) => {
//     const formatter = new Intl.NumberFormat('en-US', {
//       style: 'currency',
//       currency: 'USD',
//       minimumFractionDigits: 2
//     });
    
//     return formatter.format(amount).replace('$', '$ ');
//   };

//   // If no data, show loading or empty state
//   if (!fuelCardsData || fuelCardsData.length === 0) {
//     return <div className="flex justify-start gap-4">Loading fuel data...</div>;
//   }
//   return (
//     <div className="flex justify-start gap-4">
//     {fuelCardsData.map((card) => (
//       <div
//         key={card.id}
//         className={`relative flex flex-col w-[210px] bg-white rounded-lg p-4 transition-all duration-300 
//                     shadow-md hover:scale-105 hover:border-l-[6px] hover:border-${card.color}-500
//                     ${clicked === card.fuelType ? `border-l-[6px] border-${card.color}-500` : ''}`}
//         style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', height: '100px' }}
//         onClick={() => setClicked(card.fuelType)}
//       >
//         {/* Fuel Type Header */}
//         <div className="flex items-center justify-between">
//           <span className={`text-sm font-semibold text-${card.color}-500`}>{card.fuelType}</span>
//           <h2 className={`text-sm font-bold text-${card.color}-500 mt-1`}>
//             {formatNumber(card.gallons)} gl
//           </h2>
//         </div>
        
//         <div className="flex items-center justify-between">
//           {/* Icon Container - Now on the left */}
//           <div className="w-[110px] h-[70px] flex items-center justify-start mr-2">
//             {getIcon(card.icon, card.color)}
//           </div>

//           {/* Gallons & Amount Section - Now on the right */}
//           <div className="mt-1 text-right">
//             <span className="text-gray-400 text-sm font-bold gap-3" style={{whiteSpace:'nowrap'}}>
//               {formatCurrency(card.amount)}
//             </span>
//           </div>
//         </div>
//       </div>
//     ))}
//   </div>
//   );
// };

// export default FuelCards;

import React, { useState, useEffect } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import StarIcon from '@mui/icons-material/Star';
import BoltIcon from '@mui/icons-material/Bolt';
import TruckIcon from '../../src/assets/images/TruckIcon.png';

const FuelCards = ({ apiData }) => {
  const [clicked, setClicked] = useState('Regular');
  
  // Create a static structure for the three fuel types we always want to show
  const staticFuelTypes = [
    { id: 1, fuelType: "Regular", color: "gray", icon: "TruckIcon" },
    { id: 2, fuelType: "Premium", color: "green", icon: "StarIcon" },
    { id: 3, fuelType: "Diesel", color: "blue", icon: "LocalGasStationIcon" }
  ];
  
  // State to store just the values from API data
  const [fuelValues, setFuelValues] = useState({
    Regular: { gallons: 0, amount: 0 },
    Premium: { gallons: 0, amount: 0 },
    Diesel: { gallons: 0, amount: 0 }
  });

  // Process API data when it changes
  useEffect(() => {
    if (apiData && Array.isArray(apiData)) {
      // Create a new values object
      const newValues = {...fuelValues};
      
      // Update values for each fuel type found in the API data
      apiData.forEach(item => {
        const fuelType = item.product_name;
        if (newValues[fuelType]) {
          newValues[fuelType] = {
            gallons: parseInt(item.total_gallons, 10),
            amount: parseFloat(item.total_amount)
          };
        }
      });
      
      // Set the new values
      setFuelValues(newValues);
    }
  }, [apiData]);

  // Function to return appropriate icon
  const getIcon = (iconName, color) => {
    switch (iconName) {
      case 'TruckIcon':
        return <img src={TruckIcon} width={70} height={40} sx={{ fontSize: 50 }} className={`text-${color}-500`} />;
      case 'LocalGasStationIcon':
        return <LocalGasStationIcon sx={{ fontSize: 30 }} className={`text-${color}-500`} />;
      case 'StarIcon':
        return <StarIcon sx={{ fontSize: 30 }} className={`text-${color}-500`} />;
      case 'BoltIcon':
        return <BoltIcon sx={{ fontSize: 30 }} className={`text-${color}-500`} />;
      default:
        return <DescriptionIcon sx={{ fontSize: 30 }} className={`text-${color}-500`} />;
    }
  };

  // Format numbers with commas
  const formatNumber = (number) => number.toLocaleString();

  // Format currency as USD with space after $ symbol
  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    
    return formatter.format(amount).replace('$', '$ ');
  };

  return (
    <div className="flex justify-start gap-4">
      {staticFuelTypes.map((card) => (
        <div
          key={card.id}
          className={`relative flex flex-col w-[210px] bg-white rounded-lg p-4 transition-all duration-300 
                      shadow-md hover:scale-105 hover:border-l-[6px] hover:border-${card.color}-500
                      ${clicked === card.fuelType ? `border-l-[6px] border-${card.color}-500` : ''}`}
          style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', height: '100px' }}
          onClick={() => setClicked(card.fuelType)}
        >
          {/* Fuel Type Header */}
          <div className="flex items-center justify-between">
            <span className={`text-sm font-semibold text-${card.color}-500`}>{card.fuelType}</span>
            <h2 className={`text-sm font-bold text-${card.color}-500 mt-1`}>
              {formatNumber(fuelValues[card.fuelType]?.gallons || 0)} gl
            </h2>
          </div>
          
          <div className="flex items-center justify-between">
            {/* Icon Container - Now on the left */}
            <div className="w-[110px] h-[70px] flex items-center justify-start mr-2">
              {getIcon(card.icon, card.color)}
            </div>

            {/* Gallons & Amount Section - Now on the right */}
            <div className="mt-1 text-right">
              <span className="text-gray-400 text-sm font-bold gap-3" style={{whiteSpace:'nowrap'}}>
                {formatCurrency(fuelValues[card.fuelType]?.amount || 0)}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FuelCards;
