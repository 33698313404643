import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import config from "../../config/config";
import { deleteVendor } from "../../store/vendorSlice";
import { showToastForDeleteVendor } from "../toasts/toastForVendor";
import FullScreenLoader from './FullScreenLoader'
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import { Navigate, json, useNavigate } from "react-router-dom";

export default function BusinessDeleteModal({ onClose, businessId , business_name , resetRowSelection, onDeleteSuccess }) {
  const [loading, setLoading] = useState(false); // Add a loading state

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const businessId = localStorage.getItem("business_id")
    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
    const [openModal, setOpenModal] = useState(true);
const ownerId = localStorage.getItem('user_id')
  const handleDelete = async() => {
    setLoading(true)
    const encodeId = btoa(businessId)
    try{
        const response = await axios.delete(`${baseURL}${("business/deletebusiness")}/${(businessId)}`);
        console.log("delete vendor" , response)
        if (response.data && response.data.deleteStatus === true) {
            onDeleteSuccess(businessId);

            window.scroll(0,0)
            onClose();
            // resetRowSelection();
            dispatch(addToast({ type: 'danger', message: `${business_name} deleted successfully` }));
            // navigate('/vendors')        

        } else {
            console.error("Failed to delete the business on the server.");
            dispatch(addToast({ type: 'danger', message: `Failed to delete the business on the server.` }));

            // navigate('/vendors')  
        }
    }catch(error){
        console.log("error deleting business" , error);
        dispatch(addToast({ type: 'danger', message: `error deleting business` }));
        // navigate('/vendors')  
    } finally{
      setLoading(false)
    //   navigate('/vendors')
    }
  };

  return (
    <Modal show={openModal} size="md" popup onClose={onClose}>
                        {loading && <FullScreenLoader/>}

      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400 mt-3">
            Are you sure you want to delete
            <span className="block uppercase p-2 font-semibold text-red-700 underline underline-offset-8">" {business_name} "</span>
          </h3>
          <div className="flex justify-center gap-4">
            <Button color="failure" onClick={handleDelete}>
              Yes, I'm sure
            </Button>
            <Button color="gray" onClick={onClose}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
