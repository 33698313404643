

// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   FaChevronLeft,
//   FaChevronRight,
//   FaHome,
//   FaPen,
// } from "react-icons/fa";
// import { DatePicker, Space } from "antd";
// import TextField from "@mui/material/TextField";
// import axios from "axios";
// import config from "../../config/config";
// import dayjs from "dayjs";
// import { addCustomer } from "../../store/customerSlice";
// import { useSelector, useDispatch } from "react-redux";
// import { addToast } from "./ToastBox/ToastBoxMessageSlice";
// import CustomNavigation from "../reusable/CustomNavigationIcon";
// import { useLocation } from "react-router-dom";
// import CustomCommonTab from "../reusable/CustomCommonTab";
// import { setNavigationState } from '../../components/Navigation/navigationSlice';

// const DeliveryPrice = ({ }) => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const businessDate = localStorage.getItem('business_date');
//   const businessname = location.state?.businessname;
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [ispriceExists, setIspriceExists] = useState(false);
//   const [selectedCustomer, setSelectedCustomer] = useState("");
//   const [isAllCustomers, setIsAllCustomers] = useState(false);
//   const [freightCharge, setFreightCharge] = useState("");
//   const [freightSurcharge, setFreightSurcharge] = useState("");
//   const [deliveryChargesData, setDeliveryChargesData] = useState({});
//   const [activeItem, setActiveItem] = useState("View");
//   const [editRows, setEditRows] = useState([]);
//   const [updatedPrices, setUpdatedPrices] = useState({});
//   const [responseDate, setResponseDate] = useState(null);
//   const [isInEditMode, setIsInEditMode] = useState(false);

//   const customersAll = useSelector((state) => state.customer.allCustomers);
//   const businessId = localStorage.getItem("business_id");
//   const baseURL =
//     process.env.NODE_ENV === "production"
//       ? config.production.baseURL
//       : config.development.baseURL;

//   // Format number with 2 decimal places
//   const formatNumber = (value) => {
//     if (!value && value !== 0) return "0.00";
  
//     // Remove any existing commas first
//     const cleanValue = typeof value === 'string' ? value.replace(/,/g, '') : value.toString();
    
//     // Convert to number
//     const numValue = parseFloat(cleanValue);
    
//     // Check if it's a valid number
//     if (isNaN(numValue)) return "0.00";
    
//     // Format with 2 decimal places and add commas for thousands
//     return numValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//   };

//   const handlePriceChange = (field, value) => {
//     // First, remove any existing commas to work with a clean number
//     const cleanValue = value.replace(/,/g, '');
    
//     // Check if the input is empty or a valid number format with up to 5 decimal places
//     if (cleanValue === '' || /^\d*\.?\d{0,5}$/.test(cleanValue)) {
//       // Ensure it's not negative (for paste operations)
//       if (cleanValue === '' || parseFloat(cleanValue) >= 0 || isNaN(parseFloat(cleanValue))) {
//         // Format the number with commas for thousands
//         let formattedValue = cleanValue;
        
//         if (cleanValue !== '' && cleanValue !== '.') {
//           // Split the number into integer and decimal parts
//           const parts = cleanValue.split('.');
          
//           // Format the integer part with commas
//           parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          
//           // Rejoin with decimal part if it exists
//           formattedValue = parts.length > 1 ? `${parts[0]}.${parts[1]}` : parts[0];
//         }
        
//         // Update the state with the formatted value
//         setUpdatedPrices((prev) => ({
//           ...prev,
//           [field]: formattedValue,
//         }));
  
//         // Update the main state variables
//         if (field === 'freightCharge') {
//           setFreightCharge(formattedValue);
//         } else if (field === 'freightSurcharge') {
//           setFreightSurcharge(formattedValue);
//         }
//       }
//     }
//   };
//   const parseFormattedNumber = (formattedValue) => {
//     if (!formattedValue) return 0;
    
//     // Remove commas and convert to float
//     return parseFloat(formattedValue.replace(/,/g, ''));
//   };
//   const isSubmitDisabled = () => {
//     // Parse the formatted values to check if they're valid
//     const freightChargeValue = parseFormattedNumber(freightCharge);
//     const freightSurchargeValue = parseFormattedNumber(freightSurcharge);
    
//     // Check if either field is empty or negative
//     const isFreightChargeValid = freightCharge !== "" && 
//                                 !isNaN(freightChargeValue) && 
//                                 freightChargeValue >= 0;
    
//     const isFreightSurchargeValid = freightSurcharge !== "" && 
//                                    !isNaN(freightSurchargeValue) && 
//                                    freightSurchargeValue >= 0;
    
//     return isSubmitted || !isFreightChargeValid || !isFreightSurchargeValid;
//   };
//   const handleEditClick = (field) => {
//     // Add the field to editRows but don't change the overall edit mode state
//     setEditRows((prevRows) => {
//       if (prevRows.includes(field)) {
//         return prevRows.filter((id) => id !== field); // Remove from edit mode
//       } else {
//         return [...prevRows, field]; // Add to edit mode
//       }
//     });

//     // Reset submission state when editing begins
//     if (isSubmitted) {
//       // setIsSubmitted(false);
//     }
//   };

//   const today = dayjs();
//   const [selectedDate, setSelectedDate] = useState(today);
//   const todayDate = dayjs().format("YYYY-MM-DD");

//   const formattedDate = selectedDate
//     ? selectedDate.toDate().toLocaleDateString("en-CA")
//     : null;
    
//   // Check if the response date is today
//   const isResponseDateToday = responseDate ? dayjs(responseDate).format("YYYY-MM-DD") === todayDate : false;
//   console.log(isResponseDateToday, 'isResponseDateToday')

//   // Set All Customers as default on component mount
//   useEffect(() => {
//     setIsAllCustomers(true);
//   }, []);

//   // Fetch customers and save to Redux store
//   useEffect(() => {
//     window.scrollTo(0, 0);
//     axios
//       .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
//       .then((response) => {
//         const sortedCustomerData = response.data.customers_data.sort(
//           (a, b) => b.customer_id - a.customer_id
//         );
//         dispatch(addCustomer(sortedCustomerData));
//       })
//       .catch((error) => {
//         console.error("Error fetching customer data:", error);
//       });
//   }, []);

//   const menuItems = [{ name: "Delivery Price" }];

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const requestData = {
//           businessId: localStorage.getItem("business_id"),
//           chargesDate: selectedDate.format("YYYY-MM-DD"),
//           customerId: selectedCustomer ? selectedCustomer : 'allCustomers'
//         };

//         const response = await axios.post(
//           `${baseURL}${btoa("deliverycharges/olddeliveryprice")}`,
//           requestData,
//           { headers: { "Content-Type": "application/json" } }
//         );

//         if (response.data) {
//           setDeliveryChargesData(response.data);
//           setIspriceExists(response.data.priceExistStatus || false);
          
//           if (response.data.date) {
//             setResponseDate(response.data.oldChargesData[0].added_on);
//           }

//           if (response.data.oldChargesData && response.data.oldChargesData.length > 0) {
//             const chargeData = response.data.oldChargesData[0];
//             setFreightCharge(chargeData.freight_charge || "");
//             setFreightSurcharge(chargeData.freight_surcharge || "");
//           } else {
//             setFreightCharge("");
//             setFreightSurcharge("");
//           }
//         }

//         // Reset states on new data fetch
//         setUpdatedPrices({});
//         setIsInEditMode(false);
//         setEditRows([]);
//         // setIsSubmitted(false);
//       } catch (error) {
//         console.error("Error fetching delivery charges:", error);
//         setFreightCharge("");
//         setFreightSurcharge("");
//       }
//     };

//     fetchData();
//   }, [selectedDate, selectedCustomer, isAllCustomers]);

// const handleSubmit = async () => {
//   // Parse the formatted values to get clean numbers for API submission
//   const parsedFreightCharge = parseFormattedNumber(freightCharge);
//   const parsedFreightSurcharge = parseFormattedNumber(freightSurcharge);

//   // Preparing the request data with parsed values
//   const requestData = {
//     businessId: localStorage.getItem("business_id"),
//     chargesDate: formattedDate,
//     freightCharge: parsedFreightCharge,
//     freightSurcharge: parsedFreightSurcharge,
//     loggedInUserId: localStorage.getItem("user_id"),
//     userRole: localStorage.getItem("user_role"),
//     buttonOne: isAllCustomers ? "allCustomers" : null,
//     buttonTwo: selectedCustomer,
//   };

//   try {
//     const response = await axios.post(
//       `${baseURL}${btoa("deliverycharges/create")}`,
//       requestData,
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     // Handle the response
//     if (response.status === 200) {
//       // Update ispriceExists to true after successful submission
//       setIsSubmitted(true);
//       setIsInEditMode(false);
//       setEditRows([]);

//       dispatch(
//         addToast({
//           type: "success",
//           message: `${editRows.length > 0 ? 'Delivery prices updated successfully' : response.data.message}`
//         })
//       );
//     } else {
//       console.error("Failed to submit:", response.statusText);
//     }
//   } catch (error) {
//     console.error("Error during API request:", error);
//   }
// };

//   const handleNotifyCustomers = () => {
//     const navigationData = {
//       fromDeliveryPrice: true, businessNamefromdeliveryprice: businessname
//     };
//     navigate("/HomeNotifications");
//     dispatch(setNavigationState(navigationData));

//   };

//   const handleNoNotification = () => {
//     navigate('/deliveryPriceDashBoard');
//   };

//   const handleDateChange = (date) => {
//     if (date) {
//       setSelectedDate(date);
//     }
//   };

//   const handlePrevDay = () => {
//     setSelectedDate((prevDate) => {
//       // Convert businessDate to dayjs object if it's not already
//       const businessDateObj = typeof businessDate === 'string'
//         ? dayjs(businessDate)
//         : dayjs(businessDate);

//       // Check if going back one day would make the date earlier than businessDate
//       if (prevDate.subtract(1, 'day').isBefore(businessDateObj, 'day')) {
//         return businessDateObj; // Don't go earlier than businessDate
//       }
//       return prevDate.subtract(1, 'day'); // Otherwise subtract one day as normal
//     });
//   };

//   const disabledDate = (current) => {
//     // Convert businessDate to dayjs object if it's not already
//     const businessDateObj = typeof businessDate === 'string' 
//       ? dayjs(businessDate) 
//       : dayjs(businessDate);
    
//     // Disable dates before businessDate or after today
//     return (
//       current.isBefore(businessDateObj, 'day') || 
//       current.isAfter(today, 'day')
//     );
//   };

//   const handleNextDay = () => {
//     setSelectedDate((prevDate) => {
//       if (prevDate.isBefore(today, "day")) {
//         return prevDate.add(1, "day");
//       }
//       return prevDate; // Prevent exceeding today's date
//     });
//   };

//   const handleAllCustomersClick = () => {
//     setIsAllCustomers(true);
//     setSelectedCustomer(""); // Clear dropdown selection
//   };

//   const handleDropdownChange = (e) => {
//     setSelectedCustomer(e.target.value);
//     setIsAllCustomers(false); // Unselect "All Customers" if dropdown is used
//   };

//   const handleMenuClick = (item) => {
//     setActiveItem(item);
//   };

//   // Determine if a field is being edited
//   const isFieldEditing = (field) => {
//     return editRows.includes(field);
//   };

//   return (
//     <>
//       <div className="flex w-full px-8 ">
//         <div className="flex-1 flex justify-start items-center ">
//           <nav aria-label="breadcrumb " style={{visibility: "hidden"}}>
//             <ol className="flex space-x-2 text-md font-bold text-gray-700 mb-6">
//               <li>Delivery Price</li>
//               <li>
//                 <span className="text-gray-400">{">>"}</span>
//               </li>
//               <li>Create Delivery Price</li>
//             </ol>
//           </nav>
//         </div>

//         {/* Center Aligned Custom Tab Component */}
//         <div className="flex-1 flex justify-center">
//           <CustomCommonTab items={menuItems} />
//         </div>

//         {/* Placeholder for Right-Aligned Section */}
//         <div className="flex-1 flex justify-end">
//           {/* Add any right-aligned content here if needed */}
//         </div>
//       </div>

//       <div
//         className="relative max-w-4xl mx-auto p-8 bg-white mt-[20px] rounded-lg shadow-lg"
//         style={{
//           boxShadow: "0 4px 24px 0 rgba(0, 0, 0, 0.12)",
//         }}
//       >
//        <div className="flex items-center justify-end">
//       {/* Home button - bordered/unfilled by default, filled blue on hover */}
//       <div
//         className="absolute -top-5 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-0 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
//         onClick={() => {
//           navigate("/");
//         }}
//         style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
//       >
//         <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16}  />
//       </div>

//       {/* Close Icon - bordered/unfilled by default, filled red on hover */}
//       <div
//         className="absolute -top-5 -right-4 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
//         onClick={() => navigate('/deliveryPriceDashBoard')}
//       >
//         <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1 transition-colors duration-200">&times;</span>
//       </div>
//     </div>

//         <div className="flex justify-evenly items-center bg-white mt-[40px]">
//           <div className="flex items-center justify-center">
//             <button className="flex items-center">
//               <FaChevronLeft className="mr-2" onClick={handlePrevDay} />
//             </button>
//             <div>
//               <Space direction="vertical" style={{ height: "100%" }}>
//                 <DatePicker
//                   label="date"
//                   disabled={isSubmitted}
//                   disabledDate={disabledDate}
//                   format="MM-DD-YYYY"
//                   views={["year", "month", "day"]}
//                   value={selectedDate}
//                   onChange={handleDateChange}
//                   className="w-[170px] h-[30px]"
//                   placeholder="Date"
//                   renderInput={(params) => (
//                     <TextField
//                       {...params}
//                       sx={{
//                         "& .MuiOutlinedInput-root": {
//                           height: "32px",
//                           display: "flex",
//                           alignItems: "center",
//                           fontSize: "1rem",
//                           borderRadius: "6px",
//                           "&.Mui-focused": {
//                             borderColor: "blue",
//                             boxShadow: "0 0 8px rgba(0, 0, 255, 0.5)",
//                           },
//                         },
//                       }}
//                     />
//                   )}
//                 />
//               </Space>
//             </div>
//             <button className="flex items-center">
//               <FaChevronRight className="ml-2" onClick={handleNextDay} />
//             </button>
//           </div>
//           <button
//             disabled={isSubmitted}
//             className={`px-4 py-0.5 border rounded-lg ${isAllCustomers
//               ? `bg-blue-500 border-blue-500 w-[170px] ${isSubmitted ? 'text-gray-600' : 'text-white'}`
//               : "hover:bg-gray-200 border-gray-500 text-black-500 w-[170px]"
//               } ${isSubmitted ? 'bg-gray-100 border-gray-500 text-black-500 w-[170px]' : 'w-[170px]'}`}
//             onClick={handleAllCustomersClick}
//           >
//             All Customers
//           </button>

//           <div className="flex flex-col mx-4">
//             <select
//               id="customer-select"
//               className={`h-[30px] py-0.5 rounded-lg border-grey-500 w-[170px] ${isSubmitted ? 'bg-gray-100 text-gray-600' : ''}`}
//               value={selectedCustomer}
//               onChange={handleDropdownChange}
//               disabled={isSubmitted}
//             >
//               <option value="">Select Customer</option>
//               {customersAll.map((customer) => (
//                 <option key={customer.customer_id} value={customer.customer_id}>
//                   {customer.business_name}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>

//         <div className="mt-8">
//           <div className="bg-white rounded-lg flex flex-col gap-4 p-4"
//             style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
//           >
//             {/* Freight Charge Field */}
//             {/* <div className="flex justify-center items-center mb-4 p-2 rounded gap-4">
//               <div className="font-semibold w-[150px]">Freight Charge</div>
//               <div className="flex items-center">
//                 {!ispriceExists ? (
//                   <>
//                     {dayjs(selectedDate).format("YYYY-MM-DD") === todayDate && !isResponseDateToday && !isSubmitted ? (
//                       <input
//                         type="number"
//                         value={freightCharge}
//                         onChange={(e) => handlePriceChange('freightCharge', e.target.value)}
//                         className="border p-2 rounded h-[30px] w-[130px] text-right"
//                         placeholder="0.00"
//                         step="0.01"
//                       />
//                     ) : editRows.includes('freightCharge') ? (
//                       <input
//                         type="number"
//                         value={freightCharge}
//                         onChange={(e) => handlePriceChange('freightCharge', e.target.value)}
//                         className="border p-2 rounded h-[30px] w-[130px] text-right"
//                         placeholder="0.00"
//                         step="0.01"
//                       />
//                     ) : (
//                       <div className="border p-2 rounded h-[30px] w-[130px] flex items-center justify-end">
//                         {formatNumber(freightCharge) || "0.00"}
//                       </div>
//                     )}
                    
//                     {isResponseDateToday && !isSubmitted && (
//                       <button
//                         className="text-blue-500 hover:text-blue-700 ml-3"
//                         onClick={() => handleEditClick('freightCharge')}
//                       >
//                         <FaPen size={14} />
//                       </button>
//                     )}
//                   </>
//                 ) : (
//                   <div className="border p-2 rounded h-[30px] w-[130px] bg-gray-200 flex items-center justify-end">
//                     {formatNumber(freightCharge) || "0.00"}
//                   </div>
//                 )}
//               </div>
//             </div> */}
// <div className="flex justify-center items-center mb-4 p-2 rounded gap-4">
//   <div className="font-semibold w-[150px]">Freight Charge</div>
//   <div className="flex items-center">
//     {!ispriceExists ? (
//       // When price doesn't exist
//       <>
//         {dayjs(selectedDate).format("YYYY-MM-DD") === todayDate && !isResponseDateToday && !isSubmitted ? (
//           // For brand new today's date (no existing data) - input is enabled by default for new creation
//           <input
//             type="text" // Using text type for better formatting control
//             value={freightCharge}
//             onChange={(e) => handlePriceChange('freightCharge', e.target.value)}
//             className="border p-2 rounded h-[30px] w-[130px] text-right"
//             placeholder="0.00"
//           />
//         ) : editRows.includes('freightCharge') ? (
//           // Edit mode for existing data on today's date
//           <input
//             type="text" // Using text type for better formatting control
//             value={freightCharge}
//             onChange={(e) => handlePriceChange('freightCharge', e.target.value)}
//             className="border p-2 rounded h-[30px] w-[130px] text-right"
//             placeholder="0.00"
//           />
//         ) : (
//           // Display mode - just show the value for past dates or after submission
//           <div className="border p-2 rounded h-[30px] w-[130px] flex items-center justify-end">
//             {formatNumber(freightCharge)}
//           </div>
//         )}
        
//         {/* Only show edit icon when appropriate */}
//         {isResponseDateToday && !isSubmitted && (
//           <button
//             className="text-blue-500 hover:text-blue-700 ml-3"
//             onClick={() => handleEditClick('freightCharge')}
//           >
//             <FaPen size={14} />
//           </button>
//         )}
//       </>
//     ) : (
//       // When price exists - just show the value with no edit option
//       <div className="border p-2 rounded h-[30px] w-[130px] bg-gray-200 flex items-center justify-end">
//         {formatNumber(freightCharge)}
//       </div>
//     )}
//   </div>
// </div>

//             {/* Freight Surcharge Field */}
//             {/* <div className="flex justify-center items-center p-2 rounded gap-4">
//               <div className="font-semibold w-[150px]">Freight Surcharge</div>
//               <div className="flex items-center">
//                 {!ispriceExists ? (
//                   <>
//                     {dayjs(selectedDate).format("YYYY-MM-DD") === todayDate && !isResponseDateToday && !isSubmitted ? (
//                       <input
//                         type="number"
//                         value={freightSurcharge}
//                         onChange={(e) => handlePriceChange('freightSurcharge', e.target.value)}
//                         className="border p-2 rounded h-[30px] w-[130px] text-right text-right"
//                         placeholder="0.00"
//                         step="0.01"
//                       />
//                     ) : editRows.includes('freightSurcharge') ? (
//                       <input
//                         type="number"
//                         value={freightSurcharge}
//                         onChange={(e) => handlePriceChange('freightSurcharge', e.target.value)}
//                         className="border p-2 rounded h-[30px] w-[130px] text-right"
//                         placeholder="0.00"
//                         step="0.01"
//                       />
//                     ) : (
//                       <div className="border p-2 rounded h-[30px] w-[130px] flex items-center justify-end">
//                         {formatNumber(freightSurcharge) || "0.00"}
//                       </div>
//                     )}
                    
//                     {isResponseDateToday && !isSubmitted && (
//                       <button
//                         className="text-blue-500 hover:text-blue-700 ml-3"
//                         onClick={() => handleEditClick('freightSurcharge')}
//                       >
//                         <FaPen size={14} />
//                       </button>
//                     )}
//                   </>
//                 ) : (
//                   <div className="border p-2 rounded h-[30px] w-[130px] bg-gray-200 flex items-center justify-end">
//                     {formatNumber(freightSurcharge) || "0.00"}
//                   </div>
//                 )}
//               </div>
//             </div> */}
//  <div className="flex justify-center items-center p-2 rounded gap-4">
//   <div className="font-semibold w-[150px]">Freight Surcharge</div>
//   <div className="flex items-center">
//     {!ispriceExists ? (
//       // When price doesn't exist
//       <>
//         {dayjs(selectedDate).format("YYYY-MM-DD") === todayDate && !isResponseDateToday && !isSubmitted ? (
//           // For brand new today's date (no existing data) - input is enabled by default for new creation
//           <input
//             type="text" // Using text type for better formatting control
//             value={freightSurcharge}
//             onChange={(e) => handlePriceChange('freightSurcharge', e.target.value)}
//             className="border p-2 rounded h-[30px] w-[130px] text-right"
//             placeholder="0.00"
//           />
//         ) : editRows.includes('freightSurcharge') ? (
//           // Edit mode for existing data on today's date
//           <input
//             type="text" // Using text type for better formatting control
//             value={freightSurcharge}
//             onChange={(e) => handlePriceChange('freightSurcharge', e.target.value)}
//             className="border p-2 rounded h-[30px] w-[130px] text-right"
//             placeholder="0.00"
//           />
//         ) : (
//           // Display mode - just show the value for past dates or after submission
//           <div className="border p-2 rounded h-[30px] w-[130px] flex items-center justify-end">
//             {formatNumber(freightSurcharge)}
//           </div>
//         )}
        
//         {/* Only show edit icon when appropriate */}
//         {isResponseDateToday && !isSubmitted && (
//           <button
//             className="text-blue-500 hover:text-blue-700 ml-3"
//             onClick={() => handleEditClick('freightSurcharge')}
//           >
//             <FaPen size={14} />
//           </button>
//         )}
//       </>
//     ) : (
//       // When price exists - just show the value with no edit option
//       <div className="border p-2 rounded h-[30px] w-[130px] bg-gray-200 flex items-center justify-end">
//         {formatNumber(freightSurcharge)}
//       </div>
//     )}
//   </div>
// </div>
//           </div>
          
//           {dayjs(selectedDate).format("YYYY-MM-DD") === todayDate &&
//           <div className="flex justify-center mt-6 space-x-3 mt-[20px]">
//             {!ispriceExists && (
//               <>
//                 {/* <button
//                   onClick={handleSubmit}
//                   disabled={
//                     isSubmitted ||
//                     freightCharge === "" || 
//                     freightSurcharge === "" || 
//                     parseFloat(freightCharge) < 0 || 
//                     parseFloat(freightSurcharge) < 0
//                   }
//                   className={`px-3 py-1 rounded ${
//                     isSubmitted ||
//                     freightCharge === "" || 
//                     freightSurcharge === "" || 
//                     parseFloat(freightCharge) < 0 || 
//                     parseFloat(freightSurcharge) < 0
//                       ? "bg-gray-300 text-black cursor-not-allowed"
//                       : "hover:bg-blue-500 hover:text-white text-blue-600 border border-blue-600"
//                   }`}
//                 >
//                   {isInEditMode || editRows.length > 0 ? "Save" : "Submit"}
//                 </button> */}
// {/* Submit/Save button */}
// <button
//   onClick={handleSubmit}
//   disabled={
//     isSubmitted ||
//     freightCharge === "" || 
//     freightSurcharge === "" || 
//     isNaN(parseFormattedNumber(freightCharge)) ||
//     isNaN(parseFormattedNumber(freightSurcharge)) ||
//     parseFormattedNumber(freightCharge) < 0 || 
//     parseFormattedNumber(freightSurcharge) < 0
//   }
//   className={`px-3 py-1 rounded ${
//     isSubmitted ||
//     freightCharge === "" || 
//     freightSurcharge === "" || 
//     isNaN(parseFormattedNumber(freightCharge)) ||
//     isNaN(parseFormattedNumber(freightSurcharge)) ||
//     parseFormattedNumber(freightCharge) < 0 || 
//     parseFormattedNumber(freightSurcharge) < 0
//       ? "bg-gray-300 text-black cursor-not-allowed"
//       : "hover:bg-blue-500 hover:text-white text-blue-600 border border-blue-600"
//   }`}
// >
//   {isInEditMode || editRows.length > 0 ? "Save" : "Submit"}
// </button>
//                 <>
//                   <button
//                     onClick={handleNotifyCustomers}
//                     disabled={!isSubmitted}
//                     className={`px-3 py-1 rounded ${
//                       isSubmitted
//                         ? "hover:bg-green-500 hover:text-white text-green-600 border border-green-600"
//                         : "bg-gray-300 text-black cursor-not-allowed"
//                     }`}
//                   >
//                     Notify Customers
//                   </button>

//                   <button
//                     onClick={handleNoNotification}
//                     disabled={!isSubmitted}
//                     className={`px-3 py-1 rounded ${
//                       isSubmitted
//                         ? "hover:bg-yellow-500 hover:text-white text-yellow-600 border border-yellow-500"
//                         : "bg-gray-300 text-black cursor-not-allowed"
//                     }`}
//                   >
//                     No Notification
//                   </button>
//                 </>
//               </>
//             )}
//           </div>
//           }
//         </div>
//       </div>
//     </>
//   );
// };

// export default DeliveryPrice;


import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaChevronLeft,
  FaChevronRight,
  FaHome,
} from "react-icons/fa";
import { DatePicker, Space } from "antd";
import TextField from "@mui/material/TextField";
import axios from "axios";
import config from "../../config/config";
import dayjs from "dayjs";
import { addCustomer } from "../../store/customerSlice";
import { useSelector, useDispatch } from "react-redux";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import CustomNavigation from "../reusable/CustomNavigationIcon";
import { useLocation } from "react-router-dom";
import CustomCommonTab from "../reusable/CustomCommonTab";
import { setNavigationState } from '../../components/Navigation/navigationSlice';

const DeliveryPrice = ({ }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const businessDate = localStorage.getItem('business_date');
  const businessname = location.state?.businessname;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [ispriceExists, setIspriceExists] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [isAllCustomers, setIsAllCustomers] = useState(false);
  const [freightCharge, setFreightCharge] = useState("");
  const [freightSurcharge, setFreightSurcharge] = useState("");
  const [deliveryChargesData, setDeliveryChargesData] = useState({});
  const [activeItem, setActiveItem] = useState("View");
  const [updatedPrices, setUpdatedPrices] = useState({});
  const [responseDate, setResponseDate] = useState(null);

  const customersAll = useSelector((state) => state.customer.allCustomers);
  const businessId = localStorage.getItem("business_id");
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

  // Format number with 2 decimal places
  const formatNumber = (value) => {
    if (!value && value !== 0) return "0.00";
  
    // Remove any existing commas first
    const cleanValue = typeof value === 'string' ? value.replace(/,/g, '') : value.toString();
    
    // Convert to number
    const numValue = parseFloat(cleanValue);
    
    // Check if it's a valid number
    if (isNaN(numValue)) return "0.00";
    
    // Format with 2 decimal places and add commas for thousands
    return numValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handlePriceChange = (field, value) => {
    // First, remove any existing commas to work with a clean number
    const cleanValue = value.replace(/,/g, '');
    
    // Check if the input is empty or a valid number format with up to 5 decimal places
    if (cleanValue === '' || /^\d*\.?\d{0,5}$/.test(cleanValue)) {
      // Ensure it's not negative (for paste operations)
      if (cleanValue === '' || parseFloat(cleanValue) >= 0 || isNaN(parseFloat(cleanValue))) {
        // Format the number with commas for thousands
        let formattedValue = cleanValue;
        
        if (cleanValue !== '' && cleanValue !== '.') {
          // Split the number into integer and decimal parts
          const parts = cleanValue.split('.');
          
          // Format the integer part with commas
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          
          // Rejoin with decimal part if it exists
          formattedValue = parts.length > 1 ? `${parts[0]}.${parts[1]}` : parts[0];
        }
        
        // Update the state with the formatted value
        setUpdatedPrices((prev) => ({
          ...prev,
          [field]: formattedValue,
        }));
  
        // Update the main state variables
        if (field === 'freightCharge') {
          setFreightCharge(formattedValue);
        } else if (field === 'freightSurcharge') {
          setFreightSurcharge(formattedValue);
        }
      }
    }
  };

  const parseFormattedNumber = (formattedValue) => {
    if (!formattedValue) return 0;
    
    // Remove commas and convert to float
    return parseFloat(formattedValue.replace(/,/g, ''));
  };

  const today = dayjs();
  const [selectedDate, setSelectedDate] = useState(today);
  const todayDate = dayjs().format("YYYY-MM-DD");

  const formattedDate = selectedDate
    ? selectedDate.toDate().toLocaleDateString("en-CA")
    : null;
    
  // Check if the response date is today
  const isResponseDateToday = responseDate ? dayjs(responseDate).format("YYYY-MM-DD") === todayDate : false;

  // Set All Customers as default on component mount
  useEffect(() => {
    setIsAllCustomers(true);
  }, []);

  // Fetch customers and save to Redux store
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
      .then((response) => {
        const sortedCustomerData = response.data.customers_data.sort(
          (a, b) => b.customer_id - a.customer_id
        );
        dispatch(addCustomer(sortedCustomerData));
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);

  const menuItems = [{ name: "Delivery Price" }];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestData = {
          businessId: localStorage.getItem("business_id"),
          chargesDate: selectedDate.format("YYYY-MM-DD"),
          customerId: selectedCustomer ? selectedCustomer : 'allCustomers'
        };

        const response = await axios.post(
          `${baseURL}${btoa("deliverycharges/olddeliveryprice")}`,
          requestData,
          { headers: { "Content-Type": "application/json" } }
        );

        if (response.data) {
          setDeliveryChargesData(response.data);
          setIspriceExists(response.data.priceExistStatus || false);
          
          if (response.data.date) {
            setResponseDate(response.data.oldChargesData[0].added_on);
          }

          if (response.data.oldChargesData && response.data.oldChargesData.length > 0) {
            const chargeData = response.data.oldChargesData[0];
            setFreightCharge(chargeData.freight_charge || "");
            setFreightSurcharge(chargeData.freight_surcharge || "");
          } else {
            setFreightCharge("");
            setFreightSurcharge("");
          }
        }

        // Reset states on new data fetch
        setUpdatedPrices({});
        // Do not reset submission state here to preserve notification buttons state
      } catch (error) {
        console.error("Error fetching delivery charges:", error);
        setFreightCharge("");
        setFreightSurcharge("");
      }
    };

    fetchData();
  }, [selectedDate, selectedCustomer, isAllCustomers]);

  const handleSubmit = async () => {
    // Parse the formatted values to get clean numbers for API submission
    const parsedFreightCharge = parseFormattedNumber(freightCharge);
    const parsedFreightSurcharge = parseFormattedNumber(freightSurcharge);

    // Preparing the request data with parsed values
    const requestData = {
      businessId: localStorage.getItem("business_id"),
      chargesDate: formattedDate,
      freightCharge: parsedFreightCharge,
      freightSurcharge: parsedFreightSurcharge,
      loggedInUserId: localStorage.getItem("user_id"),
      userRole: localStorage.getItem("user_role"),
      buttonOne: isAllCustomers ? "allCustomers" : null,
      buttonTwo: selectedCustomer,
    };

    try {
      const response = await axios.post(
        `${baseURL}${btoa("deliverycharges/create")}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response
      if (response.status === 200) {
        // Update ispriceExists to true after successful submission
        setIsSubmitted(true);

        dispatch(
          addToast({
            type: "success",
            message: isResponseDateToday 
              ? 'Delivery prices updated successfully' 
              : response.data.message
          })
        );
      } else {
        console.error("Failed to submit:", response.statusText);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };
useEffect(()=> {
  const navigationData = {
    fromDeliveryPrice: true, 
    businessNamefromdeliveryprice: businessname
  };
  dispatch(setNavigationState(navigationData));
},[])

  const handleNotifyCustomers = () => {
    const navigationData = {
      fromDeliveryPrice: true, 
      businessNamefromdeliveryprice: businessname
    };
    localStorage.setItem('navigationState', JSON.stringify(navigationData));

    navigate("/HomeNotifications");
    dispatch(setNavigationState(navigationData));
  };

  const handleNoNotification = () => {
    navigate('/deliveryPriceDashBoard');
  };

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  const handlePrevDay = () => {
    setSelectedDate((prevDate) => {
      // Convert businessDate to dayjs object if it's not already
      const businessDateObj = typeof businessDate === 'string'
        ? dayjs(businessDate)
        : dayjs(businessDate);

      // Check if going back one day would make the date earlier than businessDate
      if (prevDate.subtract(1, 'day').isBefore(businessDateObj, 'day')) {
        return businessDateObj; // Don't go earlier than businessDate
      }
      return prevDate.subtract(1, 'day'); // Otherwise subtract one day as normal
    });
  };

  const disabledDate = (current) => {
    // Convert businessDate to dayjs object if it's not already
    const businessDateObj = typeof businessDate === 'string' 
      ? dayjs(businessDate) 
      : dayjs(businessDate);
    
    // Disable dates before businessDate or after today
    return (
      current.isBefore(businessDateObj, 'day') || 
      current.isAfter(today, 'day')
    );
  };

  const handleNextDay = () => {
    setSelectedDate((prevDate) => {
      if (prevDate.isBefore(today, "day")) {
        return prevDate.add(1, "day");
      }
      return prevDate; // Prevent exceeding today's date
    });
  };

  const handleAllCustomersClick = () => {
    setIsAllCustomers(true);
    setSelectedCustomer(""); // Clear dropdown selection
  };

  const handleDropdownChange = (e) => {
    setSelectedCustomer(e.target.value);
    setIsAllCustomers(false); // Unselect "All Customers" if dropdown is used
  };

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };
  
  // Check if today's date is selected
  const isTodaySelected = dayjs(selectedDate).format("YYYY-MM-DD") === todayDate;
  
  // Determine when to show editable fields
  const shouldShowEditableFields = isTodaySelected && !ispriceExists;
  
  // Check if there are updates (to determine if it's a Save action)
  const hasPriceUpdates = Object.keys(updatedPrices).length > 0;

  return (
    <>
      <div className="flex w-full px-8 ">
        <div className="flex-1 flex justify-start items-center ">
          <nav aria-label="breadcrumb " style={{visibility: "hidden"}}>
            <ol className="flex space-x-2 text-md font-bold text-gray-700 mb-6">
              <li>Delivery Price</li>
              <li>
                <span className="text-gray-400">{">>"}</span>
              </li>
              <li>Create Delivery Price</li>
            </ol>
          </nav>
        </div>

        {/* Center Aligned Custom Tab Component */}
        <div className="flex-1 flex justify-center">
          <CustomCommonTab items={menuItems} />
        </div>

        {/* Placeholder for Right-Aligned Section */}
        <div className="flex-1 flex justify-end">
          {/* Add any right-aligned content here if needed */}
        </div>
      </div>

      <div
        className="relative max-w-4xl mx-auto p-8 bg-white mt-[20px] rounded-lg shadow-lg"
        style={{
          boxShadow: "0 4px 24px 0 rgba(0, 0, 0, 0.12)",
        }}
      >
        <div className="flex items-center justify-end">
          {/* Home button - bordered/unfilled by default, filled blue on hover */}
          <div
            className="absolute -top-5 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-0 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
            onClick={() => {
              navigate("/");
            }}
            style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
          >
            <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16}  />
          </div>

          {/* Close Icon - bordered/unfilled by default, filled red on hover */}
          <div
            className="absolute -top-5 -right-4 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
            onClick={() => navigate('/deliveryPriceDashBoard')}
          >
            <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1 transition-colors duration-200">&times;</span>
          </div>
        </div>

        <div className="flex justify-evenly items-center bg-white mt-[40px]">
          <div className="flex items-center justify-center">
            <button className="flex items-center">
              <FaChevronLeft className="mr-2" onClick={handlePrevDay} />
            </button>
            <div>
              <Space direction="vertical" style={{ height: "100%" }}>
                <DatePicker
                  label="date"
                  disabled={isSubmitted}
                  disabledDate={disabledDate}
                  format="MM-DD-YYYY"
                  views={["year", "month", "day"]}
                  value={selectedDate}
                  onChange={handleDateChange}
                  className="w-[170px] h-[30px]"
                  placeholder="Date"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "32px",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "1rem",
                          borderRadius: "6px",
                          "&.Mui-focused": {
                            borderColor: "blue",
                            boxShadow: "0 0 8px rgba(0, 0, 255, 0.5)",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Space>
            </div>
            <button className="flex items-center">
              <FaChevronRight className="ml-2" onClick={handleNextDay} />
            </button>
          </div>
          <button
            disabled={isSubmitted}
            className={`px-4 py-0.5 border rounded-lg ${isAllCustomers
              ? `bg-blue-500 border-blue-500 w-[170px] ${isSubmitted ? 'text-gray-600' : 'text-white'}`
              : "hover:bg-gray-200 border-gray-500 text-black-500 w-[170px]"
              } ${isSubmitted ? 'bg-gray-100 border-gray-500 text-black-500 w-[170px]' : 'w-[170px]'}`}
            onClick={handleAllCustomersClick}
          >
            All Customers
          </button>

          <div className="flex flex-col mx-4">
            <select
              id="customer-select"
              className={`h-[30px] py-0.5 rounded-lg border-grey-500 w-[170px] ${isSubmitted ? 'bg-gray-100 text-gray-600' : ''}`}
              value={selectedCustomer}
              onChange={handleDropdownChange}
              disabled={isSubmitted}
            >
              <option value="">Select Customer</option>
              {customersAll.map((customer) => (
                <option key={customer.customer_id} value={customer.customer_id}>
                  {customer.business_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-8">
          <div className="bg-white rounded-lg flex flex-col gap-4 p-4"
            style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
          >
            {/* Freight Charge Field */}
            <div className="flex justify-center items-center mb-4 p-2 rounded gap-4">
              <div className="font-semibold w-[150px]">Freight Charges</div>
              <div className="flex items-center">
                {!ispriceExists ? (
                  // When price doesn't exist and today's date is selected - show input field
                  shouldShowEditableFields && !isSubmitted ? (
                    <input
                      type="text"
                      value={freightCharge}
                      onChange={(e) => handlePriceChange('freightCharge', e.target.value)}
                      className="border p-2 rounded h-[30px] w-[130px] text-right"
                      placeholder="0.00"
                    />
                  ) : (
                    // Display mode for past dates or after submission
                    <div className="border p-2 rounded h-[30px] w-[130px] flex items-center justify-end">
                      {formatNumber(freightCharge)}
                    </div>
                  )
                ) : (
                  // When price exists - just show the value with no edit option
                  <div className="border p-2 rounded h-[30px] w-[130px] bg-gray-200 flex items-center justify-end">
                    {formatNumber(freightCharge)}
                  </div>
                )}
              </div>
            </div>

            {/* Freight Surcharge Field */}
            <div className="flex justify-center items-center p-2 rounded gap-4">
              <div className="font-semibold w-[150px]">Freight Surcharge</div>
              <div className="flex items-center">
                {!ispriceExists ? (
                  // When price doesn't exist and today's date is selected - show input field
                  shouldShowEditableFields && !isSubmitted ? (
                    <input
                      type="text"
                      value={freightSurcharge}
                      onChange={(e) => handlePriceChange('freightSurcharge', e.target.value)}
                      className="border p-2 rounded h-[30px] w-[130px] text-right"
                      placeholder="0.00"
                    />
                  ) : (
                    // Display mode for past dates or after submission
                    <div className="border p-2 rounded h-[30px] w-[130px] flex items-center justify-end">
                      {formatNumber(freightSurcharge)}
                    </div>
                  )
                ) : (
                  // When price exists - just show the value with no edit option
                  <div className="border p-2 rounded h-[30px] w-[130px] bg-gray-200 flex items-center justify-end">
                    {formatNumber(freightSurcharge)}
                  </div>
                )}
              </div>
            </div>
          </div>
          
          {isTodaySelected && (
            <div className="flex justify-center mt-6 space-x-3 mt-[20px]">
              {!ispriceExists && (
                <>
                  {/* Submit/Save Button */}
                  <button
                    onClick={handleSubmit}
                    disabled={
                      isSubmitted ||
                      freightCharge === "" || 
                      freightSurcharge === "" || 
                      isNaN(parseFormattedNumber(freightCharge)) ||
                      isNaN(parseFormattedNumber(freightSurcharge)) ||
                      parseFormattedNumber(freightCharge) <= 0 || 
                      parseFormattedNumber(freightSurcharge) <= 0
                    }
                    className={`px-3 py-1 rounded ${
                      isSubmitted ||
                      freightCharge === "" || 
                      freightSurcharge === "" || 
                      isNaN(parseFormattedNumber(freightCharge)) ||
                      isNaN(parseFormattedNumber(freightSurcharge)) ||
                      parseFormattedNumber(freightCharge) <= 0 || 
                      parseFormattedNumber(freightSurcharge) <= 0
                        ? "bg-gray-300 text-black cursor-not-allowed"
                        : "hover:bg-blue-500 hover:text-white text-blue-600 border border-blue-600"
                    }`}
                  >
                    {/* Show "Submit" for first time on same day, "Save" for edits */}
                    {isResponseDateToday ? "Save" : "Submit"}
                  </button>

                  {/* Notification Buttons */}
                  <button
                    onClick={handleNotifyCustomers}
                    disabled={!isSubmitted}
                    className={`px-3 py-1 rounded ${
                      isSubmitted
                        ? "hover:bg-green-500 hover:text-white text-green-600 border border-green-600"
                        : "bg-gray-300 text-black cursor-not-allowed"
                    }`}
                  >
                    Notify Customers
                  </button>

                  <button
                    onClick={handleNoNotification}
                    disabled={!isSubmitted}
                    className={`px-3 py-1 rounded ${
                      isSubmitted
                        ? "hover:bg-yellow-500 hover:text-white text-yellow-600 border border-yellow-500"
                        : "bg-gray-300 text-black cursor-not-allowed"
                    }`}
                  >
                    No Notification
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DeliveryPrice;




