
import React, { useState, useEffect } from "react";
import { DatePicker, Space } from "antd";
import { FaHome } from "react-icons/fa";
import dayjs from "dayjs";
import FullScreenLoader from "../pages/FullScreenLoader";
import axios from "axios";
import config from "../../config/config";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';    
import CustomCommonTab from "../reusable/CustomCommonTab";
import CustomNavigation from "../reusable/CustomNavigation";
// import CustomNavigation from "../reusable/CustomNavigationIcon";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import NoteOutlinedIcon from "@mui/icons-material/NoteOutlined";
import { NotebookPenIcon } from "lucide-react";
import NotesIcon from "@mui/icons-material/Notes";

const NewStatementForm = () => {
  const [invoiceResponse, setInvoiceResponse] = useState({ invoice_data: [] });
  const [creditResponse, setCreditResponse] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [noInvoiceData, setNoInvoiceData] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggleInput = () => {
      setIsOpen((prev) => !prev);
    };
      const [termsAndConditions, setTermsAndConditions] = useState("");
      const [isOpen1, setIsOpen1] = useState(false);
      const toggleInput1 = () => {
        setIsOpen1((prev) => !prev);
      };
        const [termsAndConditions1, setTermsAndConditions1] = useState("");
      // const apiMinimumDate = dayjs('2025-01-01'); // Replace with your actual API date
      const [disabledate, setdisabledate] = useState('')
      const [memoNote, setMemoNote] = useState("");
      const [isMemoExpanded, setMemoExpanded] = useState(false);
  const formatExactNumber = (value) => {
    if (!value) return '';
    
    // Split number into integer and decimal parts
    const [integerPart, decimalPart] = value.toString().split('.');
    
    // Format integer part with commas
    const formattedInteger = parseInt(integerPart).toLocaleString();
    
    // Return with decimal part if it exists
    if (decimalPart !== undefined) {
      return `${formattedInteger}.${decimalPart}`;
    }
    
    return formattedInteger;
  };
  const dummyData = {
    invoices: invoiceResponse.invoice_data
      ? invoiceResponse.invoice_data.map((invoice) => ({
          type: "Invoice",
          invoice_number: invoice.invoice_number,
          datee: invoice.added_on,
          reg: invoice.product_name,
          quantity: invoice.item_quantity,
          amount: invoice.unit_total
        }))
      : [],

    creditCards: creditResponse.data
      ? creditResponse.data.map((credit) => ({
          type: "Credit Card",
          invoice_number: "",
          datee: credit.added_on,
          reg: "",
          quantity: "",
          amount: credit.fuel_final_amount,
          isManualEntry: false  // Add this for API fetched data
        }))
      : []
  };

  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);

  const [rows, setRows] = useState([]);
  const [invoiceDates, setInvoiceDates] = useState({
    from: null,
    to: null
  });
  const [creditDates, setCreditDates] = useState({
    from: null,
    to: null
  });
  const [loading, setLoading] = useState(true);

  const [availableServices, setAvailableServices] = useState([
    "Water Bill",
    "Rent",
    "POS Fee",
    "Loyalty Discount",
    "Loyalty Credits",
    "Loyalty Gallons",
     "Miscellaneous"
  ]);

 
  
  // useEffect(() => {
  //   if (invoiceResponse?.invoice_data && invoiceDates.from && invoiceDates.to) {
  //     if (invoiceResponse.invoice_data.length === 0) {
  //       setNoInvoiceData(true);
  //       // Clear rows if there's no invoice data
  //       setRows([]);
  //     } else {
  //       setNoInvoiceData(false);
  //       const matchingInvoices = invoiceResponse.invoice_data
  //         .filter((invoice) => {
  //           const invoiceDate = dayjs(invoice.added_on);
  //           return (
  //             dayjs(invoiceDate).startOf('day').isSameOrAfter(dayjs(invoiceDates.from).startOf('day')) &&
  //             dayjs(invoiceDate).startOf('day').isSameOrBefore(dayjs(invoiceDates.to).startOf('day'))
  //           );
  //         })
  //         .map((invoice) => ({
  //           type: "Invoice",
  //           invoice_number: invoice.invoice_number,
  //           datee: invoice.added_on,
  //           reg: invoice.product_name,
  //           quantity: invoice.item_quantity,
  //           amount: invoice.unit_total
  //         }));
    
  //       const otherRows = rows.filter((row) => row.type !== "Invoice");
  //       setRows([...matchingInvoices, ...otherRows]);
        
  //       // If we have invoice data but no credit card rows yet, add an initial empty credit card row
  //       if (matchingInvoices.length > 0 && !otherRows.some(row => row.type === "Credit Card")) {
  //         // We'll add an empty credit card row in a separate useEffect to avoid state update conflicts
  //         setTimeout(() => {
  //           setRows(currentRows => [...currentRows, createEmptyRow("Credit Card")]);
  //         }, 0);
  //       }
  //     }
  //   }
  // }, [invoiceResponse, invoiceDates]);


  useEffect(() => {
    if (invoiceResponse?.invoice_data && invoiceDates.from && invoiceDates.to) {
      if (invoiceResponse.invoice_data.length === 0) {
        setNoInvoiceData(true);
        // Clear rows if there's no invoice data
        setRows([]);
      } else {
        setNoInvoiceData(false);
        const matchingInvoices = invoiceResponse.invoice_data
          .filter((invoice) => {
            const invoiceDate = dayjs(invoice.added_on);
            // Use endOf('day') for the 'to' date to include all entries from that day
            return (
              dayjs(invoiceDate).startOf('day').isSameOrAfter(dayjs(invoiceDates.from).startOf('day')) &&
              dayjs(invoiceDate).endOf('day').isSameOrBefore(dayjs(invoiceDates.to).endOf('day'))
            );
          })
          .map((invoice) => ({
            type: "Invoice",
            invoice_number: invoice.invoice_number,
            datee: invoice.added_on,
            reg: invoice.product_name,
            quantity: invoice.item_quantity,
            amount: invoice.unit_total
          }));
    
        const otherRows = rows.filter((row) => row.type !== "Invoice");
        setRows([...matchingInvoices, ...otherRows]);
        
        // If we have invoice data but no credit card rows yet, add an initial empty credit card row
        if (matchingInvoices.length > 0 && !otherRows.some(row => row.type === "Credit Card")) {
          // We'll add an empty credit card row in a separate useEffect to avoid state update conflicts
          setTimeout(() => {
            setRows(currentRows => [...currentRows, createEmptyRow("Credit Card")]);
          }, 0);
        }
      }
    }
  }, [invoiceResponse, invoiceDates]);

  // useEffect(() => {
  //   if (!noInvoiceData && creditResponse?.data && creditDates.from && creditDates.to) {
  //     const matchingCredits = creditResponse.data
  //       .filter((credit) => {
  //         const creditDate = dayjs(credit.added_on);
  //         return (
  //           dayjs(creditDate).startOf('day').isSameOrAfter(dayjs(creditDates.from).startOf('day')) &&
  //           dayjs(creditDate).startOf('day').isSameOrBefore(dayjs(creditDates.to).startOf('day'))
  //         );
  //       })
  //       .map((credit) => ({
  //         type: "Credit Card",
  //         invoice_number: "",
  //         datee: credit.added_on,
  //         reg: "",
  //         quantity: "",
  //         amount: credit.fuel_final_amount,
  //         isManualEntry: false
  //       }));

  //     // Preserve existing rows order by type
  //     const nonCreditRows = rows.filter((row) => row.type !== "Credit Card");
  //     const invoiceRows = nonCreditRows.filter((row) => row.type === "Invoice");
  //     const otherRows = nonCreditRows.filter((row) => row.type !== "Invoice");
      
  //     // Set rows in the desired order
  //     setRows([...invoiceRows, ...matchingCredits, ...otherRows]);
  //   }
  // }, [creditResponse, noInvoiceData]);



  useEffect(() => {
    if (!noInvoiceData && creditResponse?.data && creditDates.from && creditDates.to) {
      const matchingCredits = creditResponse.data
        .filter((credit) => {
          const creditDate = dayjs(credit.added_on);
          // Use endOf('day') for the 'to' date to include all entries from that day
          return (
            dayjs(creditDate).startOf('day').isSameOrAfter(dayjs(creditDates.from).startOf('day')) &&
            dayjs(creditDate).endOf('day').isSameOrBefore(dayjs(creditDates.to).endOf('day'))
          );
        })
        .map((credit) => ({
          type: "Credit Card",
          invoice_number: "",
          datee: credit.added_on,
          reg: "",
          quantity: "",
          amount: credit.fuel_final_amount,
          isManualEntry: false
        }));
  
      // Preserve existing rows order by type
      const nonCreditRows = rows.filter((row) => row.type !== "Credit Card");
      const invoiceRows = nonCreditRows.filter((row) => row.type === "Invoice");
      const otherRows = nonCreditRows.filter((row) => row.type !== "Invoice");
      
      // Set rows in the desired order
      setRows([...invoiceRows, ...matchingCredits, ...otherRows]);
    }
  }, [creditResponse, noInvoiceData]);


  const positiveTypes = ['Invoice', 'Rent', 'Water Bill','POS Fee','Miscellaneous'];

  // Negative transaction types (red)
  const negativeTypes = ['Credit Card', 'Loyalty Discount', 'Loyalty Credits','Loyalty Gallons',];

  // Format amount and determine color based on transaction type
  // const formatAmount = (amount, type) => {
  //   const numAmount = parseFloat(amount) || 0;
    
  //   // For negative types, show negative amount in red
  //   if (negativeTypes.includes(type)) {
  //     return {
  //       value: `${Number(numAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  //     }`,
  //       color: 'text-red-600'
  //     };
  //   }
    
  //   // For positive types, show positive amount in green
  //   if (positiveTypes.includes(type)) {
  //     return {
  //       value: `${Number(numAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  //     }`,
  //       color: 'text-black'
  //     };
  //   }
    
  //   // Default case
  //   return {
  //     value: numAmount.toFixed(2),
  //     color: ''
  //   };
  // };

  const formatAmount = (amount, type) => {
    const numAmount = parseFloat(amount) || 0;
    
    // For negative types, show negative amount in red
    if (negativeTypes.includes(type)) {
      return {
        value: `${Number(numAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
        color: 'text-red-600'
      };
    }
    
    // For positive types, show positive amount in black
    if (positiveTypes.includes(type)) {
      return {
        value: `${Number(numAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
        color: 'text-black'
      };
    }
    
    // Default case
    return {
      value: numAmount.toFixed(2),
      color: ''
    };
  };  

  const handleInvoiceDateChange = (type, date) => {
    if (!date) return;
    
    setInvoiceDates((prevDates) => {
      const newDates = { ...prevDates, [type]: date };
      
      // Sync credit card dates with invoice dates
      setCreditDates({
        from: newDates.from,
        to: newDates.to
      });
      
      if (newDates.from && newDates.to) {
        const matchingInvoices = dummyData.invoices.filter((invoice) => {
          const invoiceDate = dayjs(invoice.datee);
          return (
            dayjs(invoiceDate).startOf('day').isSameOrAfter(dayjs(newDates.from).startOf('day')) &&
            dayjs(invoiceDate).startOf('day').isSameOrBefore(dayjs(newDates.to).startOf('day'))
          );
        });
  
        const otherRows = rows.filter((row) => row.type !== "Invoice");
        setRows([...matchingInvoices, ...otherRows]);
      }
      
      return newDates;
    });
  };
  const hasFetchedCreditCardData = () => {
    return creditResponse?.data && creditResponse.data.length > 0;
  };
  
  const hasManualCreditCardEntries = () => {
    return rows.some(row => 
      row.type === "Credit Card" && 
      row.isManualEntry === true &&
      row.amount && 
      row.datee
    );
  };
  useEffect(() => {
    if (invoiceDates.from && invoiceDates.to) {
      fetchInvoiceData();
      // Always sync credit card dates with invoice dates
      setCreditDates({
        from: invoiceDates.from,
        to: invoiceDates.to
      });
    }
    if (creditDates.from && creditDates.to && !noInvoiceData) {
      fetchCreditData();
    }
  }, [invoiceDates, noInvoiceData]); 

  const handleServiceChange = (e) => {
    const selectedService = e.target.value;
    if (selectedService) {
      // Add the new row
      setRows([...rows, createEmptyRow(selectedService)]);
      
      // For all services except Miscellaneous, remove from available services
      // if (selectedService !== "Miscellaneous") {
        setAvailableServices(
          availableServices.filter((service) => service !== selectedService)
        );
      // }
  
      // Reset dropdown selection
      e.target.value = "";
    }
  };

  const createEmptyRow = (type) => ({
    type,
    invoice_number: "",
    date: null,
    reg: "",
    quantity: "",
    amount: "",
    isManualEntry: true,
    isMiscellaneous: type === "Miscellaneous"
  });
  const handleAmountChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].amount = parseFloat(value) || 0;
  
    // Only handle row addition for Credit Card type with manual entry
    if (newRows[index].type === "Credit Card" && newRows[index].isManualEntry === true) {
      // Only proceed if we have invoice data
      if (!noInvoiceData) {
        // Get all manual credit card rows
        const manualCreditCardRows = newRows.filter(row => 
          row.type === "Credit Card" && row.isManualEntry === true
        );
        
        // Check if this is the last manual credit card row and has a value
        const isLastManualCreditRow = manualCreditCardRows.indexOf(newRows[index]) === manualCreditCardRows.length - 1;
        const hasValue = value && parseFloat(value) > 0;
  
        // Check if we have API-fetched credit card data
        const hasCreditAPIData = hasFetchedCreditCardData();
        
        // Add new row if:
        // 1. This is the last manual credit card row
        // 2. It has a valid amount
        // 3. We have fewer than 15 manual credit card rows
        // 4. We don't have API-fetched credit card data (which is the key condition)
        if (isLastManualCreditRow && hasValue && manualCreditCardRows.length < 15 && !hasCreditAPIData) {
          // Directly add a new row without setTimeout
          newRows.push(createEmptyRow("Credit Card"));
        }
      }
    }
    if (newRows[index].type === "Miscellaneous" && newRows[index].isMiscellaneous === true) {
      // Get all miscellaneous rows
      const miscellaneousRows = newRows.filter(row => 
        row.type === "Miscellaneous" && row.isMiscellaneous === true
      );
      
      // Check if this is the last miscellaneous row and has a value
      const isLastMiscellaneousRow = miscellaneousRows.indexOf(newRows[index]) === miscellaneousRows.length - 1;
      const hasValue = value && parseFloat(value) > 0;
  
      // Add new row if this is the last row with a value and we have fewer than 15 miscellaneous rows
      if (isLastMiscellaneousRow && hasValue && miscellaneousRows.length < 15) {
        newRows.push(createEmptyRow("Miscellaneous"));
      }
    }
    setRows(newRows);
  };
  // useEffect(() => {
  //   if (!noInvoiceData && rows.length > 0 && !rows.some(row => row.type === "Credit Card")) {
  //     setRows(currentRows => [...currentRows, createEmptyRow("Credit Card")]);
  //   }
  // }, [noInvoiceData, rows]);
  // Helper function to check if we have credit card data for the date range
  const hasCreditCardData = (fromDate, toDate) => {
    if (!fromDate || !toDate) return false;

    return dummyData.creditCards.some((cc) => {
      const ccDate = dayjs(cc.date);
      return ccDate.isAfter(fromDate) && ccDate.isBefore(toDate);
    });
  };
 

  const calculateBalance = () => {
    return rows.reduce((sum, row) => {
      const amount = parseFloat(row.amount) || 0;
      
      // Add these types to the balance
      if (
        row.type === "Invoice" ||
        row.type === "Water Bill"  ||
        row.type ===  "Rent" ||
        row.type ===   "POS Fee" ||
        row.type === "Miscellaneous" 
      ) {
        return sum + amount;
      }
      
      // Subtract these types from the balance
      if (
        row.type === "Credit Card" ||
        row.type === "Loyalty Discount" ||
        row.type ==="Loyalty Credits" ||
        row.type ===  "Loyalty Gallons"
     
      ) {
        return sum - amount;
      }
      
      return sum;
    }, 0);
  };

  const [activeItem, setActiveItem] = useState("View");
  const [formField, setFormField] = useState({ bill_to: "" });
  const [createdDate, setCreatedDate] = useState(null);

  const [businessData, setBusinessData] = useState([]);
  const [error, setError] = useState(null);
  const [taxTotal, setTaxTotal] = useState("");
  const [productSubTotal, setProductSubTotal] = useState(0);

  const [customerOptions, setCustomerOptions] = useState([]);
  const grandTotal = parseFloat(taxTotal) + parseFloat(productSubTotal);

  const [customerIndividualData, setCustomerIndividualData] = useState(grandTotal);

  const [customerIdInvoice, setCustomerIdInvoice] = useState("");
  const [InvoiceCustomerData, setInvoiceCustomerData] = useState([]);

  const customerId = customerIdInvoice;


  useEffect(() => {
    const encodedId = btoa(customerId);
    const businessId = localStorage.getItem("business_id");

    axios
      .get(`${baseURL}Y3VzdG9tZXIvZWRpdA==/${encodedId}/${btoa(businessId)}`)
      .then((response) => {
        const fuelGradedata = response.data.customersFuelsData;
        const customerIndividualData = response.data.customers_data[0];
        setCustomerIndividualData(customerIndividualData);

        console.log(fuelGradedata, "");
        setInvoiceCustomerData(fuelGradedata);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        setLoading(false);
      });
  }, [customerId]);

  const business_id = localStorage.getItem("business_id");
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");

  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

  const businessId = localStorage.getItem("business_id");

  useEffect(() => {
    async function fetchCustomerOptions() {
      try {
        const response = await axios.get(
          `${baseURL}/${btoa("customer/view")}/${btoa(businessId)}`
        );
        setCustomerOptions(response.data.customers_data);
        console.log("customer options", response.data.customers_data);
      } catch (error) {
        console.error("Error fetching customer options:", error);
      }
    }
    fetchCustomerOptions();
  }, []);

  useEffect(() => {
    axios
      .get(
        `${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        console.log("API Response:", response);
        const data = response.data.business_data[0];
        setBusinessData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
        setError("Failed to load business data");
        setLoading(false);
      });
  }, [baseURL, business_id, user_id, user_role]);

  const [invoicePurchaseDate, setInvoicePurchaseDate] = useState("");
  const [brandInvoiceNumber, setBrandInvoiceNumber] = useState("");

  useEffect(() => {
    const fetchLatestPurchaseInvoicedata = async () => {
      try {
        const response = await axios.get(
          `${baseURL}${btoa("purchasedinvoice/fetchpurchasedinvoice")}/${btoa(
            brandInvoiceNumber
          )}/${btoa(businessId)}/${btoa(customerId)}`
        );
        console.log(response, "response 3 boxes ......");

        setInvoicePurchaseDate(
          response.data.purchasedInvoiceData[0].brand_invoice_date
        );

      } catch (error) {
        console.error("Error fetching latest purchase invoices:", error);
      }
    };
    fetchLatestPurchaseInvoicedata();
  }, [brandInvoiceNumber]);

  // const handleChange = (event) => {
  //   setCustomerIdInvoice(event.target.value);
  //   console.log(event.target.value);
  //   if (event instanceof Date) {
  //   } else {

  //     const { name, value } = event.target;
  //     setFormField({
  //       ...formField,
  //       [name]: value
  //     });
  //   }
  // };

// Modify the handleChange function to reset all fields when customer changes
const handleChange = (event) => {
  if (event instanceof Date) {
    return;
  } 
  setdisabledate('')
  const { name, value } = event.target;
  
  // If customer selection is changing
  if (name === "bill_to" && value !== formField.bill_to) {
    // Reset everything
    setCustomerIdInvoice(value);
    setFormField({
      ...formField,
      [name]: value
    });
    
    // Reset all date fields
    setCreatedDate(null);
    setInvoiceDates({
      from: null,
      to: null
    });
    setCreditDates({
      from: null,
      to: null
    });

    
    
    // Reset rows and invoice/credit data
    setRows([]);
    setInvoiceResponse({ invoice_data: [] });
    setCreditResponse([]);
    setNoInvoiceData(false);
    
    // Reset other state variables
    setBrandInvoiceNumber("");
    setInvoicePurchaseDate("");
    
    // Reset available services to original list
    setAvailableServices([
      "Water Bill",
      "Rent",
      "POS Fee",
      "Loyalty Discount",
      "Loyalty Credits",
      "Loyalty Gallons",
      "Miscellaneous"
    ]);
    // fetchdisabledates()
    console.log("Customer changed - all fields reset");
  } else {
    // Regular field update without resetting
    setCustomerIdInvoice(value);
    setFormField({
      ...formField,
      [name]: value
    });
  }
};



  function formatDate(isoString) {
    if (!isoString) return null; // Return null if the date is not set
    return dayjs(isoString).format("YYYY-MM-DD"); // Use dayjs for formatting
  }
  function formatWebDate(isoString) {
    if (!isoString) return null; // Return null if the date is not set
    return dayjs(isoString).format("MM-DD-YYYY"); // Use dayjs for formatting
  }
  function formatDate1(isoString) {
    if (!isoString) return null; // Return null if the date is not set
    return dayjs(isoString).format("YYYY-MM-DD"); // Use dayjs for formatting
  }

  const fetchInvoiceData = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/${btoa("invoice/viewinvoicefilterstatement")}`,
        {
          businessId: localStorage.getItem("business_id"),
          customerId: formField.bill_to,
          userId: localStorage.getItem("user_id"),
          from: formatDate(invoiceDates.from),
          to: formatDate1(invoiceDates.to),
          userRole: localStorage.getItem("user_role")
        }
      );
      setInvoiceResponse(response.data);
      console.log("response.....", response);
      
      // Check if invoice data is empty and set the flag
      if (!response.data.invoice_data || response.data.invoice_data.length === 0) {
        setNoInvoiceData(true);
        // Clear rows when no invoice data
        setRows([]);
      } else {
        setNoInvoiceData(false);
      }
    } catch (error) {
      console.log("error posting details", error);
      setNoInvoiceData(true);
      setRows([]);
    }
  };
 
  // const fetchCreditData = async () => {
  //   // Only fetch credit data if there is invoice data
  //   if (noInvoiceData) return;
    
  //   try {
  //     const response = await axios.post(
  //       `${baseURL}/${("statement/getcreditcard")}`,
  //       {
  //         site_id: customerIndividualData.flownia_siteid ? customerIndividualData.flownia_siteid : 0,
  //         start_date: formatDate(creditDates.from),
  //         end_date: formatDate1(creditDates.to)
  //       }
  //     );
  //     console.log(response, 'response of credit');
      
  //     // Check if we got empty results
  //     if (!response.data.data || response.data.data.length === 0) {
  //       // If no API credit card data, add an initial empty credit card row for manual entry
  //       setCreditResponse({ data: [] });
        
  //       // Check if we already have manual credit card entries
  //       const hasManualEntries = rows.some(row => row.type === "Credit Card" && row.isManualEntry === true);
        
  //       if (!hasManualEntries) {
  //         // Add an initial empty credit card row
  //         setRows(currentRows => [...currentRows, createEmptyRow("Credit Card")]);
  //       }
  //     } else {
  //       // We have API data, process it
  //       setCreditResponse(response.data);
  //     }
  //   } catch (error) {
  //     console.log("error posting details", error);
      
  //     // Even on error, if we have invoice data, we should allow manual credit card entries
  //     setCreditResponse({ data: [] });
      
  //     // Check if we already have manual credit card entries
  //     const hasManualEntries = rows.some(row => row.type === "Credit Card" && row.isManualEntry === true);
      
  //     if (!hasManualEntries) {
  //       // Add an initial empty credit card row
  //       setRows(currentRows => [...currentRows, createEmptyRow("Credit Card")]);
  //     }
  //   }
  // };

  const fetchCreditData = async () => {
    // Only fetch credit data if there is invoice data
    if (noInvoiceData) return;
    
    try {
      const response = await axios.post(
        `${baseURL}/${("statement/getcreditcard")}`,
        {
          // Use the site_id if it exists, otherwise use 0
          // Added null coalescing to handle undefined or null values
          site_id: customerIndividualData?.flownia_siteid || 0,
          start_date: formatDate(creditDates.from),
          end_date: formatDate1(creditDates.to)
        }
      );
      console.log(response, 'response of credit');
      
      // Check if we got empty results
      if (!response.data.data || response.data.data.length === 0) {
        // If no API credit card data, add an initial empty credit card row for manual entry
        setCreditResponse({ data: [] });
        
        // Check if we already have manual credit card entries
        const hasManualEntries = rows.some(row => row.type === "Credit Card" && row.isManualEntry === true);
        
        if (!hasManualEntries) {
          // Add an initial empty credit card row
          setRows(currentRows => [...currentRows, createEmptyRow("Credit Card")]);
        }
      } else {
        // We have API data, process it
        setCreditResponse(response.data);
      }
    } catch (error) {
      console.log("error posting details", error);
      
      // Even on error, if we have invoice data, we should allow manual credit card entries
      setCreditResponse({ data: [] });
      
      // Check if we already have manual credit card entries
      const hasManualEntries = rows.some(row => row.type === "Credit Card" && row.isManualEntry === true);
      
      if (!hasManualEntries) {
        // Add an initial empty credit card row
        setRows(currentRows => [...currentRows, createEmptyRow("Credit Card")]);
      }
    }
  };


  const handleSubmit = async () => {
    try {
      if (!isFormComplete()) {
        alert('Please fill in all required fields');
        return;
      }

      setIsSubmitting(true);
      
      // Format rows into arrays for each field
      const formattedData = rows.reduce((acc, row, index) => {
        acc.statementType[index] = row.type;
        acc.invoiceNumber[index] = row.invoice_number || '';
        acc.statementDate[index] = formatDate(row.datee) || '';
        acc.productName[index] = row.reg || '';
        acc.productQty[index] = row.quantity || '';
        acc.statementAmt[index] = row.amount || 0;
        return acc;
      }, {
        statementType: [],
        invoiceNumber: [],
        statementDate: [],
        productName: [],
        productQty: [],
        statementAmt: []
      });
  
      const requestData = {
        businessId: localStorage.getItem('business_id'),
        customerId: formField.bill_to,
        statementMonth: createdDate ? formatDate(createdDate) : null,
        invoiceFrom: formatDate(invoiceDates.from),
        invoiceTo: formatDate(invoiceDates.to),
        creditCardFrom: formatDate(creditDates.from),
        creditCardTo: formatDate(creditDates.to),
        ...formattedData,
        balanceAmt: calculateBalance(),
        userId: localStorage.getItem('user_id'),
        userRole: localStorage.getItem('user_role'),
        termsCondition:termsAndConditions
      };

      const response = await axios.post(`${baseURL}/${("statement/create")}`,
        requestData
      );
  
      if (response.data.dataSavingStatus) {
        dispatch(addToast({ type: 'success', message: `Statement created successfully!` }));
        navigate('/statements'); // Redirect to statements list
      } else {
        throw new Error(response.data.message || 'Failed to create statement');
      }
  
      console.log(requestData, 'requestData')
    } catch (error) {
      console.error('Error creating statement:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Failed to create statement';
      alert(`Error: ${errorMessage}`);
    } finally {
      setIsSubmitting(false);
    }
  };

// The issue is likely in the isFormComplete function
// It's treating empty Miscellaneous rows as incomplete rather than skipping them

const isFormComplete = () => {
  // Check if customer is selected
  if (!formField.bill_to) return false;

  // Check if both date ranges are selected
  const hasInvoiceDates = invoiceDates.from && invoiceDates.to;
  
  // No need to check for credit dates as they are synced with invoice dates
  if (!hasInvoiceDates) return false;

  // If there's no invoice data, form cannot be complete
  if (noInvoiceData) return false;
  
  // Check if there are any rows with data
  if (rows.length === 0) return false;

  // Check if all non-empty rows have required data
  const allRowsComplete = rows.every(row => {
    // Skip completely empty Credit Card rows
    if (row.type === "Credit Card" && !row.amount && !row.datee) {
      return true;
    }
    
    // Skip completely empty Miscellaneous rows 
    if (row.type === "Miscellaneous" && !row.amount && !row.datee) {
      return true;
    }
    
    // For Invoice rows
    if (row.type === "Invoice") {
      return row.datee && row.amount;
    }
    
    // For all other rows, require both date and amount if either is present
    return row.datee && row.amount;
  });

  // Ensure at least one row has data (other than empty Credit Card or Miscellaneous rows)
  const hasAtLeastOneRow = rows.some(row => 
    (row.amount && row.amount !== '0' && row.amount !== 0) || 
    (row.type === "Invoice" && row.invoice_number)
  );

  return allRowsComplete && hasAtLeastOneRow;
};
  
  const isCustomerSelected = Boolean(formField.bill_to);

  const menuItems = [{ name: "Create Statement" }];
  const fetchdisabledates = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/${("statement/Statement_ctrl/laststatementdate")}`,
        {
          businessId: localStorage.getItem("business_id"),
          customerId: customerIdInvoice,
        }
      );
      
      // Store the disable date
      setdisabledate(response.data.invoice_to);
      
      // If we have a disable date, automatically set the from date to disable date + 1 day
      if (response.data.invoice_to) {
        const nextDay = dayjs(response.data.invoice_to).add(1, 'day');
        
        // Force update both date pickers
        setInvoiceDates(prev => ({
          ...prev,
          from: nextDay,
          fromLocked: true // Add a flag to indicate this field should be locked
        }));
        
        // Also update credit dates to match
        setCreditDates(prev => ({
          ...prev, 
          from: nextDay
        }));
        
        console.log("Auto-selected date:", nextDay.format("MM-DD-YYYY"));
      }
      
      console.log("response.....", response);
    } catch (error) {
      console.log("error posting details", error);
    }
  };

  useEffect(() => {
    if (createdDate && customerIdInvoice) {
      fetchdisabledates();
    }
  }, [createdDate, customerIdInvoice]);

  const loyaltyTypes = ["Loyalty Discount", "Loyalty Credits", "Loyalty Gallons"];


  
  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="flex w-full px-8 ">
  <div className="flex-1 flex justify-start items-center ">
    {/* <nav aria-label="breadcrumb">
      <ol className="flex space-x-2 text-md font-bold text-gray-700 mb-6">
        <li>Statements</li>
        <li>
          <span className="text-gray-400">{">>"}</span>
        </li>
        <li>Create Statement</li>
      </ol>
    </nav> */}
  </div>

  {/* Center Aligned Custom Tab Component */}
  <div className="flex-1 flex justify-center">
    <CustomCommonTab items={menuItems} />
  </div>

  {/* Placeholder for Right-Aligned Section */}
  <div className="flex-1 flex justify-end">
    {/* Add any right-aligned content here if needed */}
  </div>
</div>


      <form>
      <div className="flex justify-center items-start">
        <div className="flex flex-col">

          <div
            className="relative w-[60vw] bg-white rounded-lg mb-[20px]"
            style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
          >
            {/* <div className=" flex items-center justify-end ">  */}
              {/* <div className="relative">
                <CustomNavigation   />
          
                </div>
              </div> */}
       {/* <CustomNavigation onCloseClick={() =>  navigate("/statements")}  /> */}

 <div className="flex items-center justify-end">
               {/* Home button - bordered/unfilled by default, filled blue on hover */}
               <div
                 className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-8 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
                 onClick={() => {
                   navigate("/");
                 }}
                 style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
               >
                 <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16}  />
               </div>
         
               {/* Close Icon - bordered/unfilled by default, filled red on hover */}
               <div
                 className="absolute -top-4 -right-3 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
                 onClick={() => navigate('/statements')}
               >
                 <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">&times;</span>
               </div>
             </div>


            <div className=" flex mb-1 justify-center rounded-md font-bold text-md items-center w-full mx-auto bg-white mt-3">
            
              {/* <div
                onClick={()=> {navigate('/statements')}}
                className={`flex items-center cursor-pointer px-4 py-1
                  } border-b border-gray-300`}
              >
                <span>Cancel</span>
              </div>
              <div
              onClick={()=> {navigate('/')}}
                className={`flex items-center cursor-pointer px-4 py-1 ${
                  activeItem === "Home"
                    ? "border-b-2 border-green-500 text-green-600"
                    : "text-gray-600"
                } border-b border-gray-300 mb-1 mt-[4px]`}
              >
                <FaHome className="mr-2 mb-1 mt-[4px]" />
              </div> */}
            </div>
            <div className="flex flex-col px-[50px] py-3 text-sm" style={{display:'none'}}>
  {/* Top section with FROM and statement details */}
  <div className="flex justify-between mb-4">
    {/* FROM section */}
    <div className="flex flex-col">
      <div className="flex items-center gap-4 mt-1">
        <div>
          <img
            src={`${baseURL}/uploads/img/business/${businessData.image_file}`}
            alt="Business Logo"
            className="w-20 h-20 object-contain"
          />
        </div>
        <div className="text-gray-600">
        <p className="font-bold">FROM</p>

          <p>{businessData.business_name}</p>
          <p>{businessData.business_address}</p>
          <p>
            {businessData.business_city}{" "}
            {businessData.business_state}{" "}
            {businessData.business_zip_code}
          </p>
          {businessData.contact_no && (
            <p className="text-gray-600">PH: {businessData.contact_no}</p>
          )}
          {businessData.fax_number && (
            <p className="text-gray-600">FX: {businessData.fax_number}</p>
          )}
        </div>
      </div>
    </div>
  {/* TO section with customer address */}
  <div className="text-center mt-2">
                <p className="font-bold text-sm capitalize  ">
                  {customerIndividualData ? " To" : ""}
                </p>
                <p className=" capitalize ">
                  {customerIndividualData.business_name}
                </p>
                <p className="capitalize">
                  {customerIndividualData.billing_address}{" "}
                
                </p>
                <p className=" capitalize">
                {customerIndividualData.billing_city}{" "}
                  {customerIndividualData.billing_state}{" "}
                  {customerIndividualData.billing_zip_code}
                </p>
              </div>
    {/* Statement details on right */}
    <div className="text-right" style={{display:'none'}}>
      <p className="font-bold">Statement #{businessData.statement_number}</p>
      <p>Statement Month & Year: {businessData.month_year}</p>
      <p>Statement Date: {businessData.statement_date}</p>
    </div>
  </div>


</div>
            <div className="flex justify-evenly items-start w-[450px] mx-auto py-2 border-b">
              <div className="flex flex-cols-2 items-start w-[180px] gap-2">
                <div></div>
                <select
                  className="ml-2 w-full p-1 border rounded-md h-[32px] text-sm"
                  id="bill_to"
                  name="bill_to"
                  required
                  value={formField.bill_to}
                  onChange={handleChange}
                >
                  <option value="">--Select Customer--</option>
                  {customerOptions.map((customer) => (
                    <option
                      key={customer.customer_id}
                      value={customer.customer_id}
                    >
                      {customer.business_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center gap-2 justify-end">
                <Space direction="vertical" className="w-auto ">
                  <DatePicker
                    id="statementDate"
                    label="Select Month"
                    picker="month"
                    format="MMMM-YYYY"
                    views={["year", "month"]}
                    placeholder="Select Month"
                    className="w-auto  h-[32px] rounded-md border border-gray-300 justify-end flex"
                    value={createdDate ? dayjs(createdDate) : null}
                    disabled={!isCustomerSelected}
                    onChange={(date) => {setCreatedDate(date);fetchdisabledates()}}
                  />
                </Space>
              </div>
            </div>

            <div className="flex justify-between p-4 px-[40px] my-4">
              <div className="flex-1 p-2 rounded-md " >
                <p className="font-bold text-sm capitalize  ">
                  {customerIndividualData ? " To" : ""}
                </p>
                <p className=" capitalize ">
                  {customerIndividualData.business_name}
                </p>
                <p className="capitalize">
                  {customerIndividualData.billing_address}{" "}
                
                </p>
                <p className=" capitalize">
                {customerIndividualData.billing_city}{" "}
                  {customerIndividualData.billing_state}{" "}
                  {customerIndividualData.billing_zip_code}
                </p>
              </div>
              <div className="w-2/3 space-y-3">
                <div className="flex items-center gap-2 justify-end">
                  <span className="w-3/5 text-right font-semibold mr-2 text-sm">
                    Invoice Date:
                  </span>
                  <div className="flex gap-2">
                    {/* <DatePicker
                      placeholder="From"
                      format="MM-DD-YYYY"
                      value={
                        invoiceDates.from ? dayjs(invoiceDates.from) : null
                      }
                      onChange={(date) => handleInvoiceDateChange("from", date)}
                      className="w-40 h-[32px]"   
                      disabled={!isCustomerSelected || !createdDate}
                    />

                    <DatePicker
                      placeholder="To"
                       format="MM-DD-YYYY"
                      value={invoiceDates.to ? dayjs(invoiceDates.to) : null}
                      onChange={(date) => handleInvoiceDateChange("to", date)}
                      className="w-40 h-[32px]"   
                      disabled={!isCustomerSelected || !createdDate}
                    /> */}
                    
                    <DatePicker
  placeholder="From"
  format="MM-DD-YYYY"
  value={invoiceDates.from ? dayjs(invoiceDates.from) : null}
  onChange={(date) => handleInvoiceDateChange("from", date)}
  className="w-40 h-[32px]"  
  disabled={!isCustomerSelected || !createdDate || invoiceDates.fromLocked}
  disabledDate={(current) => {
    // Disable dates before the reference date + 1
    if (!disabledate) return false;
    if (invoiceDates.to) {
      return dayjs(current).isAfter(dayjs(invoiceDates.to));
    }
    const nextAllowedDay = dayjs(disabledate).add(1, 'day');
    return dayjs(current).isBefore(nextAllowedDay, 'day');
  }}
  style={invoiceDates.fromLocked ? { backgroundColor: '#f0f0f0', cursor: 'not-allowed' } : {}}
/>

<DatePicker
  placeholder="To"
  format="MM-DD-YYYY"
  value={invoiceDates.to ? dayjs(invoiceDates.to) : null}
  onChange={(date) => handleInvoiceDateChange("to", date)}
  className="w-40 h-[32px]"  
  disabled={!isCustomerSelected || !createdDate}
  disabledDate={(current) => {
    if (invoiceDates.from) {
      return dayjs(current).isBefore(dayjs(invoiceDates.from));
    }
    // Disable dates before the reference date and after 1 day from the reference date
    return current && (
      
      dayjs(current).isBefore(dayjs(disabledate).add(1, 'day'), 'day')
    );
  }}
/>
                  </div>
                </div>
                
                {/* Only show credit card dates if there is invoice data */}
                {!noInvoiceData && (
                  <div className="flex items-center gap-2 justify-end">
                    <span className="w-3/5 text-right font-semibold mr-2 text-sm">
                      Credit Card Date:
                    </span>
                    <div className="flex gap-2">
                      <DatePicker
                        placeholder="From"
                         format="MM-DD-YYYY"
                        value={creditDates.from ? dayjs(creditDates.from) : null}
                        className="w-40 h-[32px]"
                        disabled={true} // Always disabled as it syncs with invoice dates
                      />
                      <DatePicker
                        placeholder="To"
                         format="MM-DD-YYYY"
                        value={creditDates.to ? dayjs(creditDates.to) : null}
                        className="w-40 h-[32px]"
                        disabled={true} // Always disabled as it syncs with invoice dates
                      />
                    </div>
                  </div>
                )}
                
                {/* Show service dropdown only if there is invoice data */}
                {!noInvoiceData && (
                  <div className="flex items-center gap-2 justify-end">
                    <span className="w-32 font-semibold text-right mr-2">Service/Misc:</span>
                    <select
                      className={`w-[330px] h-[32px] py-0 px-1 border-b-grey rounded-md text-sm font-semibold
                        ${(!isCustomerSelected || !createdDate || !invoiceDates.from || !invoiceDates.to) ? 'bg-gray-100' : ''}`}
                      disabled={!isCustomerSelected || !createdDate || !invoiceDates.from || !invoiceDates.to}
                      onChange={handleServiceChange}
                    >
                      <option value="">--Select Service--</option>
                      {availableServices.map((service) => (
                        <option key={service} value={service}>
                          {service}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>

            {/* Display "No invoice data found" message when necessary */}
            {noInvoiceData && invoiceDates.from && invoiceDates.to && (
              <div className="text-center py-4 text-red-600 font-bold">
                No invoice data found for the selected date range
              </div>
            )}

            {/* Table Section - Only show if there is invoice data */}
{!noInvoiceData && (
  <div className=" rounded-lg overflow-x-auto mx-10">
    <table className="table-auto border-collapse border w-full  mx-auto">
      <thead>
      <tr>
                  <th
                    colSpan="100%"
                    className="border-b-2 border-black bg-white h-0 p-0 m-0"
                  ></th>
                </tr>
        <tr className="bg-gray-100">
          <th className="p-2 pl-[20px] text-left w-[150px] ">Type</th>
          <th className="p-2 text-center  ">Invoice #</th>
          <th className="p-2 text-center  ">Date</th>
          <th className="p-2 text-left  " style={{whiteSpace:'nowrap'}}>Product Name</th>
          <th className="p-2 text-right min-w-[100px] ">Quantity</th>
          <th className="p-2 pr-[20px] text-right  ">Amount</th>
        </tr>
        <tr>
                  <th
                    colSpan="100%"
                    className="border-t-2 border-black bg-white h-0 p-0 m-0"
                  ></th>
                </tr>
      </thead>
      <tbody style={{ fontSize: "16px", fontFamily: "Sans-Serif"}}>
        {rows.map((row, index) => (
          <tr key={index}>
            <td className="p-2 pl-[20px] border text-left truncate" style={{whiteSpace:'nowrap'}}>{row.type}</td>
            <td className="p-2 border text-center truncate">{row.invoice_number || "-"}</td>
            <td className="p-2 border text-center">
              <div className="">
                {row.type === "Invoice" || (row.type === "Credit Card" && !row.isManualEntry) ? (
                  <div className="truncate p-1">{row.datee ? formatWebDate(row.datee) : null}</div>
                ) : (
                  <DatePicker
                    value={row.datee ? dayjs(row.datee) : null}
                     format="MM-DD-YYYY"
                    onChange={(date) => {
                      const newRows = [...rows];
                      newRows[index].datee = date;
                      setRows(newRows);
                    }}
                    className="w-[130px] h-[32px]"
                    disabled={!isCustomerSelected}
                  />
                )}
              </div>
            </td>
            <td className="p-2 border text-center truncate">{row.reg || "-"}</td>
            <td className="p-2 border text-center truncate">{row.quantity || "-"}</td>
            {/* <td className="p-2 pr-[20px] border text-right">
  {row.type === "Invoice" || (row.type === "Credit Card" && !row.isManualEntry) ? (
    <div className={`truncate p-1 ${formatAmount(row.amount, row.type).color}`}>
      {formatAmount((row.type === "Credit Card" ?(-row.amount) :row.amount), row.type).value || "-"}
    </div>
  ) : (
    <div className="relative ml-auto">
      {/* Right-aligned overlay for negative types with exact formatting */}
      {/* {negativeTypes.includes(row.type) && row.amount ? (
        <div className="absolute inset-0 pointer-events-none flex justify-end items-center pr-2">
          <span className="text-red-600"> */}
            {/* Format number exactly as typed with commas for thousands */}
            {/* -{formatExactNumber(row.amount)}
          </span>
        </div>
      ) : null}
      
      {loyaltyTypes.includes(row.type) && row.amount ? (
        <div className="absolute inset-0 pointer-events-none flex justify-end items-center pr-2">
          <span className="text-black">
            {formatExactNumber(row.amount)}
          </span>
        </div>
      ) : null} */}

      {/* The actual input */}
      {/* <input
        type="number"
        value={row.amount || ""}
        onChange={(e) => {
          // Update the row with the numeric value
          handleAmountChange(index, e.target.value);
        }}

        onFocus={(e) => {
          // This will ensure cursor positioning works correctly
          const value = e.target.value;
          e.target.value = '';
          e.target.value = value;
        }}
        
        className={`w-full h-[32px] border rounded text-right pr-2  ${
          // negativeTypes.includes(row.type) && row.amount 
          //   ? 'text-transparent' 
          //   : ''

          (negativeTypes.includes(row.type) || loyaltyTypes.includes(row.type)) && row.amount 
          ? 'text-transparent' 
          : ''

        }`}
      /> */}




{/* <input
  type="number"
  value={row.amount || ""}
  onChange={(e) => {
    // Update the row with the numeric value
    handleAmountChange(index, e.target.value);
  }}
  style={{
    // This makes the caret (cursor) black and clearly visible
    caretColor: 'black'
  }}
  className={`w-full h-[32px] border rounded text-right pr-2 ${
    (negativeTypes.includes(row.type) || row.type === "Loyalty Discount" || 
     row.type === "Loyalty Credits" || row.type === "Loyalty Gallons") && row.amount
      ? 'text-transparent' 
      : ''
  }`}
/> */}


    {/* </div>
  )}
</td> */} 


<td className="p-2 pr-[20px] border text-right">
  {row.type === "Invoice" || (row.type === "Credit Card" && !row.isManualEntry) ? (
    <div className={`truncate p-1 ${formatAmount(row.amount, row.type).color}`}>
      {formatAmount((row.type === "Credit Card" ? (-row.amount) : row.amount), row.type).value || "-"}
    </div>
  ) : (
    <div className="relative ml-auto">
      {/* Right-aligned overlay for negative types with exact formatting */}
      {negativeTypes.includes(row.type) && row.amount ? (
        <div className="absolute inset-0 pointer-events-none flex justify-end items-center pr-2">
          <span className="text-red-600">
            {/* Format number exactly as typed with commas for thousands */}
            -{formatExactNumber(row.amount)}
          </span>
        </div>
      ) : null}
      
      {/* For positive loyalty types, show in black */}
      {row.type === "Loyalty Discount" && row.amount ? (
        <div className="absolute inset-0 pointer-events-none flex justify-end items-center pr-2">
          <span className="text-red-600">
            {formatExactNumber(row.amount)}
          </span>
        </div>
      ) : null}
      
      {(row.type === "Loyalty Credits" || row.type === "Loyalty Gallons") && row.amount ? (
        <div className="absolute inset-0 pointer-events-none flex justify-end items-center pr-2">
          <span className="text-red-600">
            {formatExactNumber(row.amount)}
          </span>
        </div>
      ) : null}

      {/* The actual input */}
      {/* <input
        type="number"
        maxLength={8}
        value={row.amount || ""}
        onChange={(e) => {
          // Update the row with the numeric value
          handleAmountChange(index, e.target.value);
        }}
        style={{
          // This makes the caret (cursor) black and clearly visible
          caretColor: 'black'
        }}
        className={`w-full h-[32px] border rounded text-right pr-2 ${
          (negativeTypes.includes(row.type)) && row.amount
            ? 'text-transparent' 
            : ''
        }`}
      /> */}

{/* <input
  type="text" // Changed from "number" to "text"
  // maxLength={8}
  value={row.amount || ""}
  onKeyPress={(e) => {
    // Allow only numbers (0-9) and prevent other characters
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  }}
  onChange={(e) => {
    // Only update if the input consists of numbers
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      handleAmountChange(index, value);
    }
  }}
  style={{
    caretColor: 'black'
  }}
  className={`w-full h-[32px] border rounded text-right pr-2 ${
    (negativeTypes.includes(row.type)) && row.amount
      ? 'text-transparent' 
      : ''
  }`}
/> */}


{/*   <input
  type="number"
  // step="0.01"
  min="0"
  value={row.amount || ""}
  onChange={(e) => {
    handleAmountChange(index, e.target.value);
  }}
  style={{
    caretColor: 'black',
    appearance: 'textfield' // Removes spinner arrows in some browsers
  }}
  className={`w-full h-[32px] border rounded text-right pr-2 ${
    (negativeTypes.includes(row.type)) && row.amount
      ? 'text-transparent'
      : ''
  }`}
/>  */}


<input
  type="number"
  step="0.01"
  min="0"  // This restricts input to only positive values
  onKeyDown={(e) => {
    // Prevent the minus sign (-) from being entered
    if (e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  }}
  value={row.amount || ""}
  onChange={(e) => {
    // Ensure the value is non-negative
    const value = Math.abs(parseFloat(e.target.value)) || "";
    handleAmountChange(index, value);
  }}
  style={{
    caretColor: 'black',
    appearance: 'textfield' // Removes spinner arrows in some browsers
  }}
  className={`w-full h-[32px] border rounded text-right pr-2 ${
    (negativeTypes.includes(row.type)) && row.amount
      ? 'text-transparent'
      : ''
  }`}
/>

    </div>
  )}
</td>



          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}

{/* Balance Section - Only show if there is invoice data */}
{!noInvoiceData && (
  <div className="text-right font-bold text-sm py-[20px] px-[50px]">
    Balance Amount: $ {calculateBalance().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
  </div>
)}


<div className="mx-10">
    {!isOpen ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      // border: "1px solid #ccc",
                      padding: "10px",
                      // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)"
                    }}
                    className="w-full mb-2 bg-gray-100"
                    onClick={toggleInput}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Terms & Conditions
                    </span>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      style={{
                        transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                        transition: "transform 0.2s ease",
                      }}
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                ) : (
                  <div className="relative bg-gray-100 px-2 rounded-md mb-2">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <LightbulbOutlinedIcon
                          className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
                          style={{ borderRadius: "50%", fontSize: "28px" }}
                        />
                        <div className="pl-8 pt-1 text-md font-semibold">
                          Terms & Conditions
                        </div>
                      </div>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        onClick={toggleInput}
                        style={{
                          transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                          transition: "transform 0.2s ease",
                          cursor: "pointer",
                        }}
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </div>

                    <textarea
                      className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
                      placeholder="Enter terms and conditions here..."
                      rows={1}
                      value={
                        termsAndConditions === null ||
                          termsAndConditions === "null"
                          ? ""
                          : termsAndConditions
                      }
                      onChange={(e) => setTermsAndConditions(e.target.value)}
                      maxLength={600}
                    />
                  </div>
                )}
                </div>
                <div className="flex justify-center items-center " >
        <div
          className="relative w-[60vw] bg-white rounded-lg space-y-0  mt-2 mb-[20px] p-5"
          // style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
        >
        <div className="mx-5" >
    {!isOpen1 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      // border: "1px solid #ccc",
                      padding: "10px",
                      // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)"
                    }}
                    className="w-full mb-2 bg-gray-100"
                    onClick={toggleInput1}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Memo & Notes
                    </span>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      style={{
                        transform: isOpen1 ? "rotate(90deg)" : "rotate(0deg)",
                        transition: "transform 0.2s ease",
                      }}
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                ) : (
                  <div className="relative bg-gray-100 px-2 rounded-md mb-2">
                    <div className="flex items-center justify-between">
                    <div className="flex items-center">
        <NotesIcon className="mr-2" />
        <span style={{ fontWeight: "bold" }}>Memos & Notes</span>
        {/* <span className="ml-2 text-xs text-red-600">(Required for partial payments)</span> */}
      </div>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        onClick={toggleInput1}
                        style={{
                          transform: isOpen1 ? "rotate(90deg)" : "rotate(0deg)",
                          transition: "transform 0.2s ease",
                          cursor: "pointer",
                        }}
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </div>

                    <textarea
                      className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
                      placeholder="Enter Memo and Notes here..."
                      rows={1}
                      value={
                        termsAndConditions1 === null ||
                          termsAndConditions1 === "null"
                          ? ""
                          : termsAndConditions1
                      }
                      onChange={(e) => setTermsAndConditions1(e.target.value)}
                      maxLength={600}
                    />
                  </div>
                )}
                </div>

        </div>
        </div>
                {/* Submit button - Only show if form is complete */}
                {!noInvoiceData && (
  <div className="mx-10 flex justify-end">
    {isFormComplete() && (
      <button
        onClick={handleSubmit}
        disabled={isSubmitting}
        className={`w-24 px-2 py-1 border-2 border-[#000080] mb-[20px]
          hover:font-bold rounded-md hover:bg-blue-700 
          hover:shadow-blue-700/50 uppercase duration-200 
          hover:text-white hover:shadow-lg hover:border-transparent 
          active:scale-90 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {isSubmitting ? 'Creating...' : 'CREATE'}
      </button>
    )}
  </div>
)}
<div className="mt-6 border-t text-sm text-black-600 ">
  <div className="flex items-center justify-between text-sm px-[50px] py-3" style={{display:'none'}}>
    <div className="flex items-center justify-center gap-4">
      <div>
        <img
          src={`${baseURL}/uploads/img/business/${businessData.image_file}`}
          alt="Business Logo"
          className="w-20 h-20 object-contain"
        />
      </div>
     
    </div>

    <div className="flex flex-col items-right justify-end font-md text-gray-600  text-sm capitalize">
    <div className="text-right text-gray-600 text-sm ">
                      <p >{businessData.business_name}</p>
                      <p>{businessData.business_address} </p>
                      <p>
                        {businessData.business_city}{" "}
                        {businessData.business_state}{" "}
                        {businessData.business_zip_code}
                      </p>{" "}
                    </div>
      {businessData.contact_no && <p className="font-md text-gray-600 text-sm uppercase ">
        PH: {businessData.contact_no}
      </p>}
      { businessData.fax_number && <p className="font-md text-gray-600 text-sm capitalize">
        FX: {businessData.fax_number}
      </p>}
      <p className="font-md text-gray-600 text-sm capitalize">
      </p>
    </div>
  </div>
</div>
{/* 
<div
  className="mx-[40px] mb-2"
  style={{ marginBottom: "50px", marginTop: "50px" }}
></div> */}
          </div>

       

      </div>
    </div>
     <div className="flex justify-center items-center"></div>
   <div className="flex justify-center items-center"></div>
   </form>
   </>
  );
};
export default NewStatementForm;
