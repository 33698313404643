import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTabs } from "../pages/TabContext";
import { FaCheckCircle, FaHome, FaTimes, FaTimesCircle } from "react-icons/fa";
import * as Yup from "yup";
import {
  MdOutlineLocalShipping,
  MdOutlinePayment,
  MdOutlinePhoneInTalk,
  MdPersonOutline,
} from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import config from "../../config/config"
import ReactDOM from 'react-dom';
import { showToastForDeleteCustomer, showToastForUpdateCustomer } from "../toasts/toastForCustomer";
import smsIcon from '../../assets/images/smsIcon.png'
import { Tooltip } from "react-tippy";
import { addToast } from '../../components/pages/ToastBox/ToastBoxMessageSlice';
import { useDispatch, useSelector } from "react-redux";
import CustomNavigationButtons from "../reusable/CustomNavigationWithEditDelete";
import { Formik } from "formik";
import DatePicker from "tailwind-datepicker-react";
// import { Datepicker } from "flowbite-react";
// import { DatePicker } from "antd";


const CustomerView = ({ customerId, handledelete }) => {
  const dispatch = useDispatch()

  const { changeTab } = useTabs();
  const handleCancel = () => {
    // Change tab to 'items'
    navigate(`/customers`); // Navigate to the 'items' route
  };

  // const { customerId } = useParams();
  // console.log(customerId);
  // const customerId = localStorage.getItem('customerId')
  const [isFormModified, setIsFormModified] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [fuelGradeNames, setFuelGradeNames] = useState([])

  const [fuelGradedata, setFuelGradedata] = useState([])
  const [isFuelGradesModified, setIsFuelGradesModified] = useState(false);
  const [originTerminal, setOriginTerminal] = useState([])


  useEffect(() => {
    const fetchFuelGradeNames = async () => {
      try {
        const response = await axios.get(`${baseURL}${btoa("invoice/allfuelgrades")}`)
        console.log('response', response);
        setFuelGradeNames(response.data);
        console.log(fuelGradeNames, 'fuelGradeNames')
      } catch (error) {
        console.log("error fetching fuelGrade names", error);
      }
    }
    fetchFuelGradeNames()
    console.log('setfuelGradeNames', fuelGradeNames)
  }, [])

  useEffect(() => {
    const fetchTerminalNames = async () => {
      try {
        const response = await axios.get(`${baseURL}${btoa("invoice/allterminals")}`)
        // console.log("ter" , response)
        setOriginTerminal(response.data.terminals_data);

      } catch (error) {
        console.log("error fetching vehicles names");
      }
    }
    fetchTerminalNames()
  }, [])
  const [sellerNames, setSellerNames] = useState([])
  useEffect(() => {
    const fetchSellersNames = async () => {
      try {
        const response = await axios.get(`${baseURL}${btoa("invoice/allsellers")}`)
        console.log('response', response);
        setSellerNames(response.data);
        console.log(sellerNames, 'sellernames')
      } catch (error) {
        console.log("error fetching seller names", error);
      }
    }
    fetchSellersNames()
    console.log('setSellerNames', sellerNames)
  }, [])

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`, // Use the provided marginLeft prop
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const navigate = useNavigate();
  const [customerDetails, setCustomerDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [customerEditMode, setCustomerEditMode] = useState(false);
  const [billingEditMode, setBillingEditMode] = useState(false);
  const [shippingEditMode, setShippingEditMode] = useState(false);
  const [paymentEditMode, setPaymentEditMode] = useState(false);
  const [zipCodeError, setZipCodeError] = useState("");
  const [billingZipCodeError, setBillingZipCodeError] = useState("");
  const [shippingZipCodeError, setShippingZipCodeError] = useState("");
  const [cardZipCodeError, setCardZipCodeError] = useState("");
  const [bankAccountError, setBankAccountError] = useState("");
  const [businessAccountError, setBusinessAccountError] = useState("");
  const [inputPhoneError, setInputPhoneError] = useState("");
  const [bankRoutingError, setBankRoutingError] = useState("");
  const [cardError, setCardError] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [expirationDateError, setExpirationDateError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isBusinessInfoModified, setIsBusinessInfoModified] = useState(false);
  const [isContactInfoModified, setIsContactInfoModified] = useState(false);
  const [isBillingInfoModified, setIsBillingInfoModified] = useState(false);
  const [isShippingInfoModified, setIsShippingInfoModified] = useState(false);
  const [isPaymentInfoModified, setIsPaymentInfoModified] = useState(false);
  const [busNameError, setbusNameError] = useState("");
  const [busAddNameError, setBusAddNameError] = useState("");
  const [busCityNameError, setbusCityNameError] = useState("");
  const [contactNameError, setContactNameError] = useState("");



  const [invalidBusinessZipCode, setInvalidBusinessZipCode] = useState("")
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState("");
  const [invalidBillingZipCode, setInvalidBillingZipCode] = useState("")
  const [invalidShippingPhoneNumber, setInvalidShippingPhoneNumber] = useState("");
  const [invalidShippingZipCode, setInvalidShippingZipCode] = useState("")
  const [invalidBankRoutingNumber, setInvalidBankRoutingNumber] = useState("")
  const [invalidBankAccountNumber, setInvalidBankAccountNumber] = useState("")
  const [invalidCardNumber, setInvalidCardNumber] = useState("")
  const [invalidCardCvv, setInvalidCardCvv] = useState("")
  const [invalidCardZipCode, setInvalidCardZipCode] = useState("")

  const [billingAddNameError, setBillingAddNameError] = useState("");
  const [billingCityNameError, setBillingCityNameError] = useState("");
  const [shippingToNameError, setshippingToNameError] = useState("");
  const [shippingAddNameError, setShippingAddNameError] = useState("");
  const [shippingCityError, setshippingCityError] = useState("");
  const [shippingDIError, setShippingDTError] = useState("");
  const [cardNameError, setCardNameError] = useState("");
  const [isSameAsAboveSelected, setIsSameAsAboveSelected] = useState(false);
  const [customerNotification, setCustomerNotification] = useState(false);
  const [contactNotification, setContactNotification] = useState(false);
  const [billingNotification, setBillingNotification] = useState(false);
  const [shippingNotification, setShippingNotification] = useState(false);
  const [paymentNotification, setPaymentNotification] = useState(false);



  const handlebusNameChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^0-9a-zA-Z&\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setbusNameError("Name must be at least 3 letters");
    } else {
      setbusNameError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
      setIsContactInfoModified(!isModified);
      setIsBillingInfoModified(!isModified);
      setIsShippingInfoModified(!isModified);
      setIsPaymentInfoModified(!isModified);
      setCustomerNotification(true)
    }
  };
  const handlebusAddChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z0-9\s\-\/#]/g, "");
    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setBusAddNameError("Address must be at least 3 letters");
    } else {
      setBusAddNameError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
      setIsContactInfoModified(!isModified);
      setIsBillingInfoModified(!isModified);
      setIsShippingInfoModified(!isModified);
      setIsPaymentInfoModified(!isModified);
      setCustomerNotification(true);
    }
  };

  const handlebusCityChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/[^A-Za-z0-9\s]/g, "");
    setFormData({
      ...formData,
      [name]: capitalizeWords(sanitizedValue),
    });
    if (sanitizedValue.replace(/\s/g, "").length > 0 && sanitizedValue.length < 3) {
      setbusCityNameError(" City Name must be at least 3 letters");
    } else {
      setbusCityNameError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
      setIsContactInfoModified(!isModified);
      setIsBillingInfoModified(!isModified);
      setIsShippingInfoModified(!isModified);
      setIsPaymentInfoModified(!isModified);
      setCustomerNotification(true)
    }
  };
  const handleContactNameChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z\s]/g, "");
    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setContactNameError(" Name must be at least 3 letters");
    } else {
      setContactNameError("");
    }
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      // setIsContactInfoModified(isContactModified);
      setIsBusinessInfoModified(!isContactModified);
      setIsContactInfoModified(isContactModified);
      setIsBillingInfoModified(!isContactModified);
      setIsShippingInfoModified(!isContactModified);
      setIsPaymentInfoModified(!isContactModified);
      setCustomerNotification(true)
    }
  };
  const handleBillingAddChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z0-9\s\-\/#]/g, "");

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setBillingAddNameError("Address must be at least 3 letters");
    } else {
      setBillingAddNameError("");
    }
    const isBillingModified = value !== customerDetails[name];
    if (billingInfoFields.includes(name)) {
      // setIsBillingInfoModified(isBillingModified);
      setIsBusinessInfoModified(!isBillingModified);
      setIsContactInfoModified(!isBillingModified);
      setIsBillingInfoModified(isBillingModified);
      setIsShippingInfoModified(!isBillingModified);
      setIsPaymentInfoModified(!isBillingModified);
      setBillingNotification(true)
    }
  };
  const handleBillingCityChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setBillingCityNameError(" City Name must be at least 3 letters");
    } else {
      setBillingCityNameError("");
    }
    const isBillingModified = value !== customerDetails[name];
    if (billingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isBillingModified);
      setIsContactInfoModified(!isBillingModified);
      setIsBillingInfoModified(isBillingModified);
      setIsShippingInfoModified(!isBillingModified);
      setIsPaymentInfoModified(!isBillingModified);
      setBillingNotification(true)

    }
  };
  const handlecardChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/[^A-Za-z\s]/g, "");
    let inputValue = sanitizedValue.replace(/[^a-zA-Z\s]/g, "");
    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setCardNameError(" Name on card must be at least 3 letters");
    } else {
      setCardNameError("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      // setIsPaymentInfoModified(isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsBillingInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)
    }
  };

  const handleShippingToChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z\s]/g, "");
    setShippingInfo({
      ...shippingInfo,
      contactPersonName: capitalizeWords(inputValue),
    });
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setshippingToNameError(" Shipping Details must be at least 3 letters");
    } else {
      setshippingToNameError("");
    }
    // const isPaymentModified = value !== customerDetails[name];
    // if (paymentInfoFields.includes(name)) {
    //   setIsPaymentInfoModified(isPaymentModified);
    // }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      // setIsShippingInfoModified(isShippingModified);
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsBillingInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setCustomerNotification(true)
    }
  };

  const handleShippingToChangeSecond = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z\s]/g, "");
    setFormData({
      ...formData,
      shippingTo: capitalizeWords(inputValue),
    });
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setshippingToNameError(" Shipping Details must be at least 3 letters");
    } else {
      setshippingToNameError("");
    }
    // const isPaymentModified = value !== customerDetails[name];
    // if (paymentInfoFields.includes(name)) {
    //   setIsPaymentInfoModified(isPaymentModified);
    // }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsBillingInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)
    }
  };


  const handleShippingAddChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setShippingAddNameError(" Address must be at least 3 letters");
    } else {
      setShippingAddNameError("");
    }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsBillingInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)
    }
  };


  const handleShippingCityChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z0-9\s]/g, "");
    setShippingInfo({
      ...shippingInfo,
      billingCity: capitalizeWords(inputValue),
    });
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setshippingCityError(" City Name must be at least 3 letters");
    } else {
      setshippingCityError("");
    }
    // const isPaymentModified = value !== customerDetails[name];
    // if (paymentInfoFields.includes(name)) {
    //   setIsPaymentInfoModified(isPaymentModified);
    // }
    const isShippingInfoModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isShippingInfoModified);
      setIsContactInfoModified(!isShippingInfoModified);
      setIsBillingInfoModified(!isShippingInfoModified);
      setIsShippingInfoModified(isShippingInfoModified);
      setIsPaymentInfoModified(!isShippingInfoModified);
      setShippingNotification(true)
    }
  };

  const handleShippingCityChangeSecond = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z0-9\s]/g, "");
    setFormData({
      ...formData,
      shippingCity: capitalizeWords(inputValue),
    });
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setshippingCityError(" City Name must be at least 3 letters");
    } else {
      setshippingCityError("");
    }
    // const isPaymentModified = value !== customerDetails[name];
    // if (paymentInfoFields.includes(name)) {
    //   setIsPaymentInfoModified(isPaymentModified);
    // }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsBillingInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)
    }
  };

  const handleShippingDIChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setShippingDTError(" Instructions must be at least 3 letters");
    } else {
      setShippingDTError("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsPaymentInfoModified(isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsBillingInfoModified(!isPaymentModified);
      setIsShippingInfoModified(isPaymentModified);
      setPaymentNotification(true)
    }
  };

  const [shippingInfo, setShippingInfo] = useState({
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonPhoneNumber: '',
    billingAddress: '',
    billingCity: '',
    billingState: '',
    billingZipCode: '',
    shippingCounty: '',
  });

  const [formData, setFormData] = useState({
    businessName: "",
    businessAccount: "",
    businessAddress: "",
    businessCity: "",
    businessState: "",
    businessZipCode: "",
    fuelReceivedTerminalId: '',
    brandId: '',
    contactPersonName: "",
    contactPersonEmail: "",
    contactPersonPhoneNumber: "",
    currency: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    billingZipCode: "",
    shippingTo: shippingInfo.contactPersonName,
    shippingAddress: shippingInfo.billingAddress,
    shippingCity: shippingInfo.billingCity,
    shippingState: shippingInfo.billingState,
    shippingZipCode: shippingInfo.billingZipCode,
    shippingCounty: shippingInfo.shippingCounty,
    shippingContactNumber: shippingInfo.contactPersonPhoneNumber,
    shippingDeliveryInstructions: "",
    bankCurrency: "",
    bankRouting: "",
    bankAccount: "",
    cardNumber: "",
    cardExpiryDate: new Date(),
    cardCVV: "",
    cardZipCode: "",
    nameOnCard: "",
    paymentType: "",
    creditCardName: "",
    cardPaymentCurrency: "",
    fuelIds: []
  });

  const [isCardSelected, setIsCardSelected] = useState(false);

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    cleaned = cleaned.slice(0, 10);
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ')', match[3], '-', match[4]].join('');
    }
    return null;
  }

  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const validateEmail = (email) => {
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    let { name, value } = e.target;
    value = e.target.value;
    if (value.length > 1) {
      value = value[0] + value.slice(1).toLowerCase();
    }
    const isValidEmail = validateEmail(value);
    setFormData({
      ...formData,
      [name]: value,
    });

    // Set error message if email is not in the expected format
    if (value.length > 0 && !isValidEmail) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      // setIsContactInfoModified(isContactModified);
      setIsBusinessInfoModified(!isContactModified);
      setIsContactInfoModified(isContactModified);
      setIsBillingInfoModified(!isContactModified);
      setIsShippingInfoModified(!isContactModified);
      setIsPaymentInfoModified(!isContactModified);
      setContactNotification(true)
    }
  };





  useEffect(() => {
    const encodedId = btoa(customerId)
    const businessId = localStorage.getItem("business_id")

    axios
      .get(`${baseURL}Y3VzdG9tZXIvZWRpdA==/${encodedId}/${btoa(businessId)}`)               // http://localhost/InvoiceFlow/customer/edit/{customerId}
      // .get(`${baseURL}/customer/edit/${customerId}`)      
      .then((response) => {
        const customerData = response.data.customers_data[0];
        // const fuelGradedata = response.data.customersFuelsData
        const fuelGradedata = response.data.customersFuelsData.map((id) => id.product_id.toString());

        console.log(customerData, fuelGradedata);
        setCustomerDetails(customerData);
        setFuelGradedata(fuelGradedata)
        setFormData({
          businessName: customerData.business_name,
          businessAccount: customerData.business_account,
          businessAddress: customerData.business_address,
          businessCity: customerData.business_city,
          businessState: customerData.business_state,
          businessZipCode: customerData.business_zip_code,
          fuelReceivedTerminalId: customerData.fuel_received_terminal_id,
          brandId: customerData.brand_id,
          contactPersonName: customerData.contact_name,
          contactPersonEmail: customerData.contact_email,
          contactPersonPhoneNumber: customerData.contact_no,
          billingAddress: customerData.billing_address,
          billingCity: customerData.billing_city,
          billingState: customerData.billing_state,
          billingZipCode: customerData.billing_zip_code,
          shippingTo: customerData.shipping_to,
          shippingAddress: customerData.shipping_address,
          shippingCity: customerData.shipping_city,
          shippingState: customerData.shipping_state,
          shippingZipCode: customerData.shipping_zip_code,
          shippingCounty: customerData.shipping_county,
          shippingContactNumber: customerData.shipping_contact_number,
          shippingDeliveryInstructions: customerData.shipping_delivery_instructions,

          bankCurrency: customerData.payment_currency,
          bankRouting: customerData.bank_routing,
          bankAccount: customerData.bank_account,
          cardNumber: customerData.card_number,
          nameOnCard: customerData.name_on_card,
          cardExpiryDate: customerData.card_expiry_date,
          cardZipCode: customerData.card_zip_code, // Assuming it's in the correct format, otherwise, you might need to format it appropriately
          cardCVV: customerData.card_cvv,
          paymentType: customerData.payment_type,
          creditCardName: customerData.credit_card_name,
          cardPaymentCurrency: customerData.card_payment_currency,
          fuelIds: fuelGradedata,
          customerCounty: "Hudson",
          // customerCounty: customerData.customer_county,
          flowniaSiteId: customerData.flownia_siteid
        });

        setShippingInfo({
          contactPersonName: customerData.contact_name,
          contactPersonEmail: customerData.contact_email,
          contactPersonPhoneNumber: customerData.contact_no,
          billingAddress: customerData.billing_address,
          billingCity: customerData.billing_city,
          billingState: customerData.billing_state,
          billingZipCode: customerData.shipping_zip_code,
        })
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        setLoading(false);
      });
  }, [customerId]);

  const [selectedFuelIds, setSelectedFuelIds] = useState(formData.fuelIds || []);

  useEffect(() => {
    if (formData.fuelIds) {
      setSelectedFuelIds(formData.fuelIds);
    }
  }, [formData.fuelIds]);


  // const handleFuelSelection = (event, fuelId) => {
  //   let updatedFuelIds;

  //   if (event.target.checked) {
  //     updatedFuelIds = [...selectedFuelIds, fuelId.toString()];
  //   } else {
  //     updatedFuelIds = selectedFuelIds.filter((id) => id !== fuelId.toString());
  //   }

  //   setSelectedFuelIds(updatedFuelIds);

  //   // Update FormData if required
  //   const formData = new FormData();
  //   formData.append("fuelIds", updatedFuelIds);
  //   console.log("Updated FormData:", formData.get("fuelIds"));
  // };

  const handleFuelSelection = (event, fuelId) => {
    let updatedFuelIds;

    if (event.target.checked) {
      updatedFuelIds = [...formData.fuelIds, fuelId.toString()];
    } else {
      updatedFuelIds = formData.fuelIds.filter((id) => id !== fuelId.toString());
    }

    setSelectedFuelIds(updatedFuelIds); // Optional, if separate state is used
    setFormData((prevData) => ({
      ...prevData,
      fuelIds: updatedFuelIds,
    }));
    setIsFuelGradesModified(!updatedFuelIds.every((id) => formData.fuelIds.includes(id)) || updatedFuelIds.length !== formData.fuelIds.length);

  };

  const handleAddressToggle = () => {
    setIsSameAsAboveSelected((prev) => !prev);

    // If "Same as above" is selected, copy values from the above section
    if (!isSameAsAboveSelected) {
      setShippingInfo({
        contactPersonName: formData.contactPersonName,
        contactPersonEmail: formData.contactPersonEmail,
        contactPersonPhoneNumber: formData.contactPersonPhoneNumber,
        billingAddress: formData.billingAddress,
        billingCity: formData.billingCity,
        billingState: formData.billingState,
        billingZipCode: formData.billingZipCode,

      });
    }
  };


  const handleInputPhoneChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 10);
    if (inputValue.length === 10) {
      inputValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3, 6)}-${inputValue.slice(6, 10)}`;
    }
    const formattedValue = formatPhoneNumber(e.target.value);
    const containsNonZero = /[1-9]/.test(formattedValue);
    if (containsNonZero) {
      setInvalidPhoneNumber("valid")
    } else {
      setInvalidPhoneNumber("Invalid number")
    }

    if (formattedValue === "") {
      setInvalidPhoneNumber("")
    }

    if (inputValue.length > 0 && !/^\(\d{3}\) \d{3}-\d{4}$/.test(inputValue)) {
      setInputPhoneError("Please enter a valid 10-digit phone number");
    } else {
      setInputPhoneError("");
    }
    setFormData({
      ...formData,
      [name]: inputValue,
    });
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      // setIsContactInfoModified(isContactModified);
      setIsBusinessInfoModified(!isContactModified);
      setIsContactInfoModified(isContactModified);
      setIsBillingInfoModified(!isContactModified);
      setIsShippingInfoModified(!isContactModified);
      setIsPaymentInfoModified(!isContactModified);
      setContactNotification(true)
    }
    // const isShippingModified = value !== customerDetails[name];
    // if (contactInfoFields.includes(name)) {
    //   setIsShippingInfoModified(isShippingModified);
    // }
  };

  const handleShippingInputPhoneChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 10);
    if (inputValue.length === 10) {
      inputValue = `(${inputValue.slice(0, 3)})${inputValue.slice(
        3,
        6
      )}-${inputValue.slice(6, 10)}`;
    }
    const containsNonZero = /[1-9]/.test(e.target.value);
    if (containsNonZero) {
      setInvalidShippingPhoneNumber("valid")
    } else if (e.target.value.length > 0 && e.target.value.length < 10) {
      setInvalidShippingPhoneNumber("Invalid number")
    }
    else {
      setInvalidShippingPhoneNumber("Invalid number")
    }
    if (e.target.value === "") {
      setInvalidShippingPhoneNumber("")
    }
    setShippingInfo({
      ...shippingInfo,
      contactPersonPhoneNumber: inputValue,
    });
    if (inputValue.length > 0 && !/^\(\d{3}\)\d{3}-\d{4}$/.test(inputValue)) {
      setInputPhoneError("Please enter a valid 10-digit phone number");
    } else {
      setInputPhoneError("");
    }
    // const isContactModified = value !== customerDetails[name];
    // if (contactInfoFields.includes(name)) {
    //   setIsContactInfoModified(isContactModified);
    // }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsBillingInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)
    }
  };

  const handleShippingInputPhoneChangeSecond = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 10);
    if (inputValue.length === 10) {
      inputValue = `(${inputValue.slice(0, 3)})${inputValue.slice(
        3,
        6
      )}-${inputValue.slice(6, 10)}`;
    }
    const containsNonZero = /[1-9]/.test(e.target.value);

    if (containsNonZero) {
      setInvalidShippingPhoneNumber("valid")
    } else if (e.target.value.length > 0 && e.target.value.length < 10) {
      setInvalidShippingPhoneNumber("Invalid number")
    }
    else {
      setInvalidShippingPhoneNumber("Invalid number")
    }
    if (e.target.value === "") {
      setInvalidShippingPhoneNumber("")
    }
    setFormData({
      ...formData,
      shippingContactNumber: inputValue,
    });
    if (inputValue.length > 0 && !/^\(\d{3}\)\d{3}-\d{4}$/.test(inputValue)) {
      setInputPhoneError("Please enter a valid 10-digit phone number");
    } else {
      setInputPhoneError("");
    }
    // const isContactModified = value !== customerDetails[name];
    // if (contactInfoFields.includes(name)) {
    //   setIsContactInfoModified(isContactModified);
    // }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsBillingInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)
    }
  };


  const handleInputChange = (e, val) => {
    const { name, value } = e.target;

    // Check if the field is one of the excluded fields
    const transformedValue = [
      "businessState",
      "billingState",
      "shippingState",
      "bankCurrency",
      "cardPaymentCurrency",
      "fuelReceivedTerminalId"
    ].includes(name)
      ? value
      : capitalizeWords(value);

    setFormData({
      ...formData,
      [name]: transformedValue,
    });
    if (val === "businessState") {
      const isModified = value !== customerDetails[name];
      if (businessInfoFields.includes(name)) {
        setIsBusinessInfoModified(isModified);
        setIsBusinessInfoModified(isModified);
        setIsContactInfoModified(!isModified);
        setIsBillingInfoModified(!isModified);
        setIsShippingInfoModified(!isModified);
        setIsPaymentInfoModified(!isModified);
        setCustomerNotification(true)
      }
    }
    // const isContactModified = value !== customerDetails[name];
    // if (contactInfoFields.includes(name)) {
    //   setIsContactInfoModified(isContactModified);
    // }
    if (val === "billingState") {
      const isBillingModified = value !== customerDetails[name];
      if (businessInfoFields.includes(name)) {
        setIsBillingInfoModified(!isBillingModified);
        setIsBusinessInfoModified(isBillingModified);
        setIsContactInfoModified(!isBillingModified);
        setIsShippingInfoModified(!isBillingModified);
        setIsPaymentInfoModified(!isBillingModified);
        setBillingNotification(true)
      }
    }
    if (val === "fuelReceivedTerminalId") {
      const isBillingModified = value !== customerDetails[name];
      if (businessInfoFields.includes(name)) {
        setIsBillingInfoModified(!isBillingModified);
        setIsBusinessInfoModified(isBillingModified);
        setIsContactInfoModified(!isBillingModified);
        setIsShippingInfoModified(!isBillingModified);
        setIsPaymentInfoModified(!isBillingModified);
        setBillingNotification(true)
      }
    }
    if (val === "brandId") {
      const isBillingModified = value !== customerDetails[name];
      if (businessInfoFields.includes(name)) {
        setIsBillingInfoModified(!isBillingModified);
        setIsBusinessInfoModified(isBillingModified);
        setIsContactInfoModified(!isBillingModified);
        setIsShippingInfoModified(!isBillingModified);
        setIsPaymentInfoModified(!isBillingModified);
        setBillingNotification(true)
      }
    }

    // const isShippingModified = value !== customerDetails[name];
    // if (shippingInfoFields.includes(name)) {
    //   setIsShippingInfoModified(isShippingModified);
    // }
    if (val === "cardPaymentCurrency" || "creditCardName" || "bankCurrency") {
      const isPaymentModified = value !== customerDetails[name];
      if (paymentInfoFields.includes(name)) {
        setIsBillingInfoModified(!isPaymentModified);
        setIsBusinessInfoModified(!isPaymentModified);
        setIsContactInfoModified(!isPaymentModified);
        setIsShippingInfoModified(!isPaymentModified);
        setIsPaymentInfoModified(isPaymentModified);
        setPaymentNotification(true)
      }
    }
  };

  const handleBankRoutingChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 9); // Limit to 17 digits

    const containsNonZero = /[1-9]/.test(inputValue);

    if (containsNonZero) {
      setInvalidBankRoutingNumber("valid")
    } else {
      setInvalidBankRoutingNumber("Invalid number")
    }
    if (inputValue === "") {
      setInvalidBankRoutingNumber("")
    }

    setFormData({
      ...formData,
      [name]: inputValue,
    });

    // Set error message if account number is not empty and exceeds 17 digits
    if (inputValue.length > 0 && inputValue.length < 9) {
      setBankRoutingError("Routing Number must be of 9 digits");
    } else {
      setBankRoutingError("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsBillingInfoModified(!isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)

    }
  };

  const processBusinessAccountInput = (value) => {
    const inputValue = value.replace(/[^a-zA-Z0-9-]/g, "");
    const maxLength = 20;
    const truncatedValue = inputValue.slice(0, maxLength);
    const processedValue = truncatedValue.charAt(0).toUpperCase() + truncatedValue.slice(1);
    return processedValue;
  };

  const handleBusinessAccountChange = (e) => {
    const { name, value } = e.target;
    const processedValue = processBusinessAccountInput(value);
    setFormData({
      ...formData,
      [name]: processedValue,
    });
    if (processedValue.length >= 0 && processedValue.length < 3) {
      setBusinessAccountError("Business account must be at least 3 to 20 characters");
    } else {
      setBusinessAccountError("");
    }
    const isModified = processedValue !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
      setIsContactInfoModified(!isModified);
      setIsBillingInfoModified(!isModified);
      setIsShippingInfoModified(!isModified);
      setIsPaymentInfoModified(!isModified);
      setCustomerNotification(true)
    }
  };

  const handleBankAccountChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 17);
    const containsNonZero = /[1-9]/.test(inputValue);
    if (containsNonZero) {
      setInvalidBankAccountNumber("valid")
    } else {
      setInvalidBankAccountNumber("Invalid number")
    }
    if (inputValue === "") {
      setInvalidBankAccountNumber("")
    }
    setFormData({
      ...formData,
      [name]: inputValue,
    });

    // Set error message if account number is not empty and exceeds 17 digits
    if (inputValue.length > 0 && inputValue.length < 17) {
      setBankAccountError("Account Number must be of 17 digits");
    } else {
      setBankAccountError("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsBillingInfoModified(!isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)
    }
  };

  const handleInputZipChange = (e) => {
    const { name, value } = e.target;
    const containsNonZero = /[1-9]/.test(value);
    if (containsNonZero) {
      setInvalidBusinessZipCode("valid")
    } else {
      setInvalidBusinessZipCode("Invalid number")
    }

    if (e.target.value === "") {
      setInvalidBusinessZipCode("")
    }

    let inputValue = value;
    inputValue = inputValue.slice(0, 5); // Trimming to first 5 characters
    setFormData({
      ...formData,
      [name]: inputValue  // Using trimmed value here
    });

    // Set error message if zip code is not empty and exceeds 5 digits
    if (inputValue.length > 0 && inputValue.length < 5) {
      setZipCodeError("Zip code must be at most 5 digits");
    } else {
      setZipCodeError("");
    }

    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBillingInfoModified(!isModified);
      setIsBusinessInfoModified(isModified);
      setIsContactInfoModified(!isModified);
      setIsShippingInfoModified(!isModified);
      setIsPaymentInfoModified(!isModified);
      setCustomerNotification(true)
    }
  };



  const handleBillingZipChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 5); // Limit to 5 digits

    const containsNonZero = /[1-9]/.test(e.target.value);
    if (containsNonZero) {
      setInvalidBillingZipCode("valid")
    } else {
      setInvalidBillingZipCode("Invalid number")
    }

    if (e.target.value === "") {
      setInvalidBillingZipCode("")
    }

    setFormData({
      ...formData,
      [name]: inputValue,
    });

    // Set error message if zip code is not empty and exceeds 5 digits
    if (inputValue.length > 0 && inputValue.length < 5) {
      setBillingZipCodeError("Zip code must be at most 5 digits");
    } else {
      setBillingZipCodeError("");
    }
    const isBillingModified = value !== customerDetails[name];
    if (billingInfoFields.includes(name)) {
      setIsBillingInfoModified(isBillingModified);
      setIsBusinessInfoModified(!isBillingModified);
      setIsContactInfoModified(!isBillingModified);
      setIsShippingInfoModified(!isBillingModified);
      setIsPaymentInfoModified(!isBillingModified);
      setContactNotification(true)
    }
  };
  const expirationDatePattern = /^(0[1-9]|1[0-2])\/[0-9]{2}$/;

  const validateExpirationDate = (date) => {
    return expirationDatePattern.test(date);
  };

  // const handleExpirationDateChange = (e) => {
  //   const { name, value } = e.target;
  //   const isBackspace = e.nativeEvent && e.nativeEvent.inputType === "deleteContentBackward";
  //   const cleanedValue = value.replace(/\D/g, '');
  //   const truncatedValue = cleanedValue.slice(0, 4);
  //   const formattedValue = truncatedValue.replace(/^(\d{2})(\d{0,2})/, '$1/$2');

  //   if (isBackspace && truncatedValue.length === 2) {
  //     const slicedValue = truncatedValue.slice(0, 1);
  //     return setFormData({
  //       ...formData,
  //       [name]: slicedValue,
  //     });
  //   }

  //   const isValidExpirationDate = validateExpirationDate(formattedValue);

  //   setFormData({
  //     ...formData,
  //     [name]: formattedValue,
  //   });

  //   // Set error message if expiration date is not in the expected format
  //   // if (value.length > 0 && !isValidExpirationDate) {
  //   //   setExpirationDateError("Invalid expiration date format (MM/YY)");
  //   // } else {
  //   //   setExpirationDateError("");
  //   // }
  //   const isPaymentModified = value !== customerDetails[name];
  //   if (paymentInfoFields.includes(name)) {
  //     setIsBillingInfoModified(!isPaymentModified);
  //     setIsBusinessInfoModified(!isPaymentModified);
  //     setIsContactInfoModified(!isPaymentModified);
  //     setIsShippingInfoModified(!isPaymentModified);
  //     setIsPaymentInfoModified(isPaymentModified);
  //     setPaymentNotification(true)
  //   }
  // };

  const handleExpirationDateChange = (e) => {
    const { value } = e.target;
    const isBackspace = e.nativeEvent && e.nativeEvent.inputType === "deleteContentBackward";
    
    // Remove non-digit characters
    const cleanedValue = value.replace(/\D/g, '');
    
    // Handle month validation and formatting
    if (cleanedValue.length >= 1) {
      let month = cleanedValue.substring(0, 2);
      
      // If first digit is > 1, pad with 0
      if (month.length === 1 && parseInt(month, 10) > 1) {
        month = `0${month}`;
      }
      
      // Ensure month is between 01 and 12
      if (month.length === 2) {
        const monthNum = parseInt(month, 10);
        if (monthNum < 1) month = '01';
        if (monthNum > 12) month = '12';
      }
      
      // Format based on input length
      if (cleanedValue.length <= 2) {
        Formik.setFieldValue("cardExpiryDate", month);
      } else {
        // Handle year and add the slash
        const year = cleanedValue.substring(2, 4);
        Formik.setFieldValue("cardExpiryDate", `${month}/${year}`);
      }
    } else {
      Formik.setFieldValue("cardExpiryDate", "");
    }
  };

  
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const handleShippingZipChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 5);

    const containsNonZero = /[1-9]/.test(inputValue);

    if (containsNonZero) {
      setInvalidShippingZipCode("valid")
    } else {
      setInvalidShippingZipCode("Invalid number")
    }
    if (inputValue === "") {
      setInvalidShippingZipCode("")
    }

    setShippingInfo({
      ...shippingInfo,
      billingZipCode: inputValue,
    });

    if (inputValue.length > 0 && inputValue.length < 5) {
      setShippingZipCodeError("Zip code must be at most 5 digits");
    } else {
      setShippingZipCodeError("");
    }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBillingInfoModified(!isShippingModified);
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)
    }
  };

  const handleShippingZipChangeSecond = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 5);

    const containsNonZero = /[1-9]/.test(inputValue);

    if (containsNonZero) {
      setInvalidShippingZipCode("valid")
    } else {
      setInvalidShippingZipCode("Invalid number")
    }
    if (inputValue === "") {
      setInvalidShippingZipCode("")
    }

    setFormData({
      ...formData,
      shippingZipCode: inputValue,
    });

    if (inputValue.length > 0 && inputValue.length < 5) {
      setShippingZipCodeError("Zip code must be at most 5 digits");
    } else {
      setShippingZipCodeError("");
    }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBillingInfoModified(!isShippingModified);
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)
    }
  };

  const handleShippingCountyChange = (e) => {
    setIsShippingInfoModified(true);
    setIsBusinessInfoModified(false)
    setIsContactInfoModified(false)
    setIsBillingInfoModified(false)
    setIsPaymentInfoModified(false)
    setShippingNotification(true)
    const { name, value } = e.target;
    // let inputValue = value.replace(/\D/g, ""); 
    // inputValue = inputValue.slice(0, 5);
    // const containsNonZero = /[1-9]/.test(inputValue);

    // if(containsNonZero){
    //   setInvalidShippingZipCode("valid")
    // }else{
    //   setInvalidShippingZipCode("Invalid number")
    // }
    // if(inputValue === ""){
    //   setInvalidShippingZipCode("")
    // }
    setFormData({
      ...formData,
      [name]: (e.target.value).replace(/[^A-Za-z\s]/g, ""),
    });
    // if (inputValue.length > 0 && inputValue.length < 5) {
    //   setShippingZipCodeError("Zip code must be at most 5 digits");
    // } else {
    //   setShippingZipCodeError("");
    // }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBillingInfoModified(!isShippingModified);
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)
    }
  };


  const validateCardNumber = (cardNumber) => {
    const cleanedCardNumber = cardNumber.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedCardNumber = cleanedCardNumber.slice(0, 19); // Limit to 19 digits

    return formattedCardNumber;
  };

  const handleCardNumberChange = (e) => {
    const { name, value } = e.target;

    // Validate and format card number
    const formattedCardNumber = validateCardNumber(value);
    const containsNonZero = /[1-9]/.test(formattedCardNumber);

    if (containsNonZero) {
      setInvalidCardNumber("valid")
    } else {
      setInvalidCardNumber("Invalid number")
    }

    if (formattedCardNumber === "") {
      setInvalidCardNumber("")
    }
    setFormData({
      ...formData,
      [name]: formattedCardNumber,
    });

    if (value.length > 0 && formattedCardNumber.length < 10) {
      setCardError("Card number must be between 10 and 19 digits");
    } else {
      setCardError("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsBillingInfoModified(!isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)
    }
  };
  const validateCardCVV = (cardNumber) => {
    const cleanedCardNumber = cardNumber.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedCardNumber = cleanedCardNumber.slice(0, 4); // Limit to 19 digits

    return formattedCardNumber;
  };
  const handleCardCvvChange = (e) => {
    const { name, value } = e.target;

    // Validate and format card number
    const formattedCardNumber = validateCardCVV(value);
    const containsNonZero = /[1-9]/.test(formattedCardNumber);
    if (containsNonZero) {
      setInvalidCardCvv("valid")
    } else {
      setInvalidCardCvv("Invalid number")
    }
    if (formattedCardNumber === "") {
      setInvalidCardCvv("")
    }

    setFormData({
      ...formData,
      [name]: formattedCardNumber,
    });

    // Set error message if card number is not empty and not in the expected format
    if (value.length > 0 && formattedCardNumber.length < 3) {
      setCardCvv("CardCvv must be between 3 to 19 digits");
    } else {
      setCardCvv("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsBillingInfoModified(!isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)
    }
  };

  const handleCardZipChange = (e) => {
    const { name, value } = e.target;

    // Validate card zip code to be at most 5 digits
    let inputValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 5);

    const containsNonZero = /[1-9]/.test(e.target.value);
    if (containsNonZero) {
      setInvalidCardZipCode("valid")
    } else {
      setInvalidCardZipCode("Invalid number")
    }

    if (e.target.value === "") {
      setInvalidCardZipCode("")
    } // Limit to 5 digits

    setFormData({
      ...formData,
      [name]: inputValue,
    });

    // Set error message if zip code is not empty and exceeds 5 digits
    if (inputValue.length > 0 && inputValue.length < 5) {
      setCardZipCodeError("Zip code must be at most 5 digits");
    } else {
      setCardZipCodeError("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsBillingInfoModified(!isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)
    }
  };

  // const handleEditClick = async (e) => {
  //   if (
  //     zipCodeError ||
  //     billingZipCodeError ||
  //     shippingZipCodeError ||
  //     cardZipCodeError ||
  //     bankAccountError ||
  //     businessAccountError ||
  //     inputPhoneError
  //   ) {
  //     // Display an error message or take appropriate action
  //     return;
  //   }


  //   const businessId = localStorage.getItem("business_id")

  //   const updatedData = {
  //     businessId: localStorage.getItem("business_id"),
  //     ownerId: localStorage.getItem("user_id"),
  //     edit_customerId: customerId,
  //     edit_businessName: formData.businessName,
  //     edit_businessAccount: formData.businessAccount,
  //     edit_businessAddress: formData.businessAddress,
  //     edit_businessCity: formData.businessCity,
  //     edit_businessState: formData.businessState,
  //     edit_businessZipCode: formData.businessZipCode,
  //     fuelReceivedTerminalId: formData.fuelReceivedTerminalId,
  //     brandId: formData.brandId,

  //     edit_contactPersonName: formData.contactPersonName,
  //     edit_contactPersonEmail: formData.contactPersonEmail,
  //     edit_contactPersonPhoneNumber: formData.contactPersonPhoneNumber,
  //     // edit_billingAddress: formData.billingAddress,
  //     edit_billingAddress: formData.businessAddress,

  //     // edit_billingCity: formData.billingCity,
  //     edit_billingCity: formData.businessCity,

  //     // edit_billingState: formData.billingState,
  //     edit_billingState: formData.businessState,

  //     // edit_billingZipCode: formData.billingZipCode,
  //     edit_billingZipCode: formData.businessZipCode,

  //     edit_shippingTo: shippingInfo.contactPersonName,
  //     edit_shippingAddress: shippingInfo.billingAddress,
  //     edit_shippingCity: shippingInfo.billingCity,
  //     edit_shippingState: shippingInfo.billingState,
  //     edit_shippingZipCode: shippingInfo.billingZipCode,
  //     edit_shippingCounty: formData.shippingCounty,
  //     edit_shippingContactNumber: shippingInfo.contactPersonPhoneNumber,
  //     edit_currency: formData.bankCurrency,
  //     edit_bankRouting: formData.bankRouting,
  //     edit_bankAccount: formData.bankAccount,
  //     edit_shippingDeliveryInstructions: formData.shippingDeliveryInstructions,
  //     edit_cardNumber: formData.cardNumber,
  //     edit_nameOnCard: formData.nameOnCard,
  //     edit_cardExpiryDate: formData.cardExpiryDate,
  //     edit_cardCVV: formData.cardCVV,
  //     edit_cardZipCode: formData.cardZipCode,
  //     edit_paymentType: formData.paymentType,
  //     edit_creditCardName: formData.creditCardName,
  //     edit_cardPaymentCurrency: formData.cardPaymentCurrency,
  //     editFuelIds: formData.fuelIds
  //   };
  //   // ... (Rest of your code)

  //   try {
  //     const response = await axios.put(
  //       `${baseURL}/Y3VzdG9tZXIvdXBkYXRl`,                           //    `${baseURL}/customer/update`
  //       updatedData
  //     );
  //     console.log("Update data:", updatedData);
  //     console.log("Update Response:", response); // Add this log to check the response

  //     if (response.status === 200) {
  //       const responseData = await response.data;

  //       if (responseData.dataUpdatingStatus === true) {
  //         const filledFrames = [];

  //         // Check each frame and add its name to the array
  //         if (customerNotification) filledFrames.push("Customer Info");
  //         if (contactNotification) filledFrames.push("Contact Info");
  //         if (billingNotification) filledFrames.push("Billing Info");
  //         if (shippingNotification) filledFrames.push("Shipping Info");
  //         if (paymentNotification) filledFrames.push("Payment Info");

  //         // Construct success message based on filled frames
  //         let successMessage = ` ${formData.businessName},`;

  //         if (filledFrames.length > 0) {
  //           successMessage += ` ${filledFrames.join(
  //             ", "
  //           )} `;
  //         }
  //         // const filledFrames = [];

  //         // Check each frame and add its name to the array
  //         // if (formData.businessName) filledFrames.push("Info");
  //         // if (formData.contactPersonName) filledFrames.push("Contact Info");
  //         // if (formData.billingAddress) filledFrames.push("Billing Info");
  //         // if (formData.shippingTo) filledFrames.push("Shipping Info");
  //         // if (formData.bankCurrency) filledFrames.push("Payment Info");
  //         // showToastForUpdateCustomer(`${successMessage} updated successfully` , "success");
  //         dispatch(addToast({ type: 'approve', message: `${successMessage} updated successfully` }));

  //       } else {
  //         showToastForDeleteCustomer(`Unable to update ${formData.businessName} customer data`, "error");
  //       }
  //     } else {
  //       console.log("Request failed with status: " + response.status);
  //       response.data.then((data) => {
  //         console.log(data);
  //       });
  //       showToastForDeleteCustomer("Unable to update customer, please try again", "error");
  //     }
  //   } catch (error) {
  //     console.error("Update Error:", error);
  //     showToastForDeleteCustomer("Unable to update customer, please try again", "error");
  //   }
  //   window.scrollTo(0, 0);
  //   navigate(`/customers`);
  // };

  console.log("Form Data:", formData);
  const handleToggle = () => {
    setIsCardSelected(!isCardSelected);
  };

  const capitalizeWords = (input) => {
    return input
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Usage example
  const businessInfoFields = [
    "businessName",
    "businessAccount",
    "businessAddress",
    "businessCity",
    "businessState",
    "businessZipCode",
    "fuelReceivedTerminalId",
    "brandId"
  ];
  const contactInfoFields = [
    "contactPersonName",
    "contactPersonEmail",
    "contactPersonPhoneNumber",
  ];
  const billingInfoFields = [
    "billingAddress",
    "billingCity",
    "billingState",
    "billingZipCode",
  ];
  const shippingInfoFields = [
    "shippingTo",
    "shippingAddress",
    "shippingCity",
    "shippingState",
    "shippingZipCode",
    "shippingContactNumber",
    "shippingDeliveryInstructions",
  ];
  const fuelgraefields = ["fuelIds"]
  const paymentInfoFields = [
    "bankCurrency",
    "bankRouting",
    "bankAccount",
    "cardNumber",
    "cardExpiryDate",
    "cardCVV",
    "cardZipCode",
    "nameOnCard",
    "creditCardName",
    "cardPaymentCurrency",
  ];

  // const handleEditClick = () => {
  //   navigate(`/setup/editcustomer/${customerId}`, { 
  //     state: { customerId: customerId } 
  //   });
  // };

  const handleEditClick = () => {
    navigate(`/setup/editcustomer/${customerId}`, { 
      state: { customerId: customerId } 
    });
  };

  const handleDeleteClick = () => {
    navigate(`/setup/deletecustomer/${customerId}`, { 
      state: { customerId: customerId, businessName: formData.businessName } 
    });
  };





  return (
    <div>
      {loading ? (
        <p>Loading customer details...</p>
      ) : (
        <div className=" pb-20">
          {/* <h1 className="mx-8 font-bold mb-14 text-gray-400 text-xl mt-[-25px]">
            Customers {">>"} View Customer
          </h1> */}

          <div className="relative w-auto  bg-white rounded-box  mx-8 rounded-xl">
            {/* <div className="absolute -top-5 right-3 flex items-center space-x-2 z-10">
              <CustomNavigationButtons
                type="edit"

              />
              <CustomNavigationButtons
                type="delete"

              />
              <div
                className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-8 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
                onClick={() => {
                  navigate("/");
                }}
                style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
              >
                <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16} />
              </div>

              <div
                className="absolute -top-4 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
                onClick={() => navigate(-1)}
              >
                <span className="text-red-500 group-hover:text-white text-2xl font-bold mb-1 transition-colors duration-200">&times;</span>
              </div>
              <CustomNavigation />
            </div> */}

<div className="absolute -top-5 -right-4 flex items-center justify-end space-x-2 z-10">
  <CustomNavigationButtons
    type="edit" onClick={()=> navigate(`/viewcustomer/${customerId}`, { state: { customerId: customerId } })}

  />
  
  <CustomNavigationButtons
    type="delete" onClick={handledelete}
  />
  
  <div
    className="flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
    onClick={() => {
      navigate("/");
    }}
    style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
  >
    <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16} />
  </div>

  <div
    className="bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
    onClick={() => navigate("/customers")}
  >
    <span className="text-red-500 group-hover:text-white text-2xl font-bold mb-1 transition-colors duration-200">&times;</span>
  </div>
</div>

            
            <div className="flex items-center">
              <MdPersonOutline className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Customer Info
              </h2>
            </div>
            <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">
              <div className="w-full">
                <label
                  htmlFor="businessAccount"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Account #{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1
                  content={businessAccountError}
                  show={!!businessAccountError}
                  marginLeft={80}
                />

                <input
                  type="text"
                  
                  id="businessAccount"
                  name="businessAccount"
                  readOnly
                  value={formData.businessAccount}
                  onChange={handleBusinessAccountChange}
                  maxLength={20}
                  required
                  className={` bg-gray-100 border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  // className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg 
                  // focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-500 "
                  placeholder="Enter Account No"
                  pattern="^[a-zA-Z0-9-]*$" 
                  disabled 
                />
                {/* {businessAccountError && (
                  <div className="text-red-500 text-base mt-1">
                    {businessAccountError}
                  </div>
                )} */}
              </div>
              <div className="w-full">
                <label
                  htmlFor="businessName"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Customer Name{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>

                <CustomTooltip1 content={busNameError} show={!!busNameError} marginLeft={140} />

                <input
                  type="text"
                  readOnly
                  id="businessName"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handlebusNameChange}
                  required
                  className={` bg-gray-100 border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  // className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Customer Name" disabled
                />
              </div>

              <div className="w-full">
                <label
                  htmlFor="businessAddress"
                  className="block mb-2 text-base font-medium text-gray-900"
                >
                  Customer Address{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1
                  content={busAddNameError}
                  show={!!busAddNameError}
                  marginLeft={130}
                />

                <input
                  type="text"
                  readOnly
                  id="businessAddress"
                  name="businessAddress"
                  value={formData.businessAddress}
                  onChange={handlebusAddChange}
                  required
                  className={` bg-gray-100 border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  // className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Customer Address"  disabled
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="businessCity"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  City{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1
                  content={busCityNameError}
                  show={!!busCityNameError}
                  marginLeft={155}
                />

                <input
                 className={` bg-gray-100 border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  // className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter City"
                  readOnly
                  type="text"
                  id="businessCity"
                  name="businessCity"
                  value={formData.businessCity}
                  onChange={handlebusCityChange}
                  required disabled
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="businessState"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  State{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <select
                  id="businessState"
                  disabled
                  name="businessState"
                  className={` bg-gray-100 border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}

                  // className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  value={formData.businessState} readOnly
                  onChange={(e) => { handleInputChange(e, "businessState") }}
                  // onChange={handleChange}
                  required
                >
                  <option value="">Select State</option>
                  <option value="AL">Alabama (AL)</option>
                  <option value="AK">Alaska (AK)</option>
                  <option value="AZ">Arizona (AZ)</option>
                  <option value="AR">Arkansas (AR)</option>
                  <option value="CA">California (CA)</option>
                  <option value="CO">Colorado (CO)</option>
                  <option value="CT">Connecticut (CT)</option>
                  <option value="DE">Delaware (DE)</option>
                  <option value="FL">Florida (FL)</option>
                  <option value="GA">Georgia (GA)</option>
                  <option value="HI">Hawaii (HI)</option>
                  <option value="ID">Idaho (ID)</option>
                  <option value="IL">Illinois (IL)</option>
                  <option value="IN">Indiana (IN)</option>
                  <option value="IA">Iowa (IA)</option>
                  <option value="KS">Kansas (KS)</option>
                  <option value="KY">Kentucky (KY)</option>
                  <option value="LA">Louisiana (LA)</option>
                  <option value="ME">Maine (ME)</option>
                  <option value="MD">Maryland (MD)</option>
                  <option value="MA">Massachusetts (MA)</option>
                  <option value="MI">Michigan (MI)</option>
                  <option value="MN">Minnesota (MN)</option>
                  <option value="MS">Mississippi (MS)</option>
                  <option value="MO">Missouri (MO)</option>
                  <option value="MT">Montana (MT)</option>
                  <option value="NE">Nebraska (NE)</option>
                  <option value="NV">Nevada (NV)</option>
                  <option value="NH">New Hampshire (NH)</option>
                  <option value="NJ">New Jersey (NJ)</option>
                  <option value="NM">New Mexico (NM)</option>
                  <option value="NY">New York (NY)</option>
                  <option value="NC">North Carolina (NC)</option>
                  <option value="ND">North Dakota (ND)</option>
                  <option value="OH">Ohio (OH)</option>
                  <option value="OK">Oklahoma (OK)</option>
                  <option value="OR">Oregon (OR)</option>
                  <option value="PA">Pennsylvania (PA)</option>
                  <option value="RI">Rhode Island (RI)</option>
                  <option value="SC">South Carolina (SC)</option>
                  <option value="SD">South Dakota (SD)</option>
                  <option value="TN">Tennessee (TN)</option>
                  <option value="TX">Texas (TX)</option>
                  <option value="UT">Utah (UT)</option>
                  <option value="VT">Vermont (VT)</option>
                  <option value="VA">Virginia (VA)</option>
                  <option value="WA">Washington (WA)</option>
                  <option value="WV">West Virginia (WV)</option>
                  <option value="WI">Wisconsin (WI)</option>
                  <option value="WY">Wyoming (WY)</option>
                </select>
              </div>
              <div className="w-full">
                <label
                  htmlFor="businessZipCode"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Zip Code{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1 content={zipCodeError} show={!!zipCodeError} marginLeft={167} />
                {invalidBusinessZipCode === "Invalid number" && !zipCodeError && (
                  <CustomTooltip1
                    content={"Invalid ZipCode"}
                    show={"Invalid ZipCode"}
                    marginLeft={163}
                  />
                )}
                <input
                 className={` bg-gray-100 border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  // className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Zipcode"
                  type="text"
                  id="businessZipCode"
                  name="businessZipCode"
                  value={formData.businessZipCode}
                  onChange={handleInputZipChange}
                  required
                  readOnly disabled
                />

              </div>
              <div>
                <label
                  for="fuelReceivedTerminalId"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Terminal Belongs To
                </label>
                <select
                  id="fuelReceivedTerminalId"
                  name="fuelReceivedTerminalId"
                  required
                  disabled readOnly
                  value={formData.fuelReceivedTerminalId}
                  // onChange={(e)=> {
                  //   setFormData({
                  //     ...formData,
                  //     'fuelReceivedTerminalId': e.target.value  // Using trimmed value here
                  // });
                  // }}
                  onChange={(e) => { handleInputChange(e, "fuelReceivedTerminalId") }}

                  // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
 className={` bg-gray-100 border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                // required
                // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                >
                  <option value="">--Select Terminal--</option>
                  {originTerminal.map((terminal) => (
                    <option key={terminal.id} value={terminal.id}>{terminal.terminal_name}</option>
                  ))}

                </select>
              </div>
              <div>
                <label
                  for="brandId"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Store Brand
                </label>
                <select
                  id="brandId"
                  name="brandId"
                  required
                  value={formData.brandId}
                  // onChange={(e)=> {
                  //   setFormData({
                  //     ...formData,
                  //     'brandId': e.target.value  // Using trimmed value here
                  // });
                  // }}
                  onChange={(e) => { handleInputChange(e, "brandId") }}
                  className={` bg-gray-100 border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                  disabled readOnly
                // required
                // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                >
                  <option value="">--Select Brand--</option>
                  {sellerNames.map((seller) => (
                    <option key={seller.id} value={seller.id}>
                      {seller.seller_name.charAt(0).toUpperCase() + seller.seller_name.slice(1).toLowerCase()}
                      
                    </option>
                  ))}

                </select>
              </div>
              <div className="w-full">
                <label
                  htmlFor="customerCounty"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  County{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>

                <input
                  className={` bg-gray-100 border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  placeholder="Enter County"
                  type="text"
                  id="customerCounty"
                  name="customerCounty"
                  value={formData.customerCounty}
                  readOnly disabled
                />

              </div>
              <div className="w-full">
                <label
                  htmlFor="flowniaSiteId"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Flownia Site Id{" "}
                  {/* <span className="text-red-500 text-xl font-extrabold">*</span> */}
                </label>

                <input
                  className={` bg-gray-100 border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  placeholder="Enter Flownia Site Id"
                  type="number"
                  id="flowniaSiteId"
                  name="flowniaSiteId"
                  value={formData.flowniaSiteId}
                  readOnly disabled



                />

              </div>
            </div>
            <div className="flex justify-end space-x-3">
              {/* {isBusinessInfoModified ? (
                <>
                  <button
                    onClick={handleCancel}
                    type="submit"
                    className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleEditClick}
                    type="submit"
                    disabled={
                      !formData.businessAccount ||
                      formData.businessAccount.length < 3  ||
                      !formData.businessName ||
                      formData.businessName.length < 3 ||
                      !formData.businessAddress ||
                      formData.businessAddress.length < 3 ||
                      !formData.businessCity ||
                      formData.businessCity.length < 3 ||
                      formData.businessState === "" ||
                      !formData.businessZipCode ||
                      formData.businessZipCode.length < 5 ||
                      !formData.contactPersonEmail ||
                      !formData.contactPersonEmail.includes(".") ||
                      !formData.contactPersonEmail.includes("@") ||
                      emailError ||
                      !formData.contactPersonName ||
                      formData.contactPersonName.length < 3 ||
                      !formData.contactPersonPhoneNumber ||
                      formData.contactPersonPhoneNumber.length < 13 ||
                      !formData.billingAddress ||
                      formData.billingAddress.length < 3 ||
                      !formData.billingCity ||
                      formData.billingCity.length < 3 ||
                      formData.billingState === "" ||
                      !formData.billingZipCode ||
                      formData.billingZipCode.length < 5 ||
                      (formData && formData.bankRouting && formData.bankRouting.length > 0 && formData.bankRouting.length < 9) ||
                      cardCvv !== "" || cardError !== "" || cardNameError !== "" || invalidBusinessZipCode === "Invalid number"
                      || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" || invalidShippingPhoneNumber === "Invalid number"
                       || invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                       invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                      
                      
                    }
                    
                    className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Done
                  </button>
                </>
              ) : null} */}
            </div>
          </div>

          {/* <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl mt-16">
          <div className="flex items-center">
            <LocalGasStation className="text-gray-700 w-6 h-6 mr-2 mb-10" />
            <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
              Fuel Grade
            </h2>
          </div>
          <div className="grid grid-cols-9 gap-4 p-6 ml-4 pt-0">
  {fuelGradeNames.map((fuelgradeName) => (
    <label
      key={fuelgradeName.id}
      className="relative flex flex-col items-center justify-between p-3 border-2 border-[#DCDCDC] rounded-lg cursor-pointer transition-all duration-300 group"
      style={{ height: "150px", width: "120px" }}
    >
    
      <input
        type="checkbox"
        value={fuelgradeName.id}
        onChange={formik.handleChange} 
        checked={formik.values.fuelIds.includes(fuelgradeName.id.toString())} 
        className="absolute top-0 left-0 w-full h-full opacity-0 peer"

        name="fuelIds"
      />
      
    
      <div className="absolute inset-0 bg-white border-2 border-[#DCDCDC] rounded-lg peer-checked:bg-[#25caed] peer-checked:border-[#25caed] transition-all duration-300"></div>


      <div className="z-10 mt-10">
        <span className="block mt-2 text-md font-bold font-medium text-center text-gray-700">
          {fuelgradeName.fuel_type}
        </span>
      </div>

    
      <div className="relative top-[-155px] left-1/2 transform -translate-x-1/2 bg-black text-white text-xs px-2 py-1 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
     
        {formik.values.fuelIds.includes(fuelgradeName.id.toString())
          ? "Click again to unselect"
          : "Click on the logo to select"}
      </div>
    </label>
  ))}
</div>
        </div> */}
          <div className="w-auto bg-white rounded-box mx-8 rounded-xl mt-16">
            <div className="flex items-center">
              <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Customer Fuel Grade
              </h2>
            </div>
            <div className="grid grid-cols-9 gap-4 p-6 ml-4 pt-0">
              {fuelGradeNames.map((fuelgradeName) => (
                <label
                  key={fuelgradeName.id}
                  className="relative flex flex-col items-center justify-between bg-gray-100 border text-gray-900 text-base rounded-lg block p-3 border-2 border-[#DCDCDC] rounded-lg cursor-pointer transition-all duration-300 group"
                  style={{ height: "150px", width: "120px" }}
                >
                  <input
                    type="checkbox"
                    value={fuelgradeName.id}
                    onChange={(e) => handleFuelSelection(e, fuelgradeName.id)}
                    checked={formData.fuelIds.includes(fuelgradeName.id.toString())}
                    className="peer hidden" // Make input the peer for sibling styles
                    name="fuelIds"
                    disabled
                  />

                  <div className="absolute inset-0 border-2 border-[#DCDCDC] rounded-lg  transition-all duration-300"></div>

                  {/* Content */}
                  <div className="z-10 mt-10">
                    <span className="block mt-2 text-md font-bold text-center text-gray-700">
                      {fuelgradeName.product_name}
                    </span>
                  </div>

                  {/* Tooltip */}
                  {/* <div className="relative top-[-155px] left-1/2 transform -translate-x-1/2 bg-black text-white text-xs px-2 py-1 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                    {selectedFuelIds.includes(fuelgradeName.id.toString())
                      ? "Click again to unselect"
                      : "Click on the logo to select"}
                  </div> */}

                  {/* Checkbox at Bottom-Right */}
                  <div
                    className="absolute bottom-2 right-2 w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center opacity-0 group peer-checked:opacity-100 transition-opacity duration-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      className="w-4 h-4 text-white"
                    >
                      <path
                        d="M20 6L9 17l-5-5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </label>
              ))}
            </div>


            <div className="flex justify-end space-x-3">
              {/* {isFuelGradesModified ? (
                <>
                  <button
                    onClick={handleCancel}
                    type="submit"
                    className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleEditClick}
                    type="submit"
                    disabled={selectedFuelIds.length === 0 || !isFuelGradesModified}
                    
                    className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Done
                  </button>
                </>
              ) : null} */}
            </div>
          </div>


          <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-16">
            <div className="flex items-center">
              <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Contact Info

              </h2>
            </div>
            <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">
              <div className="w-full">
                <label
                  htmlFor="contactPersonName"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Contact Person Name{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1
                  content={contactNameError}
                  show={!!contactNameError}
                  marginLeft={120}
                />

                <input
                  className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 w-full p-2.5 "
                  placeholder="Enter Name"
                  type="text"
                  id="contactPersonName"
                  name="contactPersonName"
                  value={formData.contactPersonName}
                  onChange={handleContactNameChange}
                  required
                  readOnly disabled
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="contactPersonEmail"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Email{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1 content={emailError} show={!!emailError} marginLeft={206} />

                <input
                  className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Email"
                  type="email"
                  id="contactPersonEmail"
                  name="contactPersonEmail"
                  value={formData.contactPersonEmail}
                  onChange={handleEmailChange}
                  required
                  readOnly disabled
                />
                {/* {emailError && (
                  <div className="text-red-500 text-base mt-1">{emailError}</div>
                )} */}
              </div>
              <div className="w-full">
                <label
                  htmlFor="contactPersonPhoneNumber"
                  className="block mb-2 text-base font-medium text-gray-900  "
                >
                  <div className="flex items-center">
                    <span>Phone Number{" "}</span>
                    <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
                    <Tooltip title="SMS will be sent" position="top" trigger="mouseenter">

                      <img src={smsIcon} alt="SMS Icon" style={{ width: '30px', height: '20px', marginLeft: '8px' }} />
                    </Tooltip>
                  </div>
                </label>
                <CustomTooltip1
                  content={inputPhoneError}
                  show={!!inputPhoneError}
                  marginLeft={163}
                />
                {invalidPhoneNumber === "Invalid number" && !inputPhoneError && (
                  <CustomTooltip1
                    content={"Invalid Number"}
                    show={"Invalid Number"}
                    marginLeft={163}
                  />
                )}

                <input
                  className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Phone Number"
                  type="text"
                  id="contactPersonPhoneNumber"
                  name="contactPersonPhoneNumber"
                  value={formData.contactPersonPhoneNumber}
                  onChange={handleInputPhoneChange}
                  required
                  readOnly disabled
                />
                {/* {inputPhoneError && (
                  <div className="text-red-500 text-base mt-1">
                    {inputPhoneError}
                  </div>
                )} */}
              </div>
            </div>
            <div className="flex justify-end space-x-3">
              {/* {isContactInfoModified ? (
                <>
                  <button
                    onClick={handleCancel}
                    type="submit"
                    className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleEditClick}
                    type="submit"
                    className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                    disabled={
                      !formData.businessAccount ||
                      formData.businessAccount.length < 3  ||
                      !formData.businessName ||
                      formData.businessName.length < 3 ||
                      !formData.businessAddress ||
                      formData.businessAddress.length < 3 ||
                      !formData.businessCity ||
                      formData.businessCity.length < 3 ||
                      formData.businessState === "" ||
                      !formData.businessZipCode ||
                      formData.businessZipCode.length < 5 ||
                      !formData.contactPersonEmail ||
                      !formData.contactPersonEmail.includes(".") ||
                      !formData.contactPersonEmail.includes("@") ||
                      emailError ||
                      !formData.contactPersonName ||
                      formData.contactPersonName.length < 3 ||
                      !formData.contactPersonPhoneNumber ||
                      formData.contactPersonPhoneNumber.length < 13 ||
                      !formData.billingAddress ||
                      formData.billingAddress.length < 3 ||
                      !formData.billingCity ||
                      formData.billingCity.length < 3 ||
                      formData.billingState === "" ||
                      !formData.billingZipCode ||
                      formData.billingZipCode.length < 5 ||
                      (formData && formData.bankRouting && formData.bankRouting.length > 0 && formData.bankRouting.length < 9) ||
                      cardCvv !== ""  || cardError !== "" || cardNameError !== ""  || invalidBusinessZipCode === "Invalid number"
                      || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" || invalidShippingPhoneNumber === "Invalid number"
                      || invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                      invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                    }
                    
                  >
                    Done
                  </button>
                </>
              ) : null} */}
            </div>
          </div>

          <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-16" style={{ display: 'none' }}>
            <div className="flex items-center">
              <TbFileInvoice className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Billing Info
              </h2>
            </div>

            <div className="grid gap-4 sm:grid-cols-4 sm:gap-6">
              <div className="w-full">
                <label
                  htmlFor="billingAddress"
                  className="block mb-2 text-base font-medium text-gray-900"
                >
                  Billing Address{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1
                  content={billingAddNameError}
                  show={!!billingAddNameError}
                  marginLeft={40}
                />

                <input
                  className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Billing Address"
                  type="text"
                  id="billingAddress"
                  name="billingAddress"
                  value={formData.billingAddress}
                  readOnly
                  // onChange={(e) => {
                  //   const inputValue = e.target.value;
                  //   // const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9, ]/g, '');
                  //   setFormData((prevData) => ({
                  //     ...prevData,
                  //     billingAddress: inputValue,
                  //   }));
                  // }}
                  onChange={handleBillingAddChange}
                  required
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="billingAddress"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  City{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1
                  content={billingCityNameError}
                  show={!!billingCityNameError}
                  marginLeft={65}
                />

                <input
                  className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter City"
                  type="text"
                  id="billingCity"
                  name="billingCity"
                  value={formData.billingCity}
                  onChange={handleBillingCityChange}
                  required
                  readOnly disabled
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="billingState"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  State{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <select
                  id="billingState"
                  name="billingState"
                  className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  value={formData.billingState}
                  // onChange={handleChange}
                  onChange={(e) => { handleInputChange(e, 'billingState') }}
                  required
                  disabled
                >
                  <option value="">Select State</option>
                  <option value="AL">Alabama (AL)</option>
                  <option value="AK">Alaska (AK)</option>
                  <option value="AZ">Arizona (AZ)</option>
                  <option value="AR">Arkansas (AR)</option>
                  <option value="CA">California (CA)</option>
                  <option value="CO">Colorado (CO)</option>
                  <option value="CT">Connecticut (CT)</option>
                  <option value="DE">Delaware (DE)</option>
                  <option value="FL">Florida (FL)</option>
                  <option value="GA">Georgia (GA)</option>
                  <option value="HI">Hawaii (HI)</option>
                  <option value="ID">Idaho (ID)</option>
                  <option value="IL">Illinois (IL)</option>
                  <option value="IN">Indiana (IN)</option>
                  <option value="IA">Iowa (IA)</option>
                  <option value="KS">Kansas (KS)</option>
                  <option value="KY">Kentucky (KY)</option>
                  <option value="LA">Louisiana (LA)</option>
                  <option value="ME">Maine (ME)</option>
                  <option value="MD">Maryland (MD)</option>
                  <option value="MA">Massachusetts (MA)</option>
                  <option value="MI">Michigan (MI)</option>
                  <option value="MN">Minnesota (MN)</option>
                  <option value="MS">Mississippi (MS)</option>
                  <option value="MO">Missouri (MO)</option>
                  <option value="MT">Montana (MT)</option>
                  <option value="NE">Nebraska (NE)</option>
                  <option value="NV">Nevada (NV)</option>
                  <option value="NH">New Hampshire (NH)</option>
                  <option value="NJ">New Jersey (NJ)</option>
                  <option value="NM">New Mexico (NM)</option>
                  <option value="NY">New York (NY)</option>
                  <option value="NC">North Carolina (NC)</option>
                  <option value="ND">North Dakota (ND)</option>
                  <option value="OH">Ohio (OH)</option>
                  <option value="OK">Oklahoma (OK)</option>
                  <option value="OR">Oregon (OR)</option>
                  <option value="PA">Pennsylvania (PA)</option>
                  <option value="RI">Rhode Island (RI)</option>
                  <option value="SC">South Carolina (SC)</option>
                  <option value="SD">South Dakota (SD)</option>
                  <option value="TN">Tennessee (TN)</option>
                  <option value="TX">Texas (TX)</option>
                  <option value="UT">Utah (UT)</option>
                  <option value="VT">Vermont (VT)</option>
                  <option value="VA">Virginia (VA)</option>
                  <option value="WA">Washington (WA)</option>
                  <option value="WV">West Virginia (WV)</option>
                  <option value="WI">Wisconsin (WI)</option>
                  <option value="WY">Wyoming (WY)</option>
                </select>
              </div>
              <div className="w-full">
                <label
                  htmlFor="billingZipCode"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Zip Code{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <div>
                  {invalidBillingZipCode === "Invalid number" && !billingZipCodeError && (
                    <CustomTooltip1
                      content={"Invalid ZipCode"}
                      show={"Invalid ZipCode"}
                      marginLeft={163}
                    />
                  )}
                  <CustomTooltip1
                    content={billingZipCodeError}
                    show={!!billingZipCodeError}
                    marginLeft={80}
                  />
                </div>


                <input
                  className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Zipcode"
                  type="text"
                  id="billingZipCode"
                  name="billingZipCode"
                  value={formData.billingZipCode}
                  onChange={handleBillingZipChange}
                  required
                  readOnly disabled
                />
                {/* {billingZipCodeError && (
                  <div className="text-red-500 text-base mt-1">
                    {billingZipCodeError}
                  </div>
                )} */}
              </div>
            </div>
            <div className="flex justify-end space-x-3">
              {/* {isBillingInfoModified ? (
                <>
                  <button
                    onClick={handleCancel}
                    type="submit"
                    className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleEditClick}
                    type="submit"
                    className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                    disabled={
                      !formData.businessAccount ||
                      formData.businessAccount.length < 3  ||
                      !formData.businessName ||
                      formData.businessName.length < 3 ||
                      !formData.businessAddress ||
                      formData.businessAddress.length < 3 ||
                      !formData.businessCity ||
                      formData.businessCity.length < 3 ||
                      formData.businessState === "" ||
                      !formData.businessZipCode ||
                      formData.businessZipCode.length < 5 ||
                      !formData.contactPersonEmail ||
                      !formData.contactPersonEmail.includes(".") ||
                      !formData.contactPersonEmail.includes("@") ||
                      emailError ||
                      !formData.contactPersonName ||
                      formData.contactPersonName.length < 3 ||
                      !formData.contactPersonPhoneNumber ||
                      formData.contactPersonPhoneNumber.length < 13 ||
                      !formData.billingAddress ||
                      formData.billingAddress.length < 3 ||
                      !formData.billingCity ||
                      formData.billingCity.length < 3 ||
                      formData.billingState === "" ||
                      !formData.billingZipCode ||
                      formData.billingZipCode.length < 5 || 
                      (formData && formData.bankRouting && formData.bankRouting.length > 0 && formData.bankRouting.length < 9) ||
                      cardCvv !== ""  || cardError !== "" || cardNameError !== ""  || invalidBusinessZipCode === "Invalid number"
                      || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" || invalidShippingPhoneNumber === "Invalid number"
                      || invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                      invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                    }
                    
                  >
                    Done
                  </button>
                </>
              ) : null} */}
            </div>
          </div>

          <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl mt-16">
            <div className="flex items-center">
              <MdOutlineLocalShipping className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Shipping Info
              </h2>
            </div>

            <div className="flex mt-[-65px] mb-10 justify-end items-end">
              <span className="text-gray-700 font-semibold">Manual</span>
              <label className="switch-toggle ml-4">

                <input
                  type="checkbox"
                  onChange={handleAddressToggle}
                  checked={isSameAsAboveSelected}
                  id="sameAsAbove"
                  name="sameAsAbove"
                />
                <span className="slider-toggle round"></span>
              </label>
              <span className="ml-4 font-semibold">Same as above</span>
            </div>

            <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">
              {/* <div className="w-full sm:grid-cols-span-3">
                      <label
                        htmlFor="shippingTo"
                        className="block mb-2 text-base font-medium text-gray-900 "
                      >
                        Shipping To
                      </label>
                      <CustomTooltip1
                        content={shippingToNameError}
                        show={!!shippingToNameError}
                        marginLeft={308}
                        />
                      

                      <input
                        className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        placeholder="Enter contact person name who will receive the delivery"
                        type="text"
                        id="shippingTo"
                        name="shippingTo"
                        value={isSameAsAboveSelected ? shippingInfo.contactPersonName : formData.shippingTo }
                        onChange={isSameAsAboveSelected ? handleShippingToChange : handleShippingToChangeSecond }
                        required
                      />
                    </div>

                    <div className="w-full sm:grid-cols-span-3">
                      <label
                        htmlFor="shippingContactNumber"
                        className="block mb-2 text-base font-medium text-gray-900 "
                      >
                        Contact Number
                      </label>
                      <CustomTooltip1
                        content={inputPhoneError}
                        show={!!inputPhoneError}
                        marginLeft={344}
                      />
                      {invalidShippingPhoneNumber === "Invalid number" && !inputPhoneError &&  (
                        <CustomTooltip1
                          content={"Invalid Number"}
                          show={"Invalid Number"}
                          marginLeft={163}
                        />
                          )} 

                      <input
                        className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        placeholder="Enter Contact Number"
                        type="text"
                        id="shippingContactNumber"
                        name="shippingContactNumber"
                        value={isSameAsAboveSelected ? shippingInfo.contactPersonPhoneNumber : formData.shippingContactNumber }
                        onChange={isSameAsAboveSelected ? handleShippingInputPhoneChange : handleShippingInputPhoneChangeSecond}
                        required
                      />
                    </div> */}

              <div className="w-full sm:grid-cols-span-3">
                <label
                  htmlFor="shippingAddress"
                  className="block mb-2 text-base font-medium text-gray-900"
                >
                  Shipping Address
                </label>
                <CustomTooltip1
                  content={shippingAddNameError}
                  show={!!shippingAddNameError}
                  marginLeft={43}
                />

                <input
                  className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Shipping Address"
                  type="text"
                  id="shippingAddress"
                  name="shippingAddress"
                  readOnly disabled
                  value={isSameAsAboveSelected ? shippingInfo.billingAddress : formData.shippingAddress}
                  onChange={(e) => {
                    setIsShippingInfoModified(true);
                    setIsBusinessInfoModified(false)
                    setIsContactInfoModified(false)
                    setIsBillingInfoModified(false)
                    setIsPaymentInfoModified(false)
                    setShippingNotification(true)
                    const inputValue = e.target.value;

                    // Allow only commas, numbers, and text
                    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9, ]/g, '');

                    isSameAsAboveSelected
                      ? setShippingInfo((prevShippingInfo) => ({
                        ...prevShippingInfo,
                        billingAddress: sanitizedValue
                      }))
                      : setFormData((prevFormData) => ({
                        ...prevFormData,
                        shippingAddress: sanitizedValue
                      }));

                  }}
                  required
                />
              </div>
              <div className="w-full sm:grid-cols-span-3">
                <label
                  htmlFor="shippingCity"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  City
                </label>
                <CustomTooltip1
                  content={shippingCityError}
                  show={!!shippingCityError}
                  marginLeft={67}
                />

                <input
                  className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter City"
                  type="text"
                  id="shippingCity"
                  name="shippingCity"
                  readOnly
                  value={isSameAsAboveSelected ? shippingInfo.billingCity : formData.shippingCity}
                  onChange={isSameAsAboveSelected ? handleShippingCityChange : handleShippingCityChangeSecond}
                  required disabled
                />
              </div>

              <div className="w-full sm:grid-cols-span-3">
                <label
                  htmlFor="shippingState"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  State
                </label>
                <select
                  id="shippingState"
                  name="shippingState"
                  disabled
                  className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  value={isSameAsAboveSelected ? shippingInfo.billingState : formData.shippingState}
                  onChange={(e) => {
                    setIsShippingInfoModified(true);
                    setIsBusinessInfoModified(false)
                    setIsContactInfoModified(false)
                    setIsBillingInfoModified(false)
                    setIsPaymentInfoModified(false)
                    setShippingNotification(true)
                    const inputValue = e.target.value;
                    isSameAsAboveSelected
                      ? setShippingInfo((prevShippingInfo) => ({
                        ...prevShippingInfo,
                        billingState: inputValue
                      }))
                      : setFormData((prevFormData) => ({
                        ...prevFormData,
                        shippingState: inputValue
                      }));
                  }}
                  // onChange={handleChange}
                  required
                >
                  <option value="">Select State</option>
                  <option value="AL">Alabama (AL)</option>
                  <option value="AK">Alaska (AK)</option>
                  <option value="AZ">Arizona (AZ)</option>
                  <option value="AR">Arkansas (AR)</option>
                  <option value="CA">California (CA)</option>
                  <option value="CO">Colorado (CO)</option>
                  <option value="CT">Connecticut (CT)</option>
                  <option value="DE">Delaware (DE)</option>
                  <option value="FL">Florida (FL)</option>
                  <option value="GA">Georgia (GA)</option>
                  <option value="HI">Hawaii (HI)</option>
                  <option value="ID">Idaho (ID)</option>
                  <option value="IL">Illinois (IL)</option>
                  <option value="IN">Indiana (IN)</option>
                  <option value="IA">Iowa (IA)</option>
                  <option value="KS">Kansas (KS)</option>
                  <option value="KY">Kentucky (KY)</option>
                  <option value="LA">Louisiana (LA)</option>
                  <option value="ME">Maine (ME)</option>
                  <option value="MD">Maryland (MD)</option>
                  <option value="MA">Massachusetts (MA)</option>
                  <option value="MI">Michigan (MI)</option>
                  <option value="MN">Minnesota (MN)</option>
                  <option value="MS">Mississippi (MS)</option>
                  <option value="MO">Missouri (MO)</option>
                  <option value="MT">Montana (MT)</option>
                  <option value="NE">Nebraska (NE)</option>
                  <option value="NV">Nevada (NV)</option>
                  <option value="NH">New Hampshire (NH)</option>
                  <option value="NJ">New Jersey (NJ)</option>
                  <option value="NM">New Mexico (NM)</option>
                  <option value="NY">New York (NY)</option>
                  <option value="NC">North Carolina (NC)</option>
                  <option value="ND">North Dakota (ND)</option>
                  <option value="OH">Ohio (OH)</option>
                  <option value="OK">Oklahoma (OK)</option>
                  <option value="OR">Oregon (OR)</option>
                  <option value="PA">Pennsylvania (PA)</option>
                  <option value="RI">Rhode Island (RI)</option>
                  <option value="SC">South Carolina (SC)</option>
                  <option value="SD">South Dakota (SD)</option>
                  <option value="TN">Tennessee (TN)</option>
                  <option value="TX">Texas (TX)</option>
                  <option value="UT">Utah (UT)</option>
                  <option value="VT">Vermont (VT)</option>
                  <option value="VA">Virginia (VA)</option>
                  <option value="WA">Washington (WA)</option>
                  <option value="WV">West Virginia (WV)</option>
                  <option value="WI">Wisconsin (WI)</option>
                  <option value="WY">Wyoming (WY)</option>
                </select>
              </div>
            </div>

            <div className="grid gap-4 sm:grid-cols-3 sm:gap-6 mt-6">


              <div className="w-full sm:grid-cols-span-3">
                <label
                  htmlFor="shippingZipCode"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Zip Code
                </label>
                <CustomTooltip1
                  content={shippingZipCodeError}
                  show={!!shippingZipCodeError}
                  marginLeft={79}
                />
                {invalidShippingZipCode === "Invalid number" && !shippingZipCodeError && (
                  <CustomTooltip1
                    content={"Invalid ZipCode"}
                    show={"Invalid ZipCode"}
                    marginLeft={163}
                  />
                )}

                <input
                  className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Zipcode"
                  type="text"
                  id="shippingZipCode"
                  name="shippingZipCode"
                  value={isSameAsAboveSelected ? shippingInfo.billingZipCode : formData.shippingZipCode}
                  onChange={isSameAsAboveSelected ? handleShippingZipChange : handleShippingZipChangeSecond}
                  required
                  readOnly disabled
                />
                {/* {shippingZipCodeError && (
                        <div className="text-red-500 text-base mt-1">
                          {shippingZipCodeError}
                        </div>
                      )} */}
              </div>

              <div className="w-full sm:grid-cols-span-3">
                <label
                  htmlFor="shippingCounty"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  County
                </label>
                {/* <CustomTooltip1
                        content={shippingZipCodeError}
                        show={!!shippingZipCodeError}
                        marginLeft={79}
                      />
                      {invalidShippingZipCode === "Invalid number" && !shippingZipCodeError &&  (
                      <CustomTooltip1
                        content={"Invalid ZipCode"}
                        show={"Invalid ZipCode"}
                        marginLeft={163}
                      />
                      )}  */}

                <input
                  className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter County"
                  type="text"
                  id="shippingCounty"
                  name="shippingCounty"
                  value={formData.shippingCounty}
                  onChange={(e) => { handleShippingCountyChange(e) }}
                  required
                  readOnly disabled
                />
                {/* {shippingZipCodeError && (
                        <div className="text-red-500 text-base mt-1">
                          {shippingZipCodeError}
                        </div>
                      )} */}
              </div>
            </div>


            <div className="sm:col-span-4 mb-8 mt-6">
              <label
                htmlFor="shippingDeliveryInstructions"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                Shipping Instructions
              </label>
              <CustomTooltip1
                content={shippingDIError}
                show={!!shippingDIError}
                marginLeft={824}
              />

              <textarea
                rows="4"
                className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                placeholder="Enter Shipping Instructions if any..."
                id="shippingDeliveryInstructions"
                name="shippingDeliveryInstructions"
                value={formData.shippingDeliveryInstructions}
                readOnly disabled
                onChange={(e) => {
                  console.log('nameeeeeeeeee', e.target.value);
                  setIsShippingInfoModified(true);
                  setIsBusinessInfoModified(false)
                  setIsContactInfoModified(false)
                  setIsBillingInfoModified(false)
                  setIsPaymentInfoModified(false)
                  setShippingNotification(true)
                  const inputValue = e.target.value;

                  // Allow only commas, numbers, and text
                  const sanitizedValue = inputValue;

                  // Update the state with the sanitized value
                  setFormData((prevData) => ({
                    ...prevData,
                    shippingDeliveryInstructions: sanitizedValue,
                  }));
                  // setFormData({
                  //   ...formData,
                  //   shippingDeliveryInstructions: sanitizedValue,
                  // });
                }}
              ></textarea>
            </div>



            <div className="flex justify-end space-x-3">
              {/* {isShippingInfoModified ? (
                <>
                  <button
                    onClick={handleCancel}
                    type="submit"
                    className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleEditClick}
                    type="submit"
                    className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                    disabled={
                      !formData.businessAccount ||
                      formData.businessAccount.length < 3  ||
                      !formData.businessName ||
                      formData.businessName.length < 3 ||
                      !formData.businessAddress ||
                      formData.businessAddress.length < 3 ||
                      !formData.businessCity ||
                      formData.businessCity.length < 3 ||
                      formData.businessState === "" ||
                      !formData.businessZipCode ||
                      formData.businessZipCode.length < 5 ||
                      !formData.contactPersonEmail ||
                      !formData.contactPersonEmail.includes(".") ||
                      !formData.contactPersonEmail.includes("@") ||
                      emailError ||
                      !formData.contactPersonName ||
                      formData.contactPersonName.length < 3 ||
                      !formData.contactPersonPhoneNumber ||
                      formData.contactPersonPhoneNumber.length < 13 ||
                      !formData.billingAddress ||
                      formData.billingAddress.length < 3 ||
                      !formData.billingCity ||
                      formData.billingCity.length < 3 ||
                      formData.billingState === "" ||
                      !formData.billingZipCode ||
                      formData.billingZipCode.length < 5 ||
                      (formData && formData.bankRouting && formData.bankRouting.length > 0 && formData.bankRouting.length < 9) ||
                      cardCvv !== ""  || cardError !== "" || cardNameError !== "" || invalidBusinessZipCode === "Invalid number"
                      || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" || invalidShippingPhoneNumber === "Invalid number"
                      || invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                      invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                    } 
                    
                  >
                    Done
                  </button>
                </>
              ) : null} */}
            </div>
          </div>

          <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-16">
            <div className="flex justify-between">
              <div className="flex items-center">
                <MdOutlinePayment className="text-gray-700 w-6 h-6 mr-2 mb-10" />
                <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                  Payment Info
                </h2>
              </div>
              <div className="flex items-center mb-6">
                <span className="text-gray-700 font-semibold">Bank</span>
                <label className="switch-toggle ml-4">
                  <input
                    type="checkbox"
                    onChange={handleToggle}
                    checked={isCardSelected}
                    id="paymentType"
                    name="paymentType"
                  />
                  <span className="slider-toggle round"></span>
                </label>
                <span className="ml-4 font-semibold">Card</span>
              </div>
            </div>

            {isCardSelected ? (
              <div className="grid gap-4 sm:grid-cols-4 sm:gap-6">
                <div className="w-full sm:col-span-1">
                  <label
                    htmlFor="cardPaymentCurrency"
                    className="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Currency
                  </label>

                  <select
                    id="cardPaymentCurrency"
                    name="cardPaymentCurrency"
                    disabled
                    className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                    value={formData.cardPaymentCurrency}
                    onChange={(e) => { handleInputChange(e, 'cardPaymentCurrency') }}
                  >
                    <option value="">--Select Currency--</option>
                    <option value="USD">USD ($) - United States dollar</option>
                    <option value="CAD">CAD ($) Canadian dollar</option>
                  </select>
                </div>
                <div className="w-full sm:col-span-1">
                  <label
                    htmlFor="creditCardName"
                    className="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Credit Card
                  </label>

                  <select
                    id="creditCardName"
                    name="creditCardName"
                    className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                    value={formData.creditCardName}
                    onChange={(e) => { handleInputChange(e, 'creditCardName') }}
                    disabled
                  >
                    <option value="">Select Card Type</option>
                    <option value="Americal Express">American Express</option>
                    <option value="Bank of America">Bank of America</option>
                    <option value="Barclays">Barclays</option>
                    <option value="Capital One">Capital One</option>
                    <option value="Chase">Chase</option>
                    <option value="Citi">Citi</option>
                    <option value="Discover">Discover</option>
                    <option value="Synchrony">Synchrony</option>
                    <option value="US Bank">U.S. Bank</option>
                    <option value="Wells Fargo">Wells Fargo</option>
                  </select>
                </div>
                <div className="flex sm:col-span-2 gap-3">
                  <div className="w-full">
                    <label
                      htmlfor="nameOnCard"
                      className="block mb-2 text-base font-medium text-gray-900 "
                    >
                      Name on the Card
                    </label>
                    <CustomTooltip1
                      content={cardNameError}
                      show={!!cardNameError}
                      marginLeft={281}
                    />

                    <input
                      type="text"
                      id="nameOnCard"
                      name="nameOnCard"
                      value={formData.nameOnCard}
                      onChange={handlecardChange}
                      className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                      placeholder="Enter exact name shown on the card"
                      readOnly disabled
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="cardNumber"
                    className="block mb-2 text-base font-medium text-gray-900"
                  >
                    Card #
                  </label>
                  <CustomTooltip1 content={cardError} show={!!cardError} marginLeft={46} />
                  {invalidCardNumber === "Invalid number" && !cardError && (
                    <CustomTooltip1
                      content={"Invalid Card Number"}
                      show={"Invalid Card Number"}
                      marginLeft={163}
                    />
                  )}

                  <input
                    type="text"
                    id="cardNumber"
                    name="cardNumber"
                    value={formData.cardNumber}
                    onChange={handleCardNumberChange}
                    required
                    className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter Card Number"
                    readOnly disabled
                  />
                  {/* {cardError && (
                    <div className="text-red-500 text-base mt-1">{cardError}</div>
                  )} */}
                </div>

                <div>
                  <label
                    htmlFor="cardExpiryDate"
                    className="block mb-2 text-base font-medium text-gray-900"
                  >
                    Exp Date
                  </label>
                  <CustomTooltip1
                    content={expirationDateError}
                    show={!!expirationDateError}
                    marginLeft={80}
                  />

                  <input 
                    type="text"
                    id="cardExpiryDate"
                    name="cardExpiryDate"
                    value={formData.cardExpiryDate}
                    onChange={handleExpirationDateChange}
                    onFocus={() => setCalendarOpen(true)} // Open calendar on focus
                    // Remove onBlur to avoid closing the calendar when selecting a date
                    className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full h-[45px] p-2.5"
                    placeholder="MM/YY"
                    required
                    readOnly disabled
                    // style={{height: "45px"}}
                  />

                  {calendarOpen && (
                    <Calendar
                      onChange={(date) => {
                        if (date instanceof Date && !isNaN(date)) {
                          const formattedDate = date.toLocaleDateString("en-US", {
                            year: "2-digit",
                            month: "2-digit",
                          });
                          handleExpirationDateChange({ target: { name: "cardExpiryDate", value: formattedDate } });
                        }
                      }}
                      value={isValidDate(formData.cardExpiryDate) ? new Date(formData.cardExpiryDate) : null}
                      showYearDropdown
                      showMonthYearDropdown
                      yearDropdownItemNumber={10}
                      minDate={new Date()} // Set the minimum date to the current date
                      onClickDay={() => setCalendarOpen(false)} // Close calendar when a day is clicked
                    />
                  )}

                </div>

                <div>
                  <label
                    htmlFor="cardCVV"
                    className="block mb-2 text-base font-medium text-gray-900 "
                  >
                    CVV #
                  </label>
                  <CustomTooltip1 content={cardCvv} show={!!cardCvv} marginLeft={90} />
                  {invalidCardCvv === "Invalid number" && !cardCvv && (
                    <CustomTooltip1
                      content={"Invalid Cvv"}
                      show={"Invalid Cvv"}
                      marginLeft={163}
                    />
                  )}

                  <input
                    type="text"
                    id="cardCVV"
                    name="cardCVV"
                    value={formData.cardCVV}
                    onChange={handleCardCvvChange}
                    className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter CVV"
                    required
                    readOnly disabled
                  />
                  {/* {cardCvv && (
                    <div className="text-red-500 text-base mt-1">{cardCvv}</div>
                  )} */}
                </div>

                <div>
                  <label
                    htmlFor="cardZipCode"
                    className="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Zip Code
                  </label>
                  <CustomTooltip1
                    content={cardZipCodeError}
                    show={!!cardZipCodeError}
                    marginLeft={80}
                  />
                  {invalidCardZipCode === "Invalid number" && !cardZipCodeError && (
                    <CustomTooltip1
                      content={"Invalid Number"}
                      show={"Invalid Number"}
                      marginLeft={163}
                    />
                  )}

                  <input
                    type="text"
                    id="cardZipCode"
                    name="cardZipCode"
                    required
                    readOnly disabled
                    value={formData.cardZipCode}
                    onChange={handleCardZipChange}
                    className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter Zip Code"
                  />
                  {/* {cardZipCodeError && (
                    <div className="text-red-500 text-base mt-1">
                      {cardZipCodeError}
                    </div>
                  )} */}
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-6">
                <div className="w-full sm:col-span-2">
                  <label
                    htmlFor="bankCurrency"
                    className="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Currency
                  </label>

                  <select
                    id="bankCurrency"
                    name="bankCurrency"
                    disabled
                    className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    value={formData.bankCurrency}
                    onChange={(e) => { handleInputChange(e, 'bankCurrency') }}
                  >
                    <option value="">--Select Currency--</option>
                    <option value="USD">USD ($) - United States dollar</option>
                    <option value="CAD">CAD ($) Canadian dollar</option>
                  </select>
                </div>
                <div className="w-full">
                  <label
                    htmlFor="bankRouting"
                    className="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Bank Routing #
                  </label>
                  <CustomTooltip1
                    content={bankRoutingError}
                    show={!!bankRoutingError}
                    marginLeft={334}
                  />
                  {invalidBankRoutingNumber === "Invalid number" && !bankRoutingError && (
                    <CustomTooltip1
                      content={"Invalid Number"}
                      show={"Invalid Number"}
                      marginLeft={163}
                    />
                  )}

                  <input
                    className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter Routing Number"
                    type="text"
                    id="bankRouting"
                    name="bankRouting"
                    value={formData.bankRouting}
                    onChange={handleBankRoutingChange}
                    required
                    readOnly disabled
                  />
                  {/* {bankRoutingError && (
                    <div className="text-red-500 text-base mt-1">
                      {bankRoutingError}
                    </div>
                  )} */}
                </div>
                <div className="w-full">
                  <label
                    htmlFor="bankAccount"
                    className="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Bank Account #
                  </label>
                  <CustomTooltip1
                    content={bankAccountError}
                    show={!!bankAccountError}
                    marginLeft={300}
                  />
                  {invalidBankAccountNumber === "Invalid number" && !bankAccountError && (
                    <CustomTooltip1
                      content={"Invalid Number"}
                      show={"Invalid Number"}
                      marginLeft={163}
                    />
                  )}

                  <input
                    className="bg-gray-100 border text-gray-900 text-base rounded-lg block focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter Account Number"
                    type="text"
                    readOnly
                    id="bankAccount"
                    name="bankAccount"
                    value={formData.bankAccount}
                    onChange={handleBankAccountChange}
                    required disabled
                  />
                  {/* {bankAccountError && (
                    <div className="text-red-500 text-base mt-1">
                      {bankAccountError}
                    </div>
                  )} */}
                </div>
              </div>
            )}
            <div className="flex justify-end space-x-3">
              {/* {isPaymentInfoModified ? (
                <>
                  <button
                    onClick={handleCancel}
                    type="submit"
                    className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleEditClick}
                    type="submit"
                    className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                    disabled={
                      !formData.businessAccount ||
                      formData.businessAccount.length < 3  ||
                      !formData.businessName ||
                      formData.businessName.length < 3 ||
                      !formData.businessAddress ||
                      formData.businessAddress.length < 3 ||
                      !formData.businessCity ||
                      formData.businessCity.length < 3 ||
                      formData.businessState === "" ||
                      !formData.businessZipCode ||
                      formData.businessZipCode.length < 5 ||
                      !formData.contactPersonEmail ||
                      !formData.contactPersonEmail.includes(".") ||
                      !formData.contactPersonEmail.includes("@") ||
                      emailError ||
                      !formData.contactPersonName ||
                      formData.contactPersonName.length < 3 ||
                      !formData.contactPersonPhoneNumber ||
                      formData.contactPersonPhoneNumber.length < 13 ||
                      !formData.billingAddress ||
                      formData.billingAddress.length < 3 ||
                      !formData.billingCity ||
                      formData.billingCity.length < 3 ||
                      formData.billingState === "" ||
                      !formData.billingZipCode ||
                      formData.billingZipCode.length < 5 || 
                      (formData && formData.bankRouting && formData.bankRouting.length > 0 && formData.bankRouting.length < 9) ||
                      cardCvv !== ""  || cardError !== "" || cardNameError !== "" || invalidBusinessZipCode === "Invalid number"
                      || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" || invalidShippingPhoneNumber === "Invalid number"
                      || invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                      invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                    }                   
                    
                  >
                    Done
                  </button>
                </>
              ) : null } */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerView;


